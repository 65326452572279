import { companyInstance } from '@/api/index.js';

const state = {
  sensor_locations: [],
  sensor_types: [],
  sensor_detail_types: [],
};

const getters = {
  getSensorLocations(state) {
    return state.sensor_locations;
  },
  getSensorTypes(state) {
    return state.sensor_types;
  },
  getSensorDetailTypes(state) {
    return state.sensor_detail_types;
  },
};

const mutations = {
  setSensorLocations(state, payload) {
    state.sensor_locations = payload;
  },
  setSensorTypes(state, payload) {
    state.sensor_types = payload;
  },
  setSensorDetailTypes(state, payload) {
    state.sensor_detail_types = payload;
  },
};

const actions = {
  FETCH_PLC_SENSOR_LOCATION({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plc/sensor-location`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setSensorLocations', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  FETCH_PLC_SENSOR_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plc/sensor-type`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setSensorTypes', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  FETCH_PLC_SENSOR_DETAIL_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plc/sensor-detail-type`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setSensorDetailTypes', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
