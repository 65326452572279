import { companyInstance } from '@/api/index';
import { makeComma } from '@/utils/filters';
import clonedeep from 'lodash/cloneDeep';
import { formatDateNoHours, makeNumber } from '@/utils/filters';

const state = {
  sales: [],
  selectedIndex: -1,
  start_date: null,
  end_date: null,
  lot_sales: null,
  check_only_not_completed: true,
  managementData: {},
  managementOutputData: [],
  newSelectedIndex: -1,
  newData: null,
  newOutputData: [],
  newSales: [],
  newSalesData: null,
  newSalesSelectedIndex: -1,
  newSalesStartDate: null,
  newSalesEndDate: null,
  newSalesStartDate2: null,
  newSalesEndDate2: null,
  allNewSalesStartDate: null,
  allNewSalesEndDate: null,
  directOutputData: [],
  direct_sales: {
    company_id: null,
    sales_type_id: 2,
    completed: false,
    delivery_date: null,
    input_date: null,
    output_date: null,
    collection_type_id: null,
    detail: '',
    sales_products: [
      // {
      //   product_id: null,
      //   unit_id: null,
      //   box_unit_id: null,
      //   standard: '',
      //   quantity: 0,
      //   cost: 0, //단가
      //   supply_value: 0, //공급가
      //   tax: 0, //부가세
      //   total_cost: 0, //합계액
      //   discount_value : 0, //단일 할인액
      // },
    ],
  },

  salesBaseOutputDate: null,
  productBaseOutputDate: null,
  newOutputDate: null,
};
const getters = {
  getSalesFromOutput(state) {
    return state.sales.sort((a, b) => b.id - a.id);
  },
  getDirectSalesFromOutput(state) {
    return state.direct_sales;
  },
  getLotSalesFromOutput(state) {
    return state.lot_sales;
  },
  getManagementOutputData(state) {
    // return state.managementOutputData.sort(
    //   (a, b) => a.product_id - b.product_id,
    // );
    return state.managementOutputData;
  },
  getDirectOutputData(state) {
    // return state.directOutputData.sort((a, b) => a.product_id - b.product_id);
    return state.directOutputData;
  },
  getNewOutputData(state) {
    // return state.newOutputData.sort((a, b) => a.product_id - b.product_id);
    return state.newOutputData;
  },
  getSalesOnlyNotOutputCompletedFromOutput(state) {
    return state.sales
      .sort((a, b) => b.id - a.id)
      .filter(
        x =>
          x.product_list.find(y => y.output_yn == false) != undefined &&
          x.completed == false,
      );
  },
  getStartDateFromOutput(state) {
    return state.start_date;
  },
  getEndDateFromOutput(state) {
    return state.end_date;
  },
  getDateFromOutput(state) {
    return {
      start: formatDateNoHours(state.start_date),
      end: formatDateNoHours(state.end_date),
    };
  },
  getCheckOnlyNotCompletedFromOutput(state) {
    return state.check_only_not_completed;
  },
  getManagementDataFromOutput(state) {
    return state.managementData;
  },
  getManagementDataProductListFromOutput(state) {
    return state.managementData.product_list;
  },
  getManagementDataSalesProductsFromOutput(state) {
    return state.managementData.sales_products;
  },
  getManagementCollectionTypeIdFromOutput(state) {
    return state.managementData.collection_type_id;
  },
  getNewDataFromOutput(state) {
    return state.newData;
  },
  getSelectedIndexFromOutput(state) {
    return state.selectedIndex;
  },
  getNewSelectedIndexFromOutput(state) {
    return state.newSelectedIndex;
  },
  getNewSalesFromOutput(state) {
    return state.newSales.sort((a, b) => b.id - a.id);
  },
  getNewDataCollectionTypeIdFromOutput(state) {
    return state.newData.collection_type_id;
  },
  getNewSalesDataFromOutput(state) {
    return state.newSalesData;
  },
  getNewSalesSelectedIndexFromOutput(state) {
    return state.newSalesSelectedIndex;
  },
  getNewSalesStartDateFromOutput(state) {
    return state.newSalesStartDate;
  },
  getNewSalesEndDateFromOutput(state) {
    return state.newSalesEndDate;
  },
  getNewSalesStartDate2FromOutput(state) {
    return state.newSalesStartDate2;
  },
  getNewSalesEndDate2FromOutput(state) {
    return state.newSalesEndDate2;
  },
  getAllNewSalesStartDateFromOutput(state) {
    return state.allNewSalesStartDate;
  },
  getAllNewSalesEndDateFromOutput(state) {
    return state.allNewSalesEndDate;
  },
  getNewSalesDateFromOutput(state) {
    return {
      start: formatDateNoHours(state.newSalesStartDate),
      end: formatDateNoHours(state.newSalesEndDate),
    };
  },
  getSalesBaseOutputDateFromOutput(state) {
    return state.salesBaseOutputDate;
  },
  getProductBaseOutputDateFromOutput(state) {
    return state.productBaseOutputDate;
  },
  getNewOutputDateFromOutput(state) {
    return state.newOutputDate;
  },
};
const mutations = {
  setDirectSalesToOutput(state, payload) {
    state.direct_sales = payload;
  },

  setSalesToOutput(state, payload) {
    state.sales = payload;
  },
  setLotSalesToOutput(state, payload) {
    state.lot_sales = payload;
  },
  setManagementOutputData(state, payload) {
    // payload.forEach(x => {
    //   x.curr_stock = clonedeep(x.quantity);
    // });
    state.managementOutputData = payload;
  },
  setDirectOutputData(state, payload) {
    // payload.forEach(x => {
    //   x.curr_stock = clonedeep(x.quantity);
    // });
    state.directOutputData = payload;
  },
  setNewOutputData(state, payload) {
    // payload.forEach(x => {
    //   x.curr_stock = clonedeep(x.quantity);
    // });
    state.newOutputData = payload;
  },
  setManagementDataToOutput(state, payload) {
    payload.product_list = payload.product_list.sort((a, b) => a.id - b.id);

    state.managementData = payload;
    let productList = clonedeep(payload.product_list);
    state.managementData.sales_products = productList;
    state.managementData.sales_products.forEach(element => {
      if (element.discount == null) {
        element.discount = '0';
      } else {
        element.discount = makeComma(element.discount);
      }
      if (element.cost == null) {
        element.cost = '0';
        element.cost = '0';
      } else {
        element.cost = makeComma(element.cost);
      }
      element.collect_value = element.total_cost - makeNumber(element.discount);
    });
    state.managementData.product_list.forEach(element => {
      if (element.discount == null) {
        element.discount = '0';
      } else {
        element.discount = makeComma(element.discount);
      }
      if (element.cost == null) {
        element.cost = '0';
      } else {
        element.cost = makeComma(element.cost);
      }
      element.collect_value = element.total_cost - makeNumber(element.discount);
    });
  },
  deleteManagementDataProductListItemFromOutput(state, index) {
    state.managementData.product_list.splice(index, 1);
  },
  setManagementCollectionTypeIdToOutput(state, payload) {
    state.managementData.collection_type_id = payload;
  },
  setNewDataToOutput(state, payload) {
    state.newData = payload;
  },
  setSelectedIndexToOutput(state, index) {
    state.selectedIndex = index;
  },
  setNewBoxUnitIdToOutput(state, payload) {
    state.newData.box_unit_id = payload;
  },
  setNewDataCollectionTypeIdToOutput(state, payload) {
    state.newData.collection_type_id = payload;
  },
  setManagementBoxUnitIdToOutput(state, payload) {
    state.managementData.product_list[payload.index].box_unit_id =
      payload.value;
  },
  setDirectSalesBoxUnitIdToOutput(state, payload) {
    state.direct_sales.sales_product[payload.index].box_unit_id = payload.value;
  },
  toggleCheckOnlyNotCompletedToOutput(state) {
    state.check_only_not_completed = !state.check_only_not_completed;
  },
  setStartDateToOutput(state, payload) {
    state.start_date = payload;
  },
  setEndDateToOutput(state, payload) {
    state.end_date = payload;
  },
  setNewSelectedIndexToOutput(state, payload) {
    state.newSelectedIndex = payload;
  },
  setNewSalesToOutput(state, payload) {
    state.newSales = payload;
  },
  setNewSalesDataToOutput(state, payload) {
    state.newSalesData = payload;
  },
  setNewSalesSelectedIndexToOutput(state, payload) {
    state.newSalesSelectedIndex = payload;
  },
  setNewSalesStartDateToOutput(state, payload) {
    state.newSalesStartDate = payload;
  },
  setNewSalesEndDateToOutput(state, payload) {
    state.newSalesEndDate = payload;
  },
  setNewSalesStartDate2ToOutput(state, payload) {
    state.newSalesStartDate2 = payload;
  },
  setNewSalesEndDate2ToOutput(state, payload) {
    state.newSalesEndDate2 = payload;
  },
  setAllNewSalesStartDateToOutput(state, payload) {
    state.allNewSalesStartDate = payload;
  },
  setAllNewSalesEndDateToOutput(state, payload) {
    state.allNewSalesEndDate = payload;
  },
  setSalesBaseOutputDateToOutput(state, payload) {
    state.salesBaseOutputDate = payload;
  },
  setProductBaseOutputDateToOutput(state, payload) {
    state.productBaseOutputDate = payload;
  },
  setNewOutputDateToOutput(state, payload) {
    state.newOutputDate = payload;
  },
};
const actions = {
  FETCH_SALES_TO_OUTPUT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = `plan/sales/info/start_date=${payload.start_date}&end_date=${payload.end_date}`;
      if (
        payload.check_only_not_completed != undefined &&
        payload.check_only_not_completed == true
      ) {
        url += `&completed=false`;
      }
      companyInstance
        .get(url)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);

            response.data.data_list = response.data.data_list.map(x => {
              x.product_list = clonedeep(
                x.product_list.filter(x => x.output_yn == false),
              );
              return x;
            });

            commit('setSalesToOutput', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_LOT_SALES_TO_OUTPUT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/info/sales/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setLotSalesToOutput', response.data.data);
            resolve(response);
          } else {
            commit('setLotSalesToOutput', null);
            reject(response);
          }
        })
        .catch(error => {
          commit('setLotSalesToOutput', null);
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_SALES_ACCOUNT_ALL_TO_OUTPUT({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plan/sales/account/all`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setNewSalesToOutput', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `plan/sales/account/all/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setNewSalesToOutput', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_SALES_ALL_COMPLETE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put(`plan/sales/all/complete`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_SALES_PRODUCT_REQUEST_OUT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put(`plan/sales/product/requestout`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_SALES_ONE_ELEMENT_COMPLETE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put(`plan/sales/one-element/complete`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  INSERT_SALES_ACCOUNT_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post(`plan/sales/account/all`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_SALES_ACCOUNT_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`plan/sales/account/all`, { data: payload })
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
