import { companyInstance } from '@/api/index.js';
import { getUrlToCookie } from '@/utils/cookies';
import routes from '@/routes/routes';
// import UserActionTypeStore from '@/store/event/UserActionType.js';

const state = {
  menuItems: [],
  openedMenus: [],
};
const getters = {
  getMenuItems(state) {
    const menus = state.menuItems.sort((a, b) => a.id - b.id);
    menus.forEach(element => {
      element.sub_menu = element.sub_menu.sort(
        (a, b) => a.sub_menu_id - b.sub_menu_id,
      );
    });
    return menus;
  },
  getOpenedMenus(state) {
    return state.openedMenus;
  },
  getSubMenus() {
    const menuNameArr = [];
    state.menuItems.forEach(MenuItems => {
      MenuItems.sub_menu.forEach(sub_menu => {
        menuNameArr.push({
          name: sub_menu.name,
          path: sub_menu.sub_detail,
        });
      });
    });
    return menuNameArr;
  },
};
const mutations = {
  setMenuItems(state, payload) {
    payload.forEach(element => {
      element.menuVisible = false;
    });
    state.menuItems = payload;
  },
  async addOpenedMenu(state, to) {
    // console.log('addOpenMenu');
    for (let index = 0; index < state.openedMenus.length; index++) {
      const element = state.openedMenus[index];
      element.selected = false;
    }
    if (to.name != 'login' && to.name != 'main') {
      console.log('state.menuItems', state.menuItems);
      console.log('state.menuItems.length', state.menuItems.length);

      if (state.openedMenus.findIndex(x => x.path == to.path) > -1) {
        state.openedMenus.find(x => x.path == to.path).selected = true;
      } else {
        state.openedMenus.push({
          title: to.name,
          path: to.path,
          selected: true,
        });
      }

      if (to.path != '/patch-note' && state.menuItems.length != 0) {
        const hitMenu = state.menuItems
          .find(x => x.sub_menu.find(y => y.sub_detail == to.path))
          .sub_menu.find(x => x.sub_detail == to.path);
        console.log('hitMenu', hitMenu);
        if (hitMenu != undefined && hitMenu.sub_menu_id != 999) {
          await this.dispatch('INSERT_USER_ACTION', {
            detail: `${hitMenu.name} 페이지 접근`,
            user_action_type_id: this.getters.getUserActionId_ACCESS,
            menu_group_id: hitMenu.id,
          })
            .then(() => {
              console.log('ACCESS ACTION INSERTED');
            })
            .catch(() => {
              console.log('ACCESS ACTION NOT INSERTED : RETURN FAILED');
            });
        } else {
          console.log('ACCESS ACTION NOT INSERTED : MENU ID NOT FOUND');
        }
      }
    }
  },
  removeOpenedMenu(state, idx) {
    try {
      const closingPage = routes[0].children.find(
        x => x.path == state.openedMenus[idx].path,
      );
      closingPage.meta.menu_init = true;
      state.openedMenus.splice(idx, 1);
    } catch (error) {
      console.log(error, 'route가 올바른지 확인');
    }
  },
};
const actions = {
  async FETCH_ALL_MENUS({ commit }) {
    // 접속한 사용자의 account로 접근 가능한 메뉴 리스트를 불러옴
    // 추후 수정 예정
    const baseURL = companyInstance.defaults.baseURL;
    if (baseURL == null || baseURL == undefined || baseURL == '') {
      companyInstance.defaults.baseURL = getUrlToCookie();
    }
    return new Promise((resolve, reject) => {
      companyInstance
        .get('menu/all')
        .then(response => {
          if (response.data.result == 'success') {
            response.data.data_list.forEach(el => {
              el.detail == '/system'
                ? el.sub_menu.push({
                    detail: null,
                    id: 100,
                    main_menu_id: el.sub_menu[0].main_menu_id,
                    name: '기기 설정',
                    sub_detail: '/system/setting',
                    sub_menu_id: 999,
                  })
                : '';
            });
            commit('setMenuItems', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_ALL_USER_MENUS({ commit }, userId) {
    const baseURL = companyInstance.defaults.baseURL;
    if (baseURL == null || baseURL == undefined || baseURL == '') {
      companyInstance.defaults.baseURL = getUrlToCookie();
    }
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`menu/all/user/${userId}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setMenuItems', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
