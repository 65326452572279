/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Vuex from 'vuex';
import ImageStore from './system/image';
import UserMenuStore from './user-menu/menu';
import UserAuthStore from './auth/user';
import SessionStore from './auth/session';
import ModalStore from './common/modal_bus';
import SpinnerStore from './common/spinner_bus';
import forTestingStore from './forTesting';
import { formatDateNoHours } from '@/utils/filters';
import { yyyymmdd } from '@/utils/func';

// 메인 페이지
// 캘린더
import MainCalendarStore from './common/main_calendar';

//단위 환산
import UnitConversionStore from './common/unit_conversion';
//거래처 유형
import CompanyTypeStore from './common/company_type';
//자원 유형
import ResourceTypeStore from './common/resource_type';
//판매 유형
import SalesTypeStore from './common/sales_type';
//구매 유형
import PurchaseTypeStore from './common/purchase_type';
//User Action Tpye
import UserActionTypeStore from './event/UserActionType';
// 자재 입출고 유형
import MaterialInOutTypeStore from './common/material_in_out_type';
//공정 상태 유형
import LotTypeStore from './common/lot_type';
//제품 입출고 유형
import ProductInOutTypeStore from './common/product_in_out_type';
//구매처 유형
import PaymentTypeStore from './common/payment_type';
// 수금 유형
import CollectionTypeStore from './common/collection_type';

//공통자료관리 페이지
import CodePageStore from './code/codePage';
//단위
import UnitCodeStore from './code/unit';
//창고
import StorageStore from './code/store';
//설비
import MachineStore from './code/machine';
//박스
import BoxStore from './code/box';

//거래처 페이지
import CompanyPageStore from './base/company/companyPage';
import CompanyStore from './base/company/company';

//원자재 페이지
import MaterialPageStore from './base/material/materialPage';
import MaterialStore from './base/material/material';
import MaterialCompanyStore from './base/material/material_company';
import MaterialGroupStore from './base/material/material_group';

//제품 페이지
import ProductPageStore from './base/product/productPage';
import ProductStore from './base/product/product';
import SellerComapnyStore from './base/product/seller-company';
import ProductGroupStore from './base/product/product-group';

//공정 페이지
import ProcessPageStore from './base/process/processPage';
import ProcessStore from './base/process/process';

//공정 멀티페이지
import ProcessMultiPageStore from './base/process/processMultiPage';
import ProcessMultiStore from './base/process/processMulti';

//Bom 페이지
import BomPageStore from './base/bom/bomPage';
import BomStore from './base/bom/bom';

//User Action
import UserActionStore from './event/UserAction';

// 즐겨찾기 메뉴
import FavoriteStore from './favorite/favoriteMenuPage';

//사업자(회사)관리 페이지
import SystemCompanyPageStore from './system/systemCompanyPage';
import SystemCompanyStore from './system/systemCompany';

//사용자 관리 페이지
import UserPageStore from './system/UserPage';
import UserStore from './system/User';

//사용현황 리포트 페이지
import UserActionPageStore from './system/UserActionPage';
//UserAction 과 ActionType은 event에 위치
import UserReportStore from './system/UserReport';
//공지사항 관리 페이지
import NoticePageStore from './system/NoticePage';
import NoticeStore from './system/Notice';

//권한 관리 페이지
import AuthorityPageStore from './system/AuthorityPage';
import AuthorityStore from './system/Authority';

//주요 정보 관리 페이지
import CalendarStore from './system/Calendar';

//수주관리 페이지
import SalesPageStore from './plan/sales/salesPage';
import SalesStore from './plan/sales/sales';

//소요량산출 페이지
import CalculationPageStore from './plan/calculation/calculationPage';
import CalculationStore from './plan/calculation/calculation';

//발주관리 페이지
import PurchaseStore from './plan/purchase/purchase';
import PurchasePageStore from './plan/purchase/purchasePage';

//원자재입고 페이지
import InputPageStore from './material/input/inputPage';
import InputStore from './material/input/input';
//원자재 재고 관련
import MaterialControlStore from './material/material_control';

// 입고식별표 출력  페이지
import InputPrintPageStore from './material/inputPrint/InputPrintPage';
import InputPrintStore from './material/inputPrint/InputPrint';

// 원자재 현황 조회 페이지
import MaterialStatusPageStore from './material/status/MaterialStatusPage';
import MaterialStatusStore from './material/status/MaterialStatus';

// 원자재 반품 페이지
import MaterialReturnStore from './material/return/material_return';
import MaterialReturnPageStore from './material/return/material_returnPage';

// 원자재&구매처 원장 페이지
import LedgerPageStore from './material/ledger/LedgerPage';
import LedgerStore from './material/ledger/Ledger';
import PurchaseLedgerStore from './material/ledger/purchaseLedger';

//구매처 지급관리
import PaymentPageStore from './material/payment/paymentPage';
import PaymentStore from './material/payment/payment';

//작업지시 페이지
import WorkOrderPageStore from './process/workorder/workorderPage';
import WorkOrderStore from './process/workorder/workorder';
//생산공정진행 페이지
import ProductionPageStore from './process/production/productionPage';
import ProductionStore from './process/production/production';
//생산완료&식별표 출력 페이지
import PrintCompletePageStore from './process/complete/completePage';
import PrintCompleteStore from './process/complete/complete';
//공정 진행현황 페이지
import ProcessStatusStore from './process/process-status/process-status';
//제품 출고 페이지
import OutputPageStore from './product/output/outputPage';
import OutputStore from './product/output/output';
//제품 반품 페이지
import ProductReturnStore from './product/return/product_return';
import ProductReturnPageStore from './product/return/product_returnPage';

//제품 재고/입출고현황 페이지
import ProductStatusStore from './product/status/ProductStatus';
import ProductStatusPageStore from './product/status/ProductStatusPage';
// 제품&매출처 원장 페이지
import ProductLedgerPageStore from './product/ledger/ProductLedgerPage';
import ProductLedgerStore from './product/ledger/ProductLedger';
import SalesLedgerStore from './product/ledger/salesLedger';
// 제품 LOT 추적 페이지
import LotTrackingPageStore from './product/tracking/lotTrackingPage';
import LotTrackingStore from './product/tracking/lotTracking';
import CostCalculationStore from './product/tracking/costCalculation';
//제품 재고 관련
import ProductControlStore from './product/product_control';
// 수금 관리 페이지
import CollectionPageStore from './product/collection/collectionPage';
import CollectionStore from './product/collection/collection';
import SalesCollectionStatusStore from './product/collection/sales-collection-status';
import ExchangeCollectionStore from './business/exchange/exchange_collection';
import ExchangePaymentStore from './business/exchange/exchange_payment';

//품질
//품질 검사기준 관리 페이지
import QualityManagementPageStore from './quality/management/qualityManagementPage';
import QualityManagementStore from './quality/management/qualityManagement';
//원자재 수입검사 페이지
import MaterialVerificationPageStore from './quality/materialVerification/materialVerificationPage';
import MaterialVerificationStore from './quality/materialVerification/materialVerification';
//제품검사 페이지
import ProductVerificationPageStore from './quality/productVerification/productVerificationPage';
import ProductVerificationStore from './quality/productVerification/productVerification';
//공정검사 페이지
import SeqVerificationPageStore from './quality/seqVerification/seqVerificationPage';
import SeqVerificationStore from './quality/seqVerification/seqVerification';
//검사 성적서 페이지
import QualityPrintStore from './quality/print/qualityPrint';
// 품질분석 리포트 페이지
import QualityReportPageStore from './quality/report/qualityReportPage';
import ReportAllStore from './quality/report/reportAll';
import ReportMaterialStore from './quality/report/reportMaterial';
import ReportProductStore from './quality/report/reportProduct';
import ReportProcessStore from './quality/report/reportProcess';

// 패치노트페이지
import PatchNotePageStore from './patch-note/PatchNotePage';
import PatchNoteStore from './patch-note/PatchNote';

import SanchaeProductVerificationStore from './quality/5/sanchaeProductVerification';

//CustomPages
import StatusMoniotrPageStore from './custom/monitor/statusMonitorPage';
import PlcMonitorPageStore from './custom/monitor/plcMonitorPage.js';
import PlcSensorLogStore from './custom/monitor/plcSensorLog.js';
import PlcSensorStore from './custom/monitor/plcSensor.js';
import PlcCounterLogStore from './custom/monitor/plcCounterLog.js';
import DailyOutputStore from './custom/monitor/daily_output';
import ShipmentOutputStore from './custom/monitor/shipment_request';
import KPIMonitorStore from './custom/monitor/kpi_monitor';
//haccp 모니터링
import HaccpPageStore from './custom/haccp/20/haccpPage';

//kpi 모니터링
import KpiTargetStore from './custom/monitor/kpi_target';

// plc sensor log 조회 타입
import PlcSensorLogSearchTypeStore from '@/store/custom/monitor/plcSensorLogSearchType';
import SettingPageStore from './system/SettingPage';
//plc erorr log
import PlcErrorLogStore from '@/store/custom/monitor/plcErrorLog';

//금산진생 생산계획 관리
import gumsanPlanManagementPageStore from './plan/6/gumsanPlanManagementPage';
import PlanStore from './plan/plan/plan';

//코리아닥터 불량수량
import PoorQualityStore from './custom/monitor/poor_quality';

//운석식품 작업지시 - 탱크 연결관계
import LotTankStore from './custom/process/8/lot_tank';
import DailyTankLogStore from './custom/monitor/daily_tank_log';

//우원식품 x-ray 검출기
import UwonXrayLogStore from './custom/monitor/uwon_xray';

//cns x-ray 검출기
import CNSXrayLogStore from './custom/monitor/cns_xray';

// 태광에이택 목표설정 페이지
import TargetQuantityPageStore from './plan/19/targetQuantityPage';
import TargetQuantityStore from './plan/19/targetQuantity';

/**
 * V2 고도화 기능
 */

// 거래 관리 페이지
import ExchangeManagementPageStore from './business/exchange/exchange_managementPage';
import QuoteStore from './business/exchange/quote';

// 계산서 관리
import BillManagementPageStore from './business/bill/bill_managementPage';
import BillStore from './business/bill-management/bill';

// 매출분석
import SalesAnalysisPageStore from './business/sales-analysis/sales_analysisPage';

// 창고관리
import InputManagementPageStore from './storage/input-management/inputManagementPage';
import MaterialStorageInputStore from './storage/input-management/materialStorageInput';
import ProductStorageInputStore from './storage/input-management/productStorageInput';
import OutputManagementPageStore from './storage/output-management/outputManagementPage';

// 설비관리
// 설비기기관리
import MachineManagementPageStore from './machine/machine-management/machineManagementPage';
import MachineCheckPageStore from './machine/machine-check/machineCheckPage';
import SpareManagementPageStore from './machine/spare-management/spareManagementPage';
import SpareStore from './machine/spare-management/spare';
import LifetimeManagementPageStore from './machine/lifetime-management/lifetimeManagementPage';
import OperationInfo from './machine/lifetime-management/operationInfo';
import PartsReplaeManagement from './machine/lifetime-management/partsReplaeManagement';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    SessionStore,
    MainCalendarStore,
    ImageStore,

    usermenu: UserMenuStore,
    userinfo: UserAuthStore,
    codepage: CodePageStore,
    unitcode: UnitCodeStore,
    StorageStore,
    MachineStore,
    BoxStore,
    modal: ModalStore,
    spinner: SpinnerStore,
    FavoriteStore,
    fortest: forTestingStore,
    SalesTypeStore,
    PurchaseTypeStore,
    LotTypeStore,
    MaterialInOutTypeStore,
    ProductInOutTypeStore,
    PaymentTypeStore,
    CollectionTypeStore,
    PlcSensorLogSearchTypeStore,

    MaterialControlStore,
    UnitConversionStore,
    UserActionStore,
    UserActionTypeStore,
    CompanyTypeStore,
    CompanyPageStore,
    CompanyStore,
    ResourceTypeStore,
    MaterialPageStore,
    MaterialStore,
    MaterialCompanyStore,
    MaterialGroupStore,
    ProductPageStore,
    ProductStore,
    SellerComapnyStore,
    ProductGroupStore,

    ProcessPageStore,
    ProcessStore,
    ProcessMultiPageStore,
    ProcessMultiStore,
    BomPageStore,
    BomStore,

    //plan
    SalesPageStore,
    SalesStore,
    CalculationPageStore,
    CalculationStore,
    PurchaseStore,
    PurchasePageStore,

    //material
    InputPageStore,
    InputStore,
    InputPrintPageStore,
    InputPrintStore,
    MaterialStatusPageStore,
    MaterialStatusStore,
    MaterialReturnStore,
    MaterialReturnPageStore,
    LedgerPageStore,
    LedgerStore,
    PurchaseLedgerStore,
    PaymentPageStore,
    PaymentStore,

    //process
    WorkOrderPageStore,
    WorkOrderStore,
    ProductionPageStore,
    PrintCompletePageStore,
    PrintCompleteStore,
    ProcessStatusStore,

    //product
    ProductControlStore,
    ProductionStore,
    OutputPageStore,
    OutputStore,
    ProductStatusStore,
    ProductStatusPageStore,
    ProductLedgerPageStore,
    ProductLedgerStore,
    SalesLedgerStore,
    ProductReturnStore,
    ProductReturnPageStore,
    LotTrackingPageStore,
    LotTrackingStore,
    CostCalculationStore,
    CollectionPageStore,
    CollectionStore,
    SalesCollectionStatusStore,
    ExchangeCollectionStore,
    ExchangePaymentStore,

    //system
    SystemCompanyPage: SystemCompanyPageStore,
    SystemCompanyStore,
    UserPageStore,
    UserStore,
    UserActionPageStore,
    NoticePageStore,
    NoticeStore,
    AuthorityPageStore,
    AuthorityStore,
    CalendarStore,
    UserReportStore,

    //quality
    QualityManagementPageStore,
    QualityManagementStore,
    MaterialVerificationPageStore,
    MaterialVerificationStore,
    ProductVerificationPageStore,
    ProductVerificationStore,
    SeqVerificationPageStore,
    SeqVerificationStore,
    QualityPrintStore,
    QualityReportPageStore,
    ReportAllStore,
    ReportMaterialStore,
    ReportProductStore,
    ReportProcessStore,

    // patchNote
    PatchNotePageStore,
    PatchNoteStore,

    //custom
    SanchaeProductVerificationStore,
    StatusMoniotrPageStore,
    PlcMonitorPageStore,
    PlcSensorLogStore,
    PlcSensorStore,
    PlcCounterLogStore,
    DailyOutputStore,
    ShipmentOutputStore,
    PlcErrorLogStore,
    KPIMonitorStore,
    KpiTargetStore,
    HaccpPageStore,

    //setting
    SettingPageStore,

    //gumsan custom
    gumsanPlanManagementPageStore,
    PlanStore,

    //kdoctor custom
    PoorQualityStore,

    //unseog custom
    LotTankStore,
    DailyTankLogStore,

    //uwon custom
    UwonXrayLogStore,

    //cns custom
    CNSXrayLogStore,

    //V2 bill
    BillStore,
    ExchangeManagementPageStore,
    QuoteStore,
    BillManagementPageStore,
    SalesAnalysisPageStore,

    //V2 machine
    MachineManagementPageStore,
    MachineCheckPageStore,
    SpareManagementPageStore,
    SpareStore,
    LifetimeManagementPageStore,
    OperationInfo,
    PartsReplaeManagement,

    // V2 storage
    InputManagementPageStore,
    MaterialStorageInputStore,
    ProductStorageInputStore,
    OutputManagementPageStore,

    // target quantity
    TargetQuantityPageStore,
    TargetQuantityStore,
  },

  mutations: {
    InitCodePage(state) {
      state.codepage.openTabIndex = 0;
      state.codepage.managementMode = false;
      state.unitcode.unitCodes = [];
      state.unitcode.selectedIndex = -1;
      state.unitcode.management_data = {
        id: -1,
        header: '신규 등록',
        name: '',
        detail: '',
      };
      state.UnitConversionStore.unit_conversions = [];
      state.UnitConversionStore.selectedIndex = -2;
      state.UnitConversionStore.managementData = null;
      state.StorageStore.stores = [];
      state.StorageStore.selectedIndex = {
        depth: 1,
        index: -1,
      };
      state.StorageStore.managementData = {
        id: -1,
        name: '',
        detail: '',
      };
      state.MachineStore.machines = [];
      state.MachineStore.selectedIndex = -1;
      state.MachineStore.search_machine = null;
      state.MachineStore.manangementData = {
        id: -1,
        name: '',
        detail: '',
        machine_location: '',
        process_verification_id: -1,
        counter_value: [],
      };
      // state.unitcode.isTouched = false;
      state.BoxStore.boxs = [];
      state.BoxStore.selectedIndex = -1;
      state.BoxStore.manangementData = {
        id: -1,
        name: '',
        detail: '',
        quantity: 0,
        r_box_unit_material_list: [],
      };
      state.BoxStore.cloneData = {
        id: -1,
        name: '',
        detail: '',
        quantity: 0,
        r_box_unit_material_list: [],
      };
    },
    InitCompanyPage(state) {
      state.CompanyPageStore.openTabIndex = 0;
      state.CompanyPageStore.managementMode = false;
      state.CompanyPageStore.start_date = undefined;
      state.CompanyPageStore.end_date = undefined;
      state.CompanyPageStore.search_type = null;
      state.CompanyStore.companys = [];
      state.CompanyStore.purchase_company_balance_list = [];
      state.CompanyStore.sales_company_balance_list = [];
      state.CompanyStore.selectedIndex = -1;
      state.CompanyStore.managementData = {
        company_type_id: null, // 회사 유형
        name: '', //회사명
        representative: '', //대표자
        business_type: '', //업태
        business_item: '', //업종
        representative_item: '', //대표 품목
        address: '', //주소
        detail_address: '', //상세 주소
        account_number: '', //계좌번호,
        post_number: '', //우편번호
        email: '', //이메일
        phone_number: '', //대표 번호
        fax_number: '', //팩스번호
        business_date: '', //거래 개시일
        saup_no: '', //사업자번호
        vat: null, //부가세 구분
        detail: '', //비고
        manager: '',
      };
      state.CompanyStore.newData = {
        company_type_id: null, // 회사 유형
        name: '', //회사명
        representative: '', //대표자
        business_type: '', //업태
        business_item: '', //업종
        representative_item: '', //대표 품목
        address: '', //주소
        detail_address: '', //상세 주소
        account_number: '', //계좌번호,
        post_number: '', //우편번호
        email: '', //이메일
        phone_number: '', //대표 번호
        fax_number: '', //팩스번호
        business_date: formatDateNoHours(new Date()),
        saup_no: '', //사업자번호
        vat: null, //부가세 구분
        detail: '', //비고
        manager: '',
      };
      state.CompanyTypeStore.company_types = [];
    },
    InitMaterialPage(state) {
      state.MaterialPageStore.openTabIndex = 0;
      state.MaterialPageStore.managementMode = false;
      state.MaterialPageStore.search_type = null;
      state.MaterialStore.materials = [];
      state.MaterialStore.selectedIndex = -1;
      state.MaterialStore.check_only_group_material = false;
      state.MaterialStore.managementData = {
        id: -1,
        name: '',
        detail: '',
        standard: '',
        inspection: null,
        stock_size: 0,
        tax: null,
        barcode: '',
        material_group_yn: false,
        resource_type_id: null,
        incoming_unit_id: null,
        using_unit_id: null,
        default_store_id: null,
        order_unit: 0,
        company_list: [],
      };
      state.MaterialStore.del_manage_array = [];
      state.MaterialStore.newData = {
        name: '',
        detail: '',
        standard: '',
        inspection: null,
        stock_size: 0,
        tax: null,
        barcode: '',
        material_group_yn: false,
        resource_type_id: null,
        incoming_unit_id: null,
        using_unit_id: null,
        default_store_id: null,
        order_unit: 0,
      };
      state.MaterialStore.newCompanys = [];
      state.MaterialStore.groupMaterials = [];
      state.MaterialStore.del_new_array = [];
      state.MaterialCompanyStore.material_companys = [];
      state.CompanyStore.companys = [];
    },
    InitProductPage(state) {
      state.ProductPageStore.openTabIndex = 0;
      state.ProductPageStore.managementMode = false;
      state.ProductPageStore.search_type = null;
      state.ProductStore.products = [];
      state.ProductStore.check_only_group_product = false;
      state.ProductStore.selectedIndex = -1;
      state.ProductStore.managementData = {
        id: -1,
        product_group_yn: false,
        product_group_list: [],
        product_group_type_id: null,
        name: '',
        standard: '',
        code: '',
        stock_size: 0,
        stock_unit_id: null,
        delivery_unit_id: -1,
        resource_type_id: null,
        box_unit_id: -1,
        default_store_id: null,
        detail: '',
        company_list: [],
        tax: null,
        inspection: null,
        expire_year: 0,
        expire_month: 0,
        expire_day: 0,
        oem_product_yn: false,
        crushed_product_yn: false,
      };
      state.ProductStore.newData = {
        name: '',
        product_group_yn: false,
        product_group_list: [],
        product_group_type_id: null,
        standard: '',
        code: '',
        stock_size: 0,
        stock_unit_id: null,
        delivery_unit_id: -1,
        resource_type_id: null,
        box_unit_id: -1,
        default_store_id: null,
        detail: '',
        company_list: [],
        tax: null,
        inspection: null,
        expire_year: 0,
        expire_month: 0,
        expire_day: 0,
        oem_product_yn: false,
        crushed_product_yn: false,
      };
      state.SellerComapnyStore.seller_companys = [];
      state.CompanyStore.companys = [];
    },
    InitProcessPage(state) {
      state.ProcessPageStore.openTabIndex = 0;
      state.ProcessPageStore.managementMode = false;
      state.ProcessStatusStore.target_lot = null;
      state.ProcessPageStore.visibleDel = false;
      state.ProcessStatusStore.selectedIndex = -1;
      state.ProcessStatusStore.keyCountForReDraw = 0;
      state.ProcessStatusStore.lot_status_list = [];
      state.ProcessStore.process = [];
      state.ProcessStore.selectedIndex = -1;
      state.ProcessStore.managementData = {
        id: -1,
        name: '',
        detail: '',
        machine_id: null,
        package_process_yn: false,
        process_verification_id: null,
      };
      state.ProcessStore.newData = {
        name: '',
        detail: '',
        machine_id: null,
        package_process_yn: false,
        process_verification_id: null,
      };
      state.MachineStore.machines = [];
    },
    InitProcessMultiPage(state) {
      state.ProcessMultiPageStore.openTabIndex = 0;
      state.ProcessMultiPageStore.managementMode = false;
      state.ProcessMultiPageStore.visibleDel = false;
      state.ProcessMultiStore.process = [];
      state.ProcessMultiStore.selectedIndex = -1;
      state.ProcessMultiStore.managementData = {
        id: -1,
        name: '',
        detail: '',
        machine_id: null,
        package_process_yn: false,
        process_verification_id: null,
        visible: false,
        r_process_machine_list: [],
      };
      state.ProcessMultiStore.newData = {
        name: '',
        detail: '',
        machine_id: null,
        package_process_yn: false,
        process_verification_id: null,
        visible: false,
        r_process_machine_list: [],
      };
      state.MachineStore.machines = [];
    },
    InitBomPage(state) {
      state.BomPageStore.openTabIndex = 0;
      state.BomPageStore.managementMode = false;
      state.BomStore.boms = [];
      state.BomStore.showNonBomProducts = false;
      state.BomStore.selectedIndex = -1;
      state.BomStore.managementData = {
        id: -1,
        name: '',
        product_id: null,
        bom_process: [],
      };
      state.BomStore.cloneData = {
        id: -1,
        name: '',
        product_id: null,
        bom_process: [],
      };
      state.BomStore.newData = {
        name: '',
        product_id: null,
        bom_process: [],
      };
      state.BomStore.manageSearchText = '';
      state.BomStore.newSearchText = '';
    },
    InitSalesPage(state) {
      state.SalesPageStore.openTabIndex = 0;
      state.SalesPageStore.managementMode = false;
      state.SalesStore.sales = [];
      state.SalesStore.lot_sales = null;
      state.SalesStore.selectedIndex = -1;
      state.SalesStore.start_date = null;
      state.SalesStore.end_date = null;
      state.SalesStore.check_only_not_completed = true;
      state.SalesStore.managementData = {
        id: null,
        company_id: null,
        sales_type_id: null,
        completed: false,
        delivery_date: null,
        input_date: null,
        detail: '',
        sales_products: [],
      };
      state.SalesStore.cloneData = {
        id: null,
        company_id: null,
        sales_type_id: null,
        completed: false,
        delivery_date: null,
        input_date: null,
        detail: '',
        sales_products: [],
      };
      state.SalesStore.newData = {
        company_id: null,
        sales_type_id: 1,
        completed: false,
        delivery_date: null,
        input_date: null,
        detail: '',
        sales_products: [],
      };
    },
    InitTargetQuantityPage(state) {
      state.TargetQuantityPageStore.openTabIndex = 0;
      state.TargetQuantityPageStore.managementMode = false;
      state.TargetQuantityStore.target_quantity = [];
      state.TargetQuantityStore.start_date = null;
      state.TargetQuantityStore.end_date = null;
      state.TargetQuantityStore.selectedIndex = -1;
      state.TargetQuantityStore.managementData = {
        id: null,
        work_date: null,
        product_id: null,
        quantity: 0,
        user_id: null,
        comment: '',
      };
      state.TargetQuantityStore.newData = {
        work_date: formatDateNoHours(new Date()),
        product_id: null,
        quantity: 0,
        user_id: null,
        comment: '',
      };
    },
    InitCalculationPage(state) {
      state.CalculationPageStore.openTabIndex = 0;
      state.CalculationPageStore.managementMode = false;
      state.CalculationStore.start_date = null;
      state.CalculationStore.end_date = null;
      state.CalculationStore.selected_product_list = [];
      state.CalculationStore.selected_material_list = [];
      state.CalculationStore.group_material_list = [];
    },
    InitPurchasePage(state) {
      state.PurchasePageStore.openTabIndex = 0;
      state.PurchasePageStore.managementMode = false;
      state.PurchaseStore.purchase = [];
      state.PurchaseStore.purchase_material_purchase = [];
      state.PurchaseStore.selectedIndex = -1;
      state.PurchaseStore.start_date = null;
      state.PurchaseStore.end_date = null;
      state.PurchaseStore.check_only_not_completed = true;
      state.PurchaseStore.managementData = {
        id: null,
        company_id: null,
        purchase_type_id: null,
        completed: false,
        incoming_date: null,
        input_date: null,
        detail: '',
        purchase_materials: [],
      };
      state.PurchaseStore.cloneData = {
        id: null,
        company_id: null,
        purchase_type_id: null,
        completed: false,
        incoming_date: null,
        input_date: null,
        detail: '',
        purchase_materials: [],
      };
      state.PurchaseStore.newData = {
        company_id: null,
        purchase_type_id: 1,
        completed: false,
        incoming_date: null,
        input_date: null,
        detail: '',
        purchase_materials: [],
      };
    },
    InitInputPage(state) {
      state.InputPageStore.openTabIndex = 0;
      state.InputStore.companyManagementData = {};
      state.InputStore.companySelectedIndex = -1;
      state.InputStore.materialManagementData = {};
      state.InputStore.materialSelectedIndex = -1;
      state.InputStore.purchase_account = [];
      state.InputStore.inputSelectedIndex = -1;
      state.InputStore.inputManagementData = null;
      state.InputStore.input_start_date = null;
      state.InputStore.input_end_date = null;
      state.InputStore.input_date = null;
      state.InputStore.material_base_input_date = null;
    },
    InitInputPrintPage(state) {
      state.InputPrintPageStore.openTabIndex = 0;
      state.InputPrintStore.startDate = null;
      state.InputPrintStore.endDate = null;
      state.InputPrintStore.searchText = '';
      state.InputPrintStore.inputPrintList = [];
    },
    InitMaterialReturnPage(state) {
      state.MaterialReturnPageStore.openTabIndex = 0;
      state.MaterialReturnPageStore.managementMode = false;
      state.MaterialReturnStore.returnableList = [];
      state.MaterialReturnStore.selectedIndex = -1;
      state.MaterialReturnStore.searchText = '';
      state.MaterialReturnStore.companySearchText = '';
      state.MaterialReturnStore.managementData = null;
      state.MaterialReturnStore.start_date = null;
      state.MaterialReturnStore.end_date = null;
      state.MaterialReturnStore.return_list = [];
      state.MaterialReturnStore.status_selectedIndex = -1;
      state.MaterialReturnStore.status_searchText = '';
      state.MaterialReturnStore.status_companySearchText = '';
      state.MaterialReturnStore.status_managementData = null;
      state.MaterialReturnStore.status_start_date = null;
      state.MaterialReturnStore.status_end_date = null;
    },
    InitMaterialStatusPage(state) {
      state.MaterialStatusPageStore.openTabIndex = 0;
      state.MaterialStatusPageStore.managementMode = false;
      state.MaterialStatusStore.startDate = null;
      state.MaterialStatusStore.endDate = null;
      state.MaterialStatusStore.searchText = '';
      state.MaterialStatusStore.companyText = '';
      state.MaterialStatusStore.searchStockText = '';
      state.MaterialStatusStore.selectedIndex = -1;
      state.MaterialStatusStore.MaterialStatusList = [];
      state.MaterialStatusStore.stockStatusList = [];
      state.MaterialStatusStore.modalClose = true;
      state.MaterialStatusStore.searchStoreLocation = null;
      state.MaterialStatusStore.searchMaterialResource = null;
      state.MaterialStatusStore.exceptStockZero = false;
    },
    InitLedgerPage(state) {
      state.LedgerPageStore.openTabIndex = 0;
      state.LedgerStore.startDate = null;
      state.LedgerStore.endDate = null;
      state.LedgerStore.radioList = {
        check_all: true,
        check_include: false,
        check_exclude: false,
        checkIn: '',
      };
      state.LedgerStore.filterArray = [];
      state.LedgerStore.filterCheck = [-1];
      state.LedgerStore.materialLedgerList = [];
      state.PurchaseLedgerStore.purchase_account = [];
      state.PurchaseLedgerStore.start_date = null;
      state.PurchaseLedgerStore.end_date = null;
      state.PurchaseLedgerStore.include_company_list = [];
    },
    InitProductLedgerPage(state) {
      state.ProductLedgerPageStore.openTabIndex = 0;
      state.ProductLedgerStore.startDate = null;
      state.ProductLedgerStore.endDate = null;
      state.ProductLedgerStore.radioList = {
        check_all: true,
        check_include: false,
        check_exclude: false,
        checkIn: '',
      };
      state.ProductLedgerStore.filterArray = [];
      state.ProductLedgerStore.filterCheck = [-1];
      state.ProductLedgerStore.productLedgerList = [];
      state.ProductLedgerStore.include_company_list = [];
      state.SalesLedgerStore.sales_account = [];
      state.SalesLedgerStore.start_date = null;
      state.SalesLedgerStore.end_date = null;
      state.SalesLedgerStore.include_company_list = [];
    },
    InitWorkOrderPage(state) {
      state.WorkOrderPageStore.openTabIndex = 0;
      state.WorkOrderPageStore.managementMode = false;
      state.WorkOrderStore.lots = [];
      state.WorkOrderStore.start_date = null;
      state.WorkOrderStore.end_date = null;
      state.WorkOrderStore.selectedIndex = -1;
      state.WorkOrderStore.managementData = {
        id: null,
        input_date: null,
        deadline_date: null,
        product_id: null,
        bom_id: null,
        quantity: 0,
        sales_id: null,
        company_id: null,
        detail: '',
        // emergency_yn: false,
      };
      state.WorkOrderStore.newData = {
        input_date: null,
        deadline_date: null,
        product_id: null,
        bom_id: null,
        quantity: 0,
        sales_id: null,
        company_id: null,
        detail: '',
        emergency_yn: false,
      };
      state.WorkOrderStore.newSelectedSalesProducts = [];
      state.WorkOrderStore.managementSelectedSalesProducts = [];
    },
    InitProductionPage(state) {
      state.ProductionPageStore.showLotSearch = false;
      state.ProductionStore.target_lot = null;
      state.ProductionStore.keyCountForReDraw = 0;
    },
    InitPrintCompletePage(state) {
      state.PrintCompleteStore.startDate = null;
      state.PrintCompleteStore.endDate = null;
      state.PrintCompleteStore.searchText = '';
      state.PrintCompleteStore.PrintCompleteList = [];
    },
    InitOutputPage(state) {
      state.OutputPageStore.openTabIndex = 0;
      state.OutputPageStore.managementMode = true;
      state.OutputStore.sales = [];
      state.OutputStore.selectedIndex = -1;
      state.OutputStore.start_date = null;
      state.OutputStore.end_date = null;
      state.OutputStore.lot_sales = null;
      state.OutputStore.check_only_not_completed = true;
      state.OutputStore.managementData = {};
      state.OutputStore.managementOutputData = [];
      state.OutputStore.newSelectedIndex = -1;
      state.OutputStore.newData = null;
      state.OutputStore.newOutputData = [];
      state.OutputStore.newSales = [];
      state.OutputStore.newSalesData = null;
      state.OutputStore.newSalesSelectedIndex = -1;
      state.OutputStore.newSalesStartDate = null;
      state.OutputStore.newSalesEndDate = null;
      state.OutputStore.directOutputData = [];
      state.OutputStore.direct_sales = {
        company_id: null,
        sales_type_id: 2,
        completed: false,
        delivery_date: null,
        input_date: null,
        collection_type_id: null,
        detail: '',
        sales_products: [],
      };
      state.OutputStore.salesBaseOutputDate = null;
      state.OutputStore.productBaseOutputDate = null;
      state.OutputStore.newOutputDate = null;
    },
    InitProductReturnPage(state) {
      state.ProductReturnStore.returnableList = [];
      state.ProductReturnStore.selectedIndex = -1;
      state.ProductReturnStore.searchText = '';
      state.ProductReturnStore.companySearchText = '';
      state.ProductReturnStore.managementData = null;
    },
    InitProductStatusPage(state) {
      state.ProductStatusPageStore.openTabIndex = 0;
      state.ProductStatusStore.startDate = null;
      state.ProductStatusStore.endDate = null;
      state.ProductStatusStore.searchText = '';
      state.ProductStatusStore.companyText = '';
      state.ProductStatusStore.searchStockText = '';
      state.ProductStatusStore.selectedIndex = -1;
      state.ProductStatusStore.productStatusList = [];
      state.ProductStatusStore.stockStatusList = [];
      state.ProductStatusStore.modalClose = true;
      state.ProductStatusStore.searchStoreLocation = null;
      state.ProductStatusStore.searchProductResource = null;
      state.ProductStatusStore.exceptStockZero = false;
    },
    InitLotTrackingPage(state) {
      state.LotTrackingStore.target_lot = null;
      state.LotTrackingStore.tracking_resource = [];
      state.LotTrackingStore.tracking_sales = [];
      state.LotTrackingStore.selectedTab = 0;
      state.LotTrackingStore.searchText = '';
    },
    InitQualityManagementPage(state) {
      state.QualityManagementPageStore.openTabIndex = 0;
      state.QualityManagementPageStore.managementMode = false;
      state.QualityManagementStore.base_verification_list = [];
      state.QualityManagementStore.managementData = {
        verification_type_id: 0,
        inspection_item: '',
        pass_standard: '',
        inspection_equipment: '',
        inspection_timing: '',
      };
      state.QualityManagementStore.selectedIndex = -1;
    },
    InitMaterialVerificationPage(state) {
      state.MaterialVerificationPageStore.openTabIndex = 0;
      state.MaterialVerificationStore.check_lists = [];
      state.MaterialVerificationStore.start_date = null;
      state.MaterialVerificationStore.end_date = null;
      state.MaterialVerificationStore.managementData = null;
      state.MaterialVerificationStore.modifyChkData = null;
      state.MaterialVerificationStore.selectedIndex = -1;
    },
    InitProductVerificationPage(state) {
      state.ProductVerificationPageStore.openTabIndex = 0;
      state.ProductVerificationStore.check_lists = [];
      state.ProductVerificationStore.start_date = null;
      state.ProductVerificationStore.end_date = null;
      state.ProductVerificationStore.managementData = null;
      state.ProductVerificationStore.modifyChkData = null;
      state.ProductVerificationStore.selectedIndex = -1;
    },
    InitSeqVerificationPage(state) {
      state.SeqVerificationPageStore.openTabIndex = 0;
      state.SeqVerificationStore.check_lists = [];
      state.SeqVerificationStore.target_lot = null;
      state.SeqVerificationStore.search_text = '';
      state.SeqVerificationStore.managementData = null;
      state.SeqVerificationStore.modifyChkData = null;
      state.SeqVerificationStore.selectedIndex = -1;
    },
    InitQualityPrintPage(state) {
      state.QualityPrintStore.startDate = null;
      state.QualityPrintStore.endDate = null;
      state.QualityPrintStore.radio_material = true;
      state.QualityPrintStore.radio_process = false;
      state.QualityPrintStore.radio_product = false;
      state.QualityPrintStore.material_defect_list = [];
      state.QualityPrintStore.seq_defect_list = [];
      state.QualityPrintStore.product_defect_list = [];
    },
    InitQualityReportPage(state) {
      state.QualityReportPageStore.openTabIndex = 0;
      state.ReportAllStore.startDate = null;
      state.ReportAllStore.endDate = null;
      state.ReportAllStore.radioState = {
        radio_material: true,
        radio_process: false,
        radio_product: false,
      };
      state.ReportAllStore.radioSearch = {
        radio_material: true,
        radio_process: false,
        radio_product: false,
      };
      state.ReportAllStore.materialReportData = [];
      state.ReportAllStore.seqReportData = [];
      state.ReportAllStore.productReportData = [];
      state.ReportMaterialStore.material_reports = [];
      state.ReportMaterialStore.startDate = null;
      state.ReportMaterialStore.endDate = null;
      state.ReportMaterialStore.radio = {
        all: true,
        include: false,
        notInclude: false,
      };
      state.ReportMaterialStore.filterResourceTypeArray = null;
      state.ReportMaterialStore.filterMaterialArray = [];
      state.ReportMaterialStore.searchFilterResourceType = [];
      state.ReportMaterialStore.searchFilterMaterial = [];
      state.ReportMaterialStore.searchRadio = {
        all: true,
        include: false,
        notInclude: false,
      };
      state.ReportMaterialStore.searchStartDate = null;
      state.ReportMaterialStore.searchEndDate = null;
      state.ReportProcessStore.process_reports = [];
      state.ReportProcessStore.startDate = null;
      state.ReportProcessStore.endDate = null;
      state.ReportProcessStore.radio = {
        all: true,
        include: false,
        notInclude: false,
      };
      state.ReportProcessStore.filterResourceTypeArray = null;
      state.ReportProcessStore.filterProcessArray = [];
      state.ReportProcessStore.searchFilterResourceType = [];
      state.ReportProcessStore.searchFilterProcess = [];
      state.ReportProcessStore.searchRadio = {
        all: true,
        include: false,
        notInclude: false,
      };
      state.ReportProcessStore.searchStartDate = null;
      state.ReportProcessStore.searchEndDate = null;
      state.ReportProductStore.product_reports = [];
      state.ReportProductStore.startDate = null;
      state.ReportProductStore.endDate = null;
      state.ReportProductStore.radio = {
        all: true,
        include: false,
        notInclude: false,
      };
      state.ReportProductStore.filterResourceTypeArray = null;
      state.ReportProductStore.filterProductArray = [];
      state.ReportProductStore.searchFilterResourceType = [];
      state.ReportProductStore.searchFilterProduct = [];
      state.ReportProductStore.searchRadio = {
        all: true,
        include: false,
        notInclude: false,
      };
      state.ReportProductStore.searchStartDate = null;
      state.ReportProductStore.searchEndDate = null;
    },
    InitSystemCompanyPage(state) {
      state.SystemCompanyPage.managementMode = false;
      state.SystemCompanyStore.systemCompany = {
        account_number: undefined,
        address: undefined,
        business_item: undefined,
        business_type: undefined,
        detail: undefined,
        detail_address: undefined,
        email: undefined,
        fax_number: undefined,
        id: undefined,
        name: undefined,
        phone_number: undefined,
        port: undefined,
        post_number: undefined,
        representative: undefined,
        representative_item: undefined,
        saup_no: undefined,
        server_url: undefined,
      };
      state.SystemCompanyStore.editSystemCompany =
        state.SystemCompanyStore.systemCompany;
    },
    InitUserPage(state) {
      state.UserPageStore.openTabIndex = 0;
      state.UserPageStore.managementMode = false;
      state.UserStore.users = [];
      state.UserStore.selectedIndex = -1;
      state.UserStore.management_data = {
        name: '',
        account: '',
        email: '',
        create_time: '',
        login_time: '',
        user_role_type_id: -1,
        user_state_type_id: -1,
        password: '',
        password_confirm: '',
      };
      state.UserStore.new_data = {
        name: '',
        account: '',
        email: '',
        create_time: '',
        login_time: '',
        user_role_type_id: -1,
        user_state_type_id: -1,
        password: '',
        password_confirm: '',
      };
      state.UserStore.userRoleType = [];
      state.UserReportStore.user_report = [];
    },
    InitAuthorityPage(state) {
      state.AuthorityStore.filterCheck = [];
      state.AuthorityStore.editCheck = '';
      state.AuthorityStore.searchText = '';
      state.AuthorityStore.targetUser = null;
    },
    InitCalendarPage(state) {
      state.CalendarStore.targetUser = null;
      state.CalendarStore.month = null;
      state.CalendarStore.year = null;
      state.CalendarStore.calendarOptions = [];
      state.CalendarStore.manageCalendarOptions = [];
      state.CalendarStore.calendarMonthList = [];
      state.CalendarStore.calendarModalData = {
        title: '',
        detail: '',
        system_event_type_id: 1,
      };
    },
    InitNoticePage(state) {
      state.NoticeStore.notices = [];
      state.NoticeStore.not_expired_notices = [];
      state.NoticeStore.selectedIndex = -1;
      state.NoticeStore.management_data = {
        id: -1,
        expired_day: yyyymmdd(new Date()),
        title: '',
        detail: '',
      };
      state.NoticeStore.new_data = {
        expired_day: yyyymmdd(new Date()),
        title: '',
        detail: '',
      };
      state.NoticePageStore.openTabIndex = 0;
      state.NoticePageStore.managementMode = false;
    },
    InitUserActionPage(state) {
      state.UserActionPageStore.openTabIndex = 0;
      state.UserActionStore.user_actions = [];
      state.UserActionPageStore.start_date = undefined;
      state.UserActionPageStore.end_date = undefined;
    },

    InitPaymentPage(state) {
      state.PaymentPageStore.openTabIndex = 0;
      state.PaymentPageStore.managementMode = false;
      state.PaymentStore.payments = [];
      state.PaymentStore.selectedIndex = -1;
      state.PaymentStore.start_date = null;
      state.PaymentStore.end_date = null;
      state.PaymentStore.search_type = null;
      state.PaymentStore.managementData = {
        id: -1,
        company_id: -1,
        payment_type_id: null,
        total_value: 0,
        input_date: '',
      };
      state.PaymentStore.cloneData = {
        id: -1,
        company_id: -1,
        payment_type_id: null,
        total_value: 0,
        input_date: '',
      };
      state.PaymentStore.newData = {
        company_id: -1,
        payment_type_id: null,
        total_value: 0,
        input_date: '',
      };
    },
    InitCollectionPage(state) {
      state.CollectionPageStore.openTabIndex = 0;
      state.CollectionPageStore.managementMode = false;
      state.CollectionStore.start_date = null;
      state.CollectionStore.end_date = null;
      state.CollectionStore.search_type = null;
      state.CollectionStore.collections = [];
      state.CollectionStore.selectedIndex = -1;
      state.CollectionStore.managementData = {
        id: -1,
        company_id: -1,
        collection_type_id: null,
        total_value: 0,
        input_date: '',
        collecting_date: null,
      };
      state.CollectionStore.newData = {
        company_id: -1,
        collection_type_id: null,
        total_value: 0,
        input_date: '',
        collecting_date: null,
      };
      state.CollectionStore.modifyData = {
        id: -1,
        company_id: -1,
        collection_type_id: null,
        total_value: 0,
        input_date: '',
        collecting_date: null,
      };
      state.SalesCollectionStatusStore.collections = [];
      state.SalesCollectionStatusStore.salesAccounts = [];
      state.SalesCollectionStatusStore.start_date = null;
      state.SalesCollectionStatusStore.end_date = null;
      state.SalesCollectionStatusStore.end_date_temp = '';
    },
    InitPlcMonitorPage(state) {
      state.PlcMonitorPageStore.openTabIndex = 0;

      state.PlcSensorLogStore.plc_sensor_logs = [];
      state.PlcSensorLogStore.plc_sensor_logs_time = [];
      state.PlcSensorLogStore.select_date = null;
      state.PlcSensorLogStore.start_time = null;
      state.PlcSensorLogStore.end_time = null;
      state.PlcSensorLogStore.search_location = null;
      state.PlcSensorLogStore.search_sensor_type = null;
      state.PlcSensorLogStore.search_sensor_detail_type = null;
      state.PlcSensorLogStore.page_index = 0;
      state.PlcSensorLogStore.scroll_date = null;
      state.PlcSensorLogStore.scroll_start_time = null;
      state.PlcSensorLogStore.scroll_end_time = null;
      state.PlcSensorLogStore.scroll_location = null;
      state.PlcSensorLogStore.scroll_sensor_type = null;
      state.PlcSensorLogStore.scroll_sensor_detail_type = null;

      state.PlcSensorStore.plc_sensor_list = [];
      state.PlcSensorStore.plc_sensor_current_logs = [];

      state.PlcCounterLogStore.counter_logs = [];
      state.PlcCounterLogStore.counter_logs_day = [];
      state.PlcCounterLogStore.counter_logs_day_now = [];
      state.PlcCounterLogStore.select_date = null;
    },
    InitStatusMonitorPage(state) {
      state.StatusMoniotrPageStore.openTabIndex = 0;
    },
    InitHaccpPage(state) {
      state.HaccpPageStore.openTabIndex = 0;
    },
    InitLotTankStore(state) {
      state.LotTankStore.lot_tank = [];
      state.LotTankStore.mangement_lot_tank = {
        tank1: false,
        tank2: false,
        tank3: false,
        tank4: false,
      };
      state.LotTankStore.new_lot_tank = {
        tank1: false,
        tank2: false,
        tank3: false,
        tank4: false,
      };
    },
    InitPatchNotePageStore(state) {
      state.PatchNotePageStore.openTabIndex = 0;
      state.PatchNotePageStore.managementMode = false;
      state.PatchNoteStore.patchNoteList = [];
      state.PatchNoteStore.patchNoteOnlyList = [];
      state.PatchNoteStore.newPatchNote = null;
      state.PatchNoteStore.selectIndex = -1;
      state.PatchNoteStore.managementData = {
        id: -1,
        create_time: yyyymmdd(new Date()),
        title: '',
        content: '',
        system_company_id: null,
        flag: false,
      };
      state.PatchNoteStore.newData = {
        create_time: yyyymmdd(new Date()),
        title: '',
        content: '',
        system_company_id: null,
        flag: false,
      };
      state.PatchNoteStore.systemCompanyList = [];
    },
    InitExchangePageStore(state) {
      state.ExchangeManagementPageStore.openTabIndex = 0;
      state.ExchangeManagementPageStore.managementMode = false;
      state.QuoteStore.quotation = [];
      state.QuoteStore.selectedIndex = -1;
      state.QuoteStore.search_type = null;
      state.QuoteStore.managementData = null;
      state.QuoteStore.start_date = null;
      state.QuoteStore.end_date = null;
    },
    InitBillPageStore(state) {
      state.BillManagementPageStore.openTabIndex = 0;
      state.BillManagementPageStore.managementMode = false;
      state.BillStore.bills = [];
      state.BillStore.transfer_list = [];
      state.BillStore.manage_selected_index = -1;
      state.BillStore.transfer_selected_index = -1;
      state.BillStore.management_data = null;
      state.BillStore.transfer_data = null;
      state.BillStore.management_start_date = null;
      state.BillStore.management_end_date = null;
      state.BillStore.transfer_start_date = null;
      state.BillStore.transfer_end_date = null;
      state.BillStore.new_data = {
        write_date: formatDateNoHours(new Date()), //작성일자
        company_id: null, //거래처ID
        user_id: null, // 담당자
        tax_type_id: 1, //1 과세, 2 영세, 3 면세
        tax_calc_type: 2, // 세율계산 1 절상, 2 절사, 3 반올림
        purpose_type: 1, // 1 영수, 2 청구
        supply_value: 0,
        tax_value: 0,
        total_value: 0,
        comment1: '',
        comment2: '',
        comment3: '',
        auto_write_yn: 2, // 자동완성 여부 (이관됐는지) 1 자동, 2 수동
        commit_yn: 0, //계산서 신고여부 0, 1
        tax_account_products_list: [],
        tax_account_collection: [],
        comment_length: 1,
        write_type: 0,
        collection_cash: 0,
        collection_check: 0,
        collection_note: 0,
        collection_credit: 0,
        collection_card: 0,
      };
    },
    InitKPIPage(state) {
      state.KPIMonitorStore.openTabIndex = 0;
      state.KPIMonitorStore.managementMode = false;
      state.KpiTargetStore.kpi_target = [];
      state.KpiTargetStore.selectedIndex = -1;
      state.kpi_kind_options = [];
      state.Q_target_year = null;
      state.D_target_year = null;
      state.P_target_year = null;
      state.select_year = null;
      state.kpi_order_shipment_lead_time = [];
      state.kpi_production_per_hour = [];
      state.KpiTargetStore.managementData = {
        id: -1,
        kpi_year: String(new Date().getFullYear()),
        kpi_no: -1,
        kpi_kind_select_option: -1,
        kpi_kind: '',
        kpi_name: '',
        unit_cd: '',
        product_id: null,
        crt_val: 0,
        tgt_val: 0,
        wgt_val: 0,
        remark: '',
      };
    },
    InitInputManagementPage(state) {
      state.InputManagementPageStore.openTabIndex = 0;
      state.InputManagementPageStore.selectedIndex = -1;
      //원자재 창고 공통
      state.MaterialStorageInputStore.materialManagementData = {};
      state.MaterialStorageInputStore.input_date = null;
      state.MaterialStorageInputStore.start_date = null;
      state.MaterialStorageInputStore.end_date = null;
      // 미입고창고
      state.MaterialStorageInputStore.materialSelectedIndex = -1;
      // 입고창고
      state.MaterialStorageInputStore.purchaseSelectedIndex = -1;
      // 생산제품입고
      state.ProductStorageInputStore.selectedIndex = -1;
      state.ProductStorageInputStore.managementData = null;
      state.ProductStorageInputStore.searchText = '';
      state.ProductStorageInputStore.start_date = null;
      state.ProductStorageInputStore.end_date = null;
      // 반품입고
      state.ProductStorageInputStore.status_selectedIndex = -1;
      state.ProductStorageInputStore.status_managementData = null;
      state.ProductStorageInputStore.status_searchText = '';
      state.ProductStorageInputStore.status_companySearchText = '';
      state.ProductStorageInputStore.status_start_date = null;
      state.ProductStorageInputStore.status_end_date = null;
    },

    InitMachineManagementPage(state) {
      state.MachineManagementPageStore.managementMode = false;
    },
    InitMachineCheckManagementPage(state) {
      state.MachineCheckPageStore.openTabIndex = 0;
      state.MachineCheckPageStore.managementMode = false;
    },
    InitSpareManagementPage(state) {
      state.SpareManagementPageStore.managementMode = false;
      state.SpareStore.spare_parts = [];
      state.SpareStore.r_part_machines = [];
      state.SpareStore.str_search_machine = '';
      state.SpareStore.str_search_spare_part = '';
      state.SpareStore.chk_only_not_zero_quantity = true;
      state.SpareStore.management_data = {
        // 조회 & 수정
        id: null, //Number
        name: '', //String
        standard: '', //String
        unit_id: null, //Number,
        store_id: null, //Number
        store_location_id: null, //Number
        r_part_machine_list: [], // { machine_id : number }[]
        quantity: 0, //Number,
      };
      state.SpareStore.new_data = {
        // 등록
        name: '', //String
        standard: '', //String
        unit_id: null, //Number,
        store_id: null, //Number
        store_location_id: null, //Number
        r_part_machine_list: [], // { machine_id : number }[]
        quantity: 0, //Number,
      };
    },
    InitLifetimManagementPage(state) {
      state.LifetimeManagementPageStore.openTabIndex = 0;
      state.LifetimeManagementPageStore.managementMode = false;
      state.OperationInfo.selectedIndex = -1;
      state.PartsReplaeManagement.selectedIndex = -1;
    },
  },
});
