import { companyInstance } from '@/api/index.js';
import clonedeep from 'lodash/cloneDeep';
const state = {
  company_types: [],
};
const getters = {
  getCompanyType(state) {
    return state.company_types.sort((a, b) => a.id - b.id);
  },
  getSalesTypeId(state) {
    return state.company_types.find(x => x.detail.includes('sales')).id;
  },
  getBuyTypeId(state) {
    return state.company_types.find(x => x.detail == 'buy').id;
  },
  getCompanyTypeForSelectricAll(state) {
    let options = [];
    options.push({ label: '전체', value: null, detail: null });
    const types = clonedeep(state.company_types).sort((a, b) => a.id - b.id);

    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
};
const mutations = {
  setCompanyType(state, payload) {
    state.company_types = payload;
  },
};

const actions = {
  FETCH_COMPANY_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('company-type')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setCompanyType', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
