import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  material_reports: [],
  startDate: null,
  endDate: null,
  radio: {
    all: true,
    include: false,
    notInclude: false,
  },
  checkOnlyFail: true,
  filterResourceTypeArray: null,
  filterMaterialArray: [],

  searchFilterResourceType: [],
  searchFilterMaterial: [],
  searchRadio: {
    all: true,
    include: false,
    notInclude: false,
  },
  searchStartDate: null,
  searchEndDate: null,
};

const getters = {
  getMaterialReportFromReportMaterial(state) {
    return state.material_reports;
  },
  getCheckOnlyFailFromReportMaterial(state) {
    return state.checkOnlyFail;
  },
  getStartDateFromReportMaterial(state) {
    return state.startDate;
  },
  getEndDateFromReportMaterial(state) {
    return state.endDate;
  },
  getDateFromReportMaterial(state) {
    return {
      start: formatDateNoHours(state.startDate),
      end: formatDateNoHours(state.endDate),
    };
  },
  getRadioFromReportMaterial(state) {
    return state.radio;
  },
  getFilterResourceTypeArrayFromReportMaterial(state) {
    return state.filterResourceTypeArray;
  },
  getFilterMaterialArrayFromReportMaterial(state) {
    return state.filterMaterialArray;
  },
  getSearchFilterResourceTypeFromReportMaterial(state) {
    return state.searchFilterResourceType;
  },
  getSearchFilterMaterialFromReportMaterial(state) {
    return state.searchFilterMaterial;
  },
  getSearchRadioFromReportMaterial(state) {
    return state.searchRadio;
  },
  getSearchStartDateFromReportMaterial(state) {
    return state.searchStartDate;
  },
  getSearchEndDateFromReportMaterial(state) {
    return state.searchEndDate;
  },
};
const mutations = {
  setMaterialReportToReportMaterial(state, payload) {
    state.material_reports = payload;
  },
  setCheckOnlyFailToReportMaterial(state, payload) {
    state.checkOnlyFail = payload;
  },
  setStartDateToReportMaterial(state, payload) {
    state.startDate = payload;
  },
  setEndDateToReportMaterial(state, payload) {
    state.endDate = payload;
  },
  setRadioToReportMaterial(state, payload) {
    state.radio = payload;
  },
  setFilterResourceTypeArrayToReportMaterial(state, payload) {
    state.filterResourceTypeArray = payload;
  },
  setFilterMaterialArrayToReportMaterial(state, payload) {
    state.filterMaterialArray = payload;
  },
  setSearchFilterResourceTypeToReportMaterial(state, payload) {
    state.searchFilterResourceType = payload;
  },
  setSearchFilterMaterialToReportMaterial(state, payload) {
    state.searchFilterMaterial = payload;
  },
  setSearchRadioToReportMaterial(state, payload) {
    state.searchRadio = payload;
  },
  setSearchStartDateToReportMaterial(state, payload) {
    state.searchStartDate = payload;
  },
  setSearchEndDateToReportMaterial(state, payload) {
    state.searchEndDate = payload;
  },
};
const actions = {
  FETCH_MATERIAL_QUALITY_REPORT_TO_REPORT_MATERIAL({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/material/quality-report/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setMaterialReportToReportMaterial',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
