export default [
  {
    path: '/',
    component: () => import('@/layouts/top-main/TopMain.vue'),
    redirect: '/main',
    children: [
      {
        path: '/main',
        name: 'main',
        component: () => import('@/views/pages/Main.vue'),
      },
      {
        path: '/code/common',
        name: '공통자료관리',
        component: () => import('@/views/pages/Code/CodePage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/code/company',
        name: '거래처 등록 및 관리',
        component: () => import('@/views/pages/Code/CompanyPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/code/material',
        name: '원자재 등록 및 관리',
        component: () => import('@/views/pages/Code/MaterialPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/code/product',
        name: '제품 등록 및 관리',
        component: () => import('@/views/pages/Code/ProductPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/code/process',
        name: '공정 등록 및 관리',
        component: () => import('@/views/pages/Code/ProcessPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/code/process-multi-select',
        name: '공정 다중 등록 및 관리',
        component: () => import('@/views/pages/Code/ProcessMultiPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/code/bom',
        name: 'BOM 등록 및 관리',
        component: () => import('@/views/pages/Code/BomPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/storage/input-management',
        name: '입고 관리',
        component: () =>
          import('@/views/pages/Storage/InputManagementPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/storage/output-management',
        name: '출고 관리',
        component: () =>
          import('@/views/pages/Storage/OutputManagementPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/storage/status-management',
        name: '현황 관리',
        component: () =>
          import('@/views/pages/Storage/StatusManagementPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/business/exchange-management',
        name: '거래 관리',
        component: () =>
          import('@/views/pages/Business/ExchangeManagementPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/business/bill-management',
        name: '계산서 관리',
        component: () =>
          import('@/views/pages/Business/BillManagementPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/business/sales-analysis',
        name: '매출 분석',
        component: () => import('@/views/pages/Business/SalesAnalysisPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/plan/sales',
        name: '제품 수주관리',
        component: () => import('@/views/pages/Plan/SalesPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/plan/cal',
        name: '원자재 소요량 산출',
        component: () => import('@/views/pages/Plan/CalculationPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/plan/order',
        name: '원자재 발주관리',
        component: () => import('@/views/pages/Plan/PurchasePage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/plan/plan',
        name: '제품 생산 계획 관리',
        component: () =>
          import(
            `@/views/pages/Plan/${localStorage.getItem(
              'saupKey',
            )}/CustomPlanManagementPage.vue`
          ),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/plan/target-quantity',
        name: '목표 설정',
        component: () =>
          import(
            `@/views/pages/Plan/${localStorage.getItem(
              'saupKey',
            )}/TargetQuantityPage.vue`
          ),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/plan/order-status',
        name: '발주 현황조회',
        component: () =>
          import(
            `@/views/pages/Plan/${localStorage.getItem(
              'saupKey',
            )}/CustomPlanOrderStatusPage.vue`
          ),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/plan/production-availability',
        name: '제품생산 가능여부',
        component: () =>
          import(
            `@/views/pages/Plan/${localStorage.getItem(
              'saupKey',
            )}/CustomPlanManagementPage2.vue`
          ),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/material/input',
        name: '입고등록',
        component: () => import('@/views/pages/Material/InputPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/material/print',
        name: '입고식별표 출력',
        component: () => import('@/views/pages/Material/InputPrintPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/material/status',
        name: '원자재 현황 조회',
        component: () =>
          import('@/views/pages/Material/MaterialStatusPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/material/return',
        name: '원자재 반품',
        component: () =>
          import('@/views/pages/Material/MaterialReturnPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/material/ledger',
        name: '원자재&구매처 원장',
        component: () => import('@/views/pages/Material/LedgerPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/material/payment',
        name: '구매처 지급관리',
        component: () => import('@/views/pages/Material/PaymentPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/process/workorder',
        name: '작업지시서 관리',
        component: () => import('@/views/pages/Process/WorkOrderPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/custom/process/workorder',
        name: '커스텀 작업지시서 관리',
        component: () =>
          import(
            `@/views/pages/Custom/Process/${localStorage.getItem(
              'saupKey',
            )}/CustomWorkOrderPage.vue`
          ),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/process/production',
        name: '생산공정 진행',
        component: () => import('@/views/pages/Process/ProductionPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/process/print',
        name: '생산완료&식별표출력',
        component: () => import('@/views/pages/Process/PrintCompletePage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/process/status',
        name: '공정 진행현황',
        component: () => import('@/views/pages/Process/ProcessStatusPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/product/output',
        name: '제품출고&매출등록',
        component: () => import('@/views/pages/Product/OutputPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/product/status',
        name: '제품재고&입출고현황',
        component: () => import('@/views/pages/Product/ProductStatusPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/product/return',
        name: '반품제품 등록',
        component: () => import('@/views/pages/Product/ProductReturnPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/product/sales',
        name: '제품&매출처원장',
        component: () => import('@/views/pages/Product/ProductLedgerPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/product/tracking',
        name: '제품 LOT추적',
        component: () => import('@/views/pages/Product/LotTrackingPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/product/collection',
        name: '매출처 수금관리',
        component: () => import('@/views/pages/Product/CollectionPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/quality/management',
        name: '품질 검사기준관리',
        component: () =>
          import('@/views/pages/Quality/QualityManagementPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/quality/material',
        name: '원자재 수입검사',
        component: () =>
          import('@/views/pages/Quality/MaterialVerificationPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/quality/product',
        name: '제품검사',
        component: () =>
          import('@/views/pages/Quality/ProductVerificationPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/quality/process',
        name: '공정검사',
        component: () =>
          import('@/views/pages/Quality/SeqVerificationPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/quality/print',
        name: '검사성적서 출력',
        component: () => import('@/views/pages/Quality/QualityPrintPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/quality/report',
        name: '품질분석 리포트',
        component: () => import('@/views/pages/Quality/QualityReportPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/custom/product_verification',
        name: '품질관리대장',
        component: () =>
          import(
            `@/views/pages/Quality/${localStorage.getItem(
              'saupKey',
            )}/CustomProductVerificationPage.vue`
          ),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/machine/management',
        name: '설비기기관리',
        component: () =>
          import('@/views/pages/Machine/MachineManagementPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/machine/check',
        name: '점검기준 관리',
        component: () => import('@/views/pages/Machine/MachineCheckPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/machine/spare-management',
        name: '스페어 관리',
        component: () =>
          import('@/views/pages/Machine/SpareManagementPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/machine/lifetime-management',
        name: '수명 관리',
        component: () =>
          import('@/views/pages/Machine/LifetimeManagementPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/system/systemcompany',
        name: '회사 정보 관리',
        component: () => import('@/views/pages/System/SystemCompanyPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/system/user',
        name: '사용자관리',
        component: () => import('@/views/pages/System/UserPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/system/useraction',
        name: '사용현황 리포트',
        component: () => import('@/views/pages/System/UserActionPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/system/auth',
        name: '권한 관리',
        component: () => import('@/views/pages/System/AuthorityPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/system/notice',
        name: '공지사항 관리',
        component: () => import('@/views/pages/System/NoticePage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/system/calendar',
        name: '주요 일정 관리',
        component: () => import('@/views/pages/System/CalendarPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/monitor/status-board',
        name: '현황판 모니터링',
        component: () =>
          import(
            `@/views/pages/Custom/Monitor/${localStorage.getItem(
              'saupKey',
            )}/StatusMonitorPage.vue`
          ),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/sales/output',
        name: '매출관리',
        component: () => import('@/views/pages/Sales/Custom/OutputPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/sales/collection',
        name: '수금관리',
        component: () =>
          import('@/views/pages/Sales/Custom/CollectionPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/sales/status',
        name: '현황조회',
        component: () =>
          import('@/views/pages/Sales/Custom/SalesStatusPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/monitor/kpi',
        name: 'KPI 완제품 불량률',
        component: () =>
          import(
            `@/views/pages/Custom/Monitor/${localStorage.getItem(
              'saupKey',
            )}/KPIDefectiveRatePage.vue`
          ),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/haccp/storage',
        name: '저장고-온도(CCP)',
        component: () =>
          import(
            `@/views/pages/Custom/Haccp/${localStorage.getItem(
              'saupKey',
            )}/HaccpStoragePage.vue`
          ),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/haccp/workplace',
        name: '작업실-온도(CCP)',
        component: () =>
          import(
            `@/views/pages/Custom/Haccp/${localStorage.getItem(
              'saupKey',
            )}/HaccpWorkPlacePage.vue`
          ),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/monitor/plc',
        name: 'PLC 모니터링',
        component: () =>
          import(
            `@/views/pages/Custom/Monitor/${localStorage.getItem(
              'saupKey',
            )}/PLCMonitorPage.vue`
          ),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/system/setting',
        name: '설정',
        component: () => import('@/views/pages/System/SettingPage.vue'),
        meta: {
          menu_init: true,
        },
      },
      {
        path: '/admin/patch-note',
        name: '패치노트관리',
        component: () => {
          if (localStorage.getItem('saupKey') == 4)
            return import('@/views/pages/Admin/PatchNotePage.vue');
        },
        meta: {
          menu_init: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/full-page/FullPage.vue'),
    children: [
      {
        path: '/login/:id',
        name: 'login_id',
        component: () => import('@/views/pages/Login.vue'),
        meta: {
          noAuth: true,
        },
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/Login.vue'),
        meta: {
          noAuth: true,
        },
      },
    ],
  },
  { path: '*', component: () => import('@/views/pages/NotFound.vue') },
];
