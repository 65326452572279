import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  bills: [],
  transfer_list: [],
  manage_selected_index: -1,
  transfer_selected_index: -1,
  management_data: null,
  transfer_data: null,
  management_start_date: null,
  management_end_date: null,
  transfer_start_date: null,
  transfer_end_date: null,
  new_data: {
    write_date: formatDateNoHours(new Date()), //작성일자
    company_id: null, //거래처ID
    user_id: null, // 담당자
    tax_type_id: 1, //1 과세, 2 영세, 3 면세
    tax_calc_type: 2, // 세율계산 1 절상, 2 절사, 3 반올림
    purpose_type: 1, // 1 영수, 2 청구
    supply_value: 0,
    tax_value: 0,
    total_value: 0,
    additional_tax_yn: 0,
    comment1: '',
    comment2: '',
    comment3: '',
    auto_write_yn: 2, // 자동완성 여부 (이관됐는지) 1 자동, 2 수동
    commit_yn: 0, //계산서 신고여부 0, 1
    tax_account_products_list: [],
    tax_account_collection: [],
    comment_length: 1,
    write_type: 0, // 작성방법 : 0 직접입력, 1 수량단가, 2 공급가액, 3 합계금액
    collection_cash: 0,
    collection_check: 0,
    collection_note: 0,
    collection_credit: 0,
    collection_card: 0,
  },
};

const getters = {
  getBillsFromBillManagementPage(state) {
    return state.bills;
  },
  getTransferListFromBillManagementPage(state) {
    return state.transfer_list;
  },
  getManageSelectedIndexFromBillManagementPage(state) {
    return state.manage_selected_index;
  },
  getTransferSelectedIndexFromBillManagementPage(state) {
    return state.transfer_selected_index;
  },
  getManagementDataFromBillManagementPage(state) {
    return state.management_data;
  },
  getTransferDataFromBillManagementPage(state) {
    return state.transfer_data;
  },
  getManagementStartDateFromBillManagementPage(state) {
    return state.management_start_date;
  },
  getManagementEndDateFromBillManagementPage(state) {
    return state.management_end_date;
  },
  getTransferStartDateFromBillManagementPage(state) {
    return state.transfer_start_date;
  },
  getTransferEndDateFromBillManagementPage(state) {
    return state.transfer_end_date;
  },
  getNewDataFromBillManagementPage(state) {
    return state.new_data;
  },
  getTaxAccountProductsListFromNewData(state) {
    return state.new_data.tax_account_products_list;
  },
};
const mutations = {
  setBillsToBillManagementPage(state, payload) {
    state.bills = payload;
  },
  setTransferListToBillManagementPage(state, payload) {
    const unreported_sales_product = payload.unreported_sales_product.reduce(
      function(rv, x) {
        (rv[x['company_id']] = rv[x['company_id']] || []).push(x);
        return rv;
      },
      {},
    );

    let arr = [];
    for (let k in unreported_sales_product) {
      const group_by_tax_type_id = unreported_sales_product[k].reduce(function(
        rv,
        x,
      ) {
        (rv[x['tax_type_id']] = rv[x['tax_type_id']] || []).push(x);
        return rv;
      },
      {});
      for (let j in group_by_tax_type_id) {
        let sum_total_value = group_by_tax_type_id[j]
          .map(x => Number(x.total_value))
          .reduce((a, b) => a + b, 0);
        console.log('group_by_tax_type_id', group_by_tax_type_id);
        console.log('group_by_tax_type_id[j]', group_by_tax_type_id[j]);
        console.log(' sum_total_value', sum_total_value);
        console.log(' j', j);
        const calPrice =
          Number(j) == 1
            ? {
                supply: Math.ceil(sum_total_value / 1.1),
                tax: sum_total_value - Math.ceil(sum_total_value / 1.1),
                total: sum_total_value,
              }
            : { supply: sum_total_value, tax: 0, total: sum_total_value };
        arr.push({
          id: null,
          write_date: null,
          company_id: Number(k),
          tax_type_id: Number(j),
          tax_calc_type: 1,
          purpose_type: 1,
          supply_value: calPrice.supply,
          tax_value: calPrice.tax,
          total_value: calPrice.total,
          comment1: '',
          comment2: '',
          comment3: '',
          auto_write_yn: 1,
          additional_tax_yn: 0,
          commit_yn: 0,
          comment_length: 1,
          write_type: 0,
          tax_account_products_list: group_by_tax_type_id[j].map(x => ({
            // return {
            id: null,
            tax_account_id: null,
            tax_type_id: j,
            output_date: x.output_date,
            product_id: x.product_id,
            product_name: x.product_name,
            product_standard: x.product_standard,
            sales_product_id: x.id,
            quantity: x.quantity,
            cost: x.cost,
            supply_value: x.supply_value,
            tax_value: x.tax,
            comment: '',
            // };
          })),
          tax_account_collection: payload.unreported_collection
            .filter(x => x.company_id == Number(k))
            .map(x => ({
              // return {
              id: null,
              tax_account_id: null,
              collection_id: x.id,
              collection_value: x.total_value,
              collection_type_id: x.collection_type_id,
              // };
            })),
        });
      }
    }
    delete payload.unreported_collection;
    state.transfer_list = payload.reported_list.concat(arr);
  },
  setManageSelectedIndexToBillManagementPage(state, payload) {
    state.manage_selected_index = payload;
  },
  setTransferSelectedIndexToBillManagementPage(state, payload) {
    state.transfer_selected_index = payload;
  },
  setManagementDataToBillManagementPage(state, payload) {
    state.management_data = payload;
  },
  setTransferDataToBillManagementPage(state, payload) {
    state.transfer_data = payload;
  },
  setManagementStartDateToBillManagementPage(state, payload) {
    state.management_start_date = payload;
  },
  setManagementEndDateToBillManagementPage(state, payload) {
    state.management_end_date = payload;
  },
  setTransferStartDateToBillManagementPage(state, payload) {
    state.transfer_start_date = payload;
  },
  setTransferEndDateToBillManagementPage(state, payload) {
    state.transfer_end_date = payload;
  },
  setNewDataToBillManagementPage(state, payload) {
    state.new_data = payload;
  },
};

const actions = {
  FETCH_BILL_TRANSFER({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`tax-account/transfer/start=${payload.start}&end=${payload.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setTransferListToBillManagementPage',
              response.data.data_list,
            );
            resolve(response);
          }
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_BILL_LIST({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`tax-account/start=${payload.start}&end=${payload.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setManagementDataToBillManagementPage',
              response.data.data_list,
            );
            resolve(response);
          }
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_BILL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('tax-account', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_BILL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('tax-account', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('응답', response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_BILL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`tax-account/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
