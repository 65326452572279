import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  counter_logs: [],
  counter_logs_day: [],
  counter_logs_product: [],

  select_date: null,
  start_date: null,
  end_date: null,
};
const getters = {
  getCounterLog(state) {
    return state.counter_logs;
  },
  getCounterLogDay(state) {
    return state.counter_logs_day;
  },
  getCounterLogDayProduct(state) {
    return state.counter_logs_product;
  },

  getSelectDateFromPlcCounterLog(state) {
    return state.select_date;
  },
  getStartDateFromCounterLog(state) {
    return state.start_date;
  },
  getEndDateFromCounterLog(state) {
    return state.end_date;
  },
};
const mutations = {
  setCounterLog(state, payload) {
    state.counter_logs = payload;
  },
  setCounterLogDay(state, payload) {
    state.counter_logs_day = payload;
  },
  setCounterLogDayProduct(state, payload) {
    state.counter_logs_product = payload;
  },

  setSelectDateToPlcCounterLog(state, payload) {
    state.select_date = payload;
  },
  setStartDateToCounterLog(state, payload) {
    state.start_date = payload;
  },
  setEndDateToCounterLog(state, payload) {
    state.end_date = payload;
  },
};
const actions = {
  async FETCH_COUNTER_LOG_NOW({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('plc/counter-log/now')
        .then(Response => {
          if (Response.data.result == 'success') {
            commit('setCounterLog', Response.data.data_list);
            resolve(Response.data.data_list);
          }
          reject(Response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async FETCH_COUNTER_LOG_NOW_LIST({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('plc/counter-log/now/list')
        .then(Response => {
          if (Response.data.result == 'success') {
            commit('setCounterLog', Response.data.data_list);
            resolve(Response.data.data_list);
          }
          reject(Response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_COUNTER_LOG_DAY({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(`plc/counter-log/day/start=${newDate.start}&end=${newDate.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setCounterLogDay', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_COUNTER_LOG_DAY2({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(`plc/counter-log/day/start=${newDate.start}&end=${newDate.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setCounterLogDay', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  // FETCH_COUNTER_LOG_DAY3({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     let startDate = new Date();
  //     startDate.setDate(startDate.getDate() - 1);
  //     let endDate = new Date(startDate);
  //     endDate.setDate(endDate.getDate());
  //     const newDate = {
  //       start: formatDateNoHours(startDate),
  //       end: formatDateNoHours(endDate),
  //     };
  //     companyInstance
  //       .get(
  //         `plc/counter-log/day/start_day=${newDate.start}&end_day=${newDate.end}`,
  //       )
  //       .then(response => {
  //         if (response.data.result == 'success') {
  //           commit('setCounterLogDay', response.data.data_list);
  //           console.log(response);
  //           resolve(response);
  //         }
  //         reject(response);
  //       })
  //       .catch(error => {
  //         console.log(error);
  //         reject(error);
  //       });
  //   });
  // },
  FETCH_COUNTER_LOG_DAY_PRODUCT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      endDate = formatDateNoHours(endDate);
      companyInstance
        .get(
          `plc/counter-log/day/start_day=${payload.start}&end_day=${endDate}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setCounterLogDayProduct', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_COUNTER_LOG_DAY_NOW({ commit }) {
    return new Promise((resolve, reject) => {
      let startDate = new Date();
      let endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 1);

      const newDate = {
        start: formatDateNoHours(startDate),
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `plc/counter-log/day/start_day=${newDate.start}&end_day=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setCounterLogDay', response.data.data_list);
            console.log(response);
            console.log('response.data.data_list', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async INSERT_COUNTER_LOG(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('plc/counter-log', payload)
        .then(Response => {
          if (Response.data.result == 'success') {
            resolve(Response.data.data_list);
          }
          reject(Response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async UPDATE_COUNTER_LOG(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plc/counter-log', payload)
        .then(Response => {
          if (Response.data.result == 'success') {
            resolve(Response.data.data_list);
          }
          reject(Response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
