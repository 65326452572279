import { companyInstance } from '@/api/index.js';
import clonedeep from 'lodash/cloneDeep';

const state = {
  stores: [],
  selectedIndex: {
    depth: 1,
    index: -1,
  },
  managementData: {
    id: -1,
    name: '',
    detail: '',
  },
};
const getters = {
  getStores(state) {
    state.stores.forEach(el => {
      el.locations.sort((x, y) => x.id - y.id);
    });
    return state.stores.sort((a, b) => a.id - b.id);
  },
  getStoresForSelectric(state) {
    let options = [];
    options.push({ label: '선택', value: null, detail: '' });
    const types = clonedeep(state.stores).sort((a, b) => a.id - b.id);
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getStoresForSelectricAll(state) {
    let options = [];
    options.push({ label: '전체', value: null, detail: '' });
    const types = clonedeep(state.stores).sort((a, b) => a.id - b.id);
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getSelectedIndexFromStore(state) {
    return state.selectedIndex;
  },
  getManagementDataFromStore(state) {
    return state.managementData;
  },
};
const mutations = {
  setStores(state, payload) {
    state.stores = payload;
  },
  setSelectedIndexToStore(state, payload) {
    state.selectedIndex = payload;
  },
  setManagementDataToStore(state, payload) {
    state.managementData = payload;
  },
};
const actions = {
  FETCH_STORE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/store')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setStores', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async INSERT_STORE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/store', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_STORE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/store', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_STORE_LOCATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/location/store', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_STORE_LOCATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/location/store', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async DELETE_STORE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/store/${payload}`)
        .then(response => {
          console.log('deleteStore', response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async DELETE_STORE_LOCATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/location/store/${payload}`)
        .then(response => {
          console.log('deleteStoreLocation', response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
