import { companyInstance } from '@/api/index.js';

const state = {
  unit_conversions: [],
  selectedIndex: -2,
  managementData: null,
};
const getters = {
  getUnitConversion(state) {
    return state.unit_conversions;
  },
  getSelectedIndexFromUnitConversion(state) {
    return state.selectedIndex;
  },
  getManagementDataFromUnitConversion(state) {
    return state.managementData;
  },
};
const mutations = {
  setUnitConversion(state, payload) {
    state.unit_conversions = payload;
  },
  setSelectedIndexToUnitConversion(state, payload) {
    state.selectedIndex = payload;
  },
  setManagementDataToUnitConversion(state, payload) {
    state.managementData = payload;
  },
  setManagementDataInUnitIdToUnitConversion(state, payload) {
    state.managementData.in_unit_id = payload;
  },
  setManagementDataOutUnitIdToUnitConversion(state, payload) {
    state.managementData.out_unit_id = payload;
  },
};

const actions = {
  FETCH_UNIT_CONVERSION({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/unit-conversion')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setUnitConversion', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_UNIT_CONVERSION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/unit-conversion', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);

            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UPDATE_UNIT_CONVERSION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/unit-conversion', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);

            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DELETE_UNIT_CONVERSION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/unit-conversion/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);

            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
