import { companyInstance } from '@/api';
// import { formatDateNoHours } from '@/utils/filters';

const state = {
  target_lot: null,
  tracking_resource: [],
  all_resource: [],
  tracking_sales: [],
  selectedTab: 0,
  searchText: '',
};
const getters = {
  getTargetLotFromCostCalculation(state) {
    return state.target_lot;
  },
  getSelectedTabFromCostCalculation(state) {
    return state.selectedTab;
  },
  getSearchTextFromCostCalculation(state) {
    return state.searchText;
  },
  getCostCalculationResource(state) {
    return state.tracking_resource;
  },
  getCostCalculationResourceAll(state) {
    return state.all_resource;
  },
  getCostCalculationSales(state) {
    return state.tracking_sales;
  },
};
const mutations = {
  setTargetLotToCostCalculation(state, payload) {
    state.target_lot = payload;
  },
  setSelectedTabToCostCalculation(state, payload) {
    state.selectedTab = payload;
  },
  setSearchTextToCostCalculation(state, payload) {
    state.searchText = payload;
  },
  setCostCalculationResource(state, payload) {
    state.tracking_resource = payload;
  },
  setCostCalculationResourceAll(state, payload) {
    state.all_resource = payload;
  },
  setCostCalculationSales(state, payload) {
    state.tracking_sales = payload;
  },
};
const actions = {
  async FETCH_LOT_PRODUCTION_TO_COST_CALCULATION({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/production/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('bom_lot', response.data.data);
            commit('setTargetLotToCostCalculation', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_COST_CALCULATION_RESOURCE_WITH_COST({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/tracking-resource-with-cost/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('bom_lot', response.data.data);
            commit('setCostCalculationResource', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_COST_CALCULATION_RESOURCE_WITH_COST_ALL({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`/mag/lot/tracking-resource-with-cost`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setCostCalculationResourceAll', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_COST_CALCULATION_SALES({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/tracking-sales/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('bom_lot', response.data.data);
            commit('setCostCalculationSales', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
