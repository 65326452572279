import { companyInstance } from '@/api/index';
// import clonedeep from 'lodash/cloneDeep';
// import { formatDateNoHours } from '@/utils/filters';

const state = {
  start_date: null,
  end_date: null,
  cns_xray_log: [],
  cns_xray_log_now: [],
};
const getters = {
  // getStartDateFromCNSXrayLog(state) {
  //   return state.start_date;
  // },
  // getEndDateFromCNSXrayLog(state) {
  //   return state.end_date;
  // },
  getCNSXrayLog(state) {
    return state.cns_xray_log;
  },
  // getCNSXrayLogNow(state) {
  //   return state.cns_xray_log_now;
  // },
};
const mutations = {
  // setStartDateToCNSXrayLog(state, payload) {
  //   state.start_date = payload;
  // },
  // setEndDateToCNSXrayLog(state, payload) {
  //   state.end_date = payload;
  // },
  setCNSXrayLog(state, payload) {
    state.cns_xray_log = payload;
  },
  // setCNSXrayLogNow(state, payload) {
  //   state.cns_xray_log_now = payload;
  // },
};
const actions = {
  // FETCH_CNS_XRAY_LOG({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     let endDate = new Date(payload.end);
  //     endDate.setDate(endDate.getDate() + 1);
  //     const newDate = {
  //       start: payload.start,
  //       end: formatDateNoHours(endDate),
  //     };
  //     companyInstance
  //       .get(`custom/cns_xray_log/start=${newDate.start}&end=${newDate.end}`)
  //       .then(response => {
  //         if (response.data.result == 'success') {
  //           console.log(response);
  //           commit('setcNSXrayLog', response.data.data_list);
  //           resolve(response);
  //         }
  //         reject(response);
  //       })
  //       .catch(error => {
  //         console.log(error);
  //         reject(error);
  //       });
  //   });
  // },
  FETCH_CNS_XRAY_LOG({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`custom/x-ray`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setCNSXrayLog', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
