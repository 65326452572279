import { companyInstance } from '@/api';
import axios from 'axios';

const state = {
  month: null,
  year: null,
  calendarOptions: [],
  calendarMonthList: [],
  calendarModalData: {
    title: '',
    detail: '',
    system_event_type_id: 1,
  },
  prev_holiday: [],
  curr_holiday: [],
  next_holiday: [],
};

const getters = {
  getCalendarOptionsFromMainCalendar(state) {
    return state.calendarOptions;
  },
  getCalendarMonthFromMainCalendar(state) {
    return state.calendarMonthList;
  },
  getCalendarModalDataFromMainCalendar(state) {
    return state.calendarModalData;
  },
  getMonthFromMainCalendar(state) {
    return state.month;
  },
  getYearFromMainCalendar(state) {
    return state.year;
  },
  getHolidayFromMainCalendar(state) {
    return state.prev_holiday.concat(state.curr_holiday, state.next_holiday);
  },
};

const mutations = {
  setCalendarOptionsToMainCalendar(state, payload) {
    state.calendarOptions = payload;
  },
  setCalendarMonthToMainCalendar(state, payload) {
    state.calendarMonthList = payload;
  },
  setCalendarModalDataToMainCalendar(state, payload) {
    state.calendarModalData = payload;
  },
  setMonthToMainCalendar(state, payload) {
    state.month = payload;
  },
  setYearToMainCalendar(state, payload) {
    state.year = payload;
  },
  setPrevHolidayToMainCalendar(state, payload) {
    state.prev_holiday = payload;
  },
  setCurrHolidayToMainCalendar(state, payload) {
    state.curr_holiday = payload;
  },
  setNextHolidayToMainCalendar(state, payload) {
    state.next_holiday = payload;
  },
};

const actions = {
  async FETCH_HOLIDAY({ commit }, payload) {
    const url =
      'http://localhost:8080/B090041/openapi/service/SpcdeInfoService/getRestDeInfo';
    const queryParams =
      '?' +
      encodeURIComponent('ServiceKey') +
      '=' +
      'stlRQ%2BMdma0HcCCnV2WZSXioW7cBzJed%2BR9FrFtNb6PK8G4PPF13M%2FmOkhSuU8QmVr5zDe%2BT3tY4RxMW6S4uiw%3D%3D' +
      '&' +
      encodeURIComponent('pageNo') +
      '=' +
      encodeURIComponent('1') +
      '&' +
      encodeURIComponent('numOfRows') +
      '=' +
      encodeURIComponent('100') +
      '&' +
      encodeURIComponent('solYear') +
      '=';
    let queryParams2 =
      '&' + encodeURIComponent('_type') + '=' + encodeURIComponent('json');

    return new Promise((resolve, reject) => {
      axios
        .get(url + queryParams + (Number(payload) - 1) + queryParams2)
        .then(response => {
          if (response.data.response.body.totalCount > 0) {
            commit(
              'setPrevHolidayToMainCalendar',
              response.data.response.body.items.item,
            );
            axios
              .get(url + queryParams + payload + queryParams2)
              .then(response => {
                if (response.data.response.body.totalCount > 0) {
                  commit(
                    'setCurrHolidayToMainCalendar',
                    response.data.response.body.items.item,
                  );
                  axios
                    .get(
                      url + queryParams + (Number(payload) + 1) + queryParams2,
                    )
                    .then(response => {
                      if (response.data.response.body.totalCount > 0) {
                        commit(
                          'setNextHolidayToMainCalendar',
                          response.data.response.body.items.item,
                        );
                        resolve(response);
                      } else {
                        reject(response);
                      }
                    })
                    .catch(error => {
                      reject(error);
                    });
                } else {
                  reject(response);
                }
              })
              .catch(error => {
                reject(error);
              });
          } else {
            reject(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_MAIN_CALENDAR_MONTH({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`system/event/v/${payload}`) /* payload = month => 2021-01 */
        .then(response => {
          if (response.data.result == 'success') {
            console.log('RES', response);
            commit('setCalendarMonthToMainCalendar', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_MAIN_CALENDAR({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`user/system/event/type/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('RES', response);
            commit('setCalendarOptionsToMainCalendar', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_MAIN_CALENDAR(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('user/system/event/type', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_MAIN_CALENDAR_MODAL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('system/event', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async DELETE_MAIN_CALENDAR_MODAL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`system/event/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
