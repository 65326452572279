const state = {
  openTabIndex: 0,
  managementMode: false,
};

const getters = {
  getOpenTabIndexFromProductStatusPage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromProductStatusPage(state) {
    return state.managementMode;
  },
};
const mutations = {
  setOpenTabIndexToProductStatusPage(state, idx) {
    state.openTabIndex = idx;
  },
  setManagementModeToProductStatusPage(state, payload) {
    state.managementMode = payload;
  },
};

export default { state, getters, mutations };
