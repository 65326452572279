import { companyInstance } from '@/api/index';
import { makeComma } from '@/utils/filters';
import clonedeep from 'lodash/cloneDeep';

const state = {
  sales: [],
  selectedIndex: -1,
  start_date: null,
  end_date: null,
  company_start_date: null,
  company_end_date: null,
  lot_sales: null,
  check_only_not_completed: true,
  // company_check_only_not_completed: true,
  managementData: {
    id: null,
    company_id: null,
    sales_type_id: null,
    completed: false,
    delivery_date: null,
    input_date: null,
    detail: '',
    sales_products: [],
  },
  cloneData: {
    id: null,
    company_id: null,
    sales_type_id: null,
    completed: false,
    delivery_date: null,
    input_date: null,
    detail: '',
    sales_products: [],
  },
  newData: {
    company_id: null,
    sales_type_id: 1,
    completed: false,
    delivery_date: null,
    input_date: null,
    detail: '',
    sales_products: [
      // {
      //   product_id: null,
      //   unit_id: null,
      //   box_unit_id: null,
      //   standard: '',
      //   quantity: 0,
      //   cost: 0, //단가
      //   supply_value: 0, //공급가
      //   tax: 0, //부가세
      //   total_cost: 0, //합계액
      //   discount_value : 0, //단일 할인액
      // },
    ],
  },
};
const getters = {
  getSales(state) {
    return state.sales.sort((a, b) => b.id - a.id);
  },
  getLotSalesFromSales(state) {
    return state.lot_sales;
  },
  getSalesOnlyNotOrderCompleted(state) {
    return state.sales
      .sort((a, b) => b.id - a.id)
      .filter(
        x =>
          x.product_list.find(y => y.order_yn == false) != undefined &&
          x.completed == false,
      );
  },
  getStartDateFromSales(state) {
    return state.start_date;
  },
  getEndDateFromSales(state) {
    return state.end_date;
  },
  getCompanyStartDateFromSales(state) {
    return state.company_start_date;
  },
  getCompanyEndDateFromSales(state) {
    return state.company_end_date;
  },
  getCheckOnlyNotCompletedFromSales(state) {
    return state.check_only_not_completed;
  },
  getManagementDataFromSales(state) {
    return state.managementData;
  },
  getCloneDataFromSales(state) {
    return state.cloneData;
  },
  getManagementDataProductListFromSales(state) {
    return state.managementData.product_list;
  },
  getManagementDataSalesProductsFromSales(state) {
    return state.managementData.sales_products;
  },
  getNewDataFromSales(state) {
    return state.newData;
  },
  getSelectedIndexFromSales(state) {
    return state.selectedIndex;
  },
};
const mutations = {
  setSales(state, payload) {
    state.sales = payload;
  },
  setLotSalesToSales(state, payload) {
    state.lot_sales = payload;
  },
  setManagementDataToSales(state, payload) {
    payload.product_list = payload.product_list.sort((a, b) => a.id - b.id);
    state.managementData = payload;
    state.managementData.sales_products = clonedeep(payload.product_list);
    state.managementData.sales_products.forEach(element => {
      if (element.discount == null) {
        element.discount = '0';
      } else {
        element.discount = makeComma(element.discount);
      }
      if (element.cost == null) {
        element.cost = '0';
      } else {
        element.cost = makeComma(element.cost);
      }
    });
    state.managementData.product_list.forEach(element => {
      if (element.discount == null) {
        element.discount = '0';
      } else {
        element.discount = makeComma(element.discount);
      }
      if (element.cost == null) {
        element.cost = '0';
      } else {
        element.cost = makeComma(element.cost);
      }
    });
  },
  setCloneDataToSales(state, payload) {
    state.cloneData = payload;
  },
  // deleteManagementDataProductListItem(state, sales_product, index) {
  //   state.managementData.product_list.splice(index, 1);
  // },
  setNewDataToSales(state, payload) {
    state.newData = payload;
  },
  setSelectedIndexToSales(state, index) {
    state.selectedIndex = index;
  },
  setNewBoxUnitIdToSales(state, payload) {
    state.newData.sales_products[payload.index].box_unit_id = payload.value;
  },
  setManagementBoxUnitIdToSales(state, payload) {
    state.managementData.product_list[payload.index].box_unit_id =
      payload.value;
  },
  toggleCheckOnlyNotCompletedToSales(state) {
    state.check_only_not_completed = !state.check_only_not_completed;
  },
  setStartDateToSales(state, payload) {
    state.start_date = payload;
  },
  setEndDateToSales(state, payload) {
    state.end_date = payload;
  },
  setCompanyStartDateToSales(state, payload) {
    state.company_start_date = payload;
  },
  setCompanyEndDateToSales(state, payload) {
    state.company_end_date = payload;
  },
};
const actions = {
  FETCH_SALES({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = `plan/sales/info/start_date=${payload.start_date}&end_date=${payload.end_date}`;
      if (
        payload.check_only_not_completed != undefined &&
        payload.check_only_not_completed == true
      ) {
        url += `&completed=false`;
      }
      companyInstance
        .get(url)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setSales', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_SALES_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('plan/sales/info/all', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_SALES_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/sales/info/all', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_SALES(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`plan/sales/info/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_SALES_PRODUCT_LIST(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/sales/product/list', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_LOT_SALES({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/info/sales/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setLotSalesToSales', response.data.data);
            resolve(response);
          } else {
            commit('setLotSalesToSales', null);
            reject(response);
          }
        })
        .catch(error => {
          commit('setLotSalesToSales', null);
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_SALES_ONE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plan/sales/one/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
