import { companyInstance } from '@/api';
// import { formatDateNoHours } from '@/utils/filters';

const state = {
  target_lot: null,
  tracking_resource: [],
  tracking_sales: [],
  selectedTab: 0,
  selectedLotIndex: -1,
  searchText: '',
  managementLotData: null,
  radio_lot: true,
  radio_barcode: false,
};
const getters = {
  getTargetLotFromLotTracking(state) {
    return state.target_lot;
  },
  getSelectedTabFromLotTracking(state) {
    return state.selectedTab;
  },
  getSelectedIndexFromLotTracking(state) {
    return state.selectedLotIndex;
  },
  getSearchTextFromLotTracking(state) {
    return state.searchText;
  },
  getLotTrackingResource(state) {
    return state.tracking_resource;
  },
  getLotTrackingSales(state) {
    return state.tracking_sales;
  },
  getManagementLotDataFromLotTracking(state) {
    return state.managementLotData;
  },
  getRadioStateFromLotTracking(state) {
    return {
      radio_lot: state.radio_lot,
      radio_barcode: state.radio_barcode,
    };
  },
};
const mutations = {
  setTargetLotToLotTracking(state, payload) {
    state.target_lot = payload;
  },
  setSelectedTabToLotTracking(state, payload) {
    state.selectedTab = payload;
  },
  setSelectedIndexToLotTracking(state, payload) {
    state.selectedLotIndex = payload;
  },
  setSearchTextToLotTracking(state, payload) {
    state.searchText = payload;
  },
  setLotTrackingResource(state, payload) {
    state.tracking_resource = payload;
  },
  setLotTrackingSales(state, payload) {
    state.tracking_sales = payload;
  },
  setManagementLotDataToLotTracking(state, payload) {
    state.managementLotData = payload;
  },
  setRadioStateToLotTracking(state, payload) {
    console.log(payload);
    switch (payload) {
      case 'lot':
        state.radio_lot = true;
        state.radio_barcode = false;
        break;
      case 'barcode':
        state.radio_lot = false;
        state.radio_barcode = true;
        break;
    }
  },
};
const actions = {
  async FETCH_LOT_PRODUCTION_TO_LOT_TRACKING({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/production/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('bom_lot', response.data.data);
            commit('setTargetLotToLotTracking', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_LOT_PRODUCTION_YEAR({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/production/year/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setTargetLotToLotTracking', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async FETCH_LOT_TRACKING_RESOURCE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/tracking-resource/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('bom_lot', response.data.data);
            commit('setLotTrackingResource', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FIND_LOT_ID_FROM_CONTROL_ID(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/find-by-control-id/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response.data.data);
          }
          console.log(response);
          reject(-1);
        })
        .catch(error => {
          console.log(error);
          reject(-1);
        });
    });
  },
  async FETCH_LOT_TRACKING_SALES({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/tracking-sales/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('bom_lot', response.data.data);
            commit('setLotTrackingSales', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
