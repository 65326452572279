import { companyInstance } from '@/api/index.js';
import clonedeep from 'lodash/cloneDeep';

const state = {
  // session_life: 1 * 40,
  session_life: 1 * 60 * 30,
  // session_life: 1 * 60 * 0.35,
  session_timer: 1 * 60 * 30,
  show_session_out_modal: false,
};
const getters = {
  getSessionLife(state) {
    return state.session_life;
  },
  getSessionTimer(state) {
    return state.session_timer;
  },
  getShowSessionOutModalFlag(state) {
    return state.show_session_out_modal;
  },
};
const mutations = {
  refreshSessionTimer(state) {
    state.session_timer = clonedeep(state.session_life);
  },
  setSessionTimer(state, payload) {
    state.session_timer = payload;
  },
  setShowSessionOutModalFlag(state, payload) {
    state.show_session_out_modal = payload;
  },
};
const actions = {
  async CHECK_SESSION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`session-check/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response.data.data);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async CHECK_SESSION_ACCOUNT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`session-check/account/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response.data.data);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async CREATE_SESSION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post(`session`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response.data.data);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async DELETE_SESSION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`session/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
