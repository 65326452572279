import { companyInstance } from '@/api/index.js';

const state = {
  product_in_out_types: [],
};
const getters = {
  getProductInOutType(state) {
    return state.product_in_out_types.sort((a, b) => a.id - b.id);
  },
  getProductInOutTypeNotSort(state) {
    return state.product_in_out_types;
  },
  getProductProcessInType(state) {
    return state.product_in_out_types.find(x => x.detail == 'process in');
  },
  getProductUsingOutType(state) {
    return state.product_in_out_types.find(x => x.detail == 'using out');
  },
  getProductSalesOutType(state) {
    return state.product_in_out_types.find(x => x.detail == 'sales out');
  },
  getProductReturnInType(state) {
    return state.product_in_out_types.find(x => x.detail == 'return in');
  },
  getProductReturnInTypeId(state) {
    if (
      state.product_in_out_types.find(x => x.detail == 'return in') != undefined
    ) {
      return state.product_in_out_types.find(x => x.detail == 'return in').id;
    } else {
      return null;
    }
  },
};
const mutations = {
  setProductInOutType(state, payload) {
    state.product_in_out_types = payload;
  },
};

const actions = {
  FETCH_PRODUCT_IN_OUT_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/product/in-out-type')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setProductInOutType', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
