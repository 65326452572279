import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  check_lists: [],
  start_date: null,
  end_date: null,
  managementData: null,
  modifyChkData: null,
  selectedIndex: -1,
};
const getters = {
  getCheckListFromProductVerification(state) {
    return state.check_lists;
  },
  getStartDateFromProductVerification(state) {
    return state.start_date;
  },
  getEndDateFromProductVerification(state) {
    return state.end_date;
  },
  getDateFromProductVerification(state) {
    return {
      start: formatDateNoHours(state.start_date),
      end: formatDateNoHours(state.end_date),
    };
  },
  getManagementDataFromProductVerification(state) {
    return state.managementData;
  },
  getModifyChkDataFromProductVerification(state) {
    return state.modifyChkData;
  },
  getSelectedIndexFromProductVerification(state) {
    return state.selectedIndex;
  },
};
const mutations = {
  setCheckListToProductVerification(state, payload) {
    state.check_lists = payload;
  },
  setStartDateToProductVerification(state, payload) {
    state.start_date = payload;
  },
  setEndDateToProductVerification(state, payload) {
    state.end_date = payload;
  },
  setManagementDataToProductVerification(state, payload) {
    state.managementData = payload;
  },
  setModifyChkDataToProductVerification(state, payload) {
    state.modifyChkData = payload;
  },
  setSelectedIndexToProductVerification(state, payload) {
    state.selectedIndex = payload;
  },
};
const actions = {
  FETCH_PRODUCT_VERIFICATION_DEFECT({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/product/verification/defect/start_day=${newDate.start}&end_day=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setCheckListToProductVerification',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_PRODUCT_DEFECT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/product/defect/all/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            response.data.data_list.forEach(el => {
              el.check_quantity = el.pass_quantity + el.unpass_quantity;
            });
            commit(
              'setManagementDataToProductVerification',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_PRODUCT_QUALITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('mag/product/quality', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DELETE_PRODUCT_QUALITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`mag/product/quality/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UPDATE_PRODUCT_QUALITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('mag/product/quality', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
