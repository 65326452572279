function saveAuthToCookie(value) {
  document.cookie = `auth=${value}`;
}

function saveUserToCookie(value) {
  document.cookie = `user=${value}`;
}

function saveUrlToCookie(value) {
  document.cookie = `CompanyUrl=${value}`;
}

function saveSaupToCookie(value) {
  document.cookie = `saup=${value}`;
}

function getAuthFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
}

function getUserFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)user\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
}

function getUrlToCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)CompanyUrl\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
}

function getSaupFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)saup\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
}

function deleteCookie(value) {
  document.cookie = `${value}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export {
  saveAuthToCookie,
  saveUserToCookie,
  saveUrlToCookie,
  saveSaupToCookie,
  getAuthFromCookie,
  getUserFromCookie,
  getUrlToCookie,
  getSaupFromCookie,
  deleteCookie,
};
