const state = {
  openTabIndex: 0,
};

const getters = {
  getOpenTabIndexFromStatusMonitorPage(state) {
    return state.openTabIndex;
  },
};
const mutations = {
  setOpenTabIndexToStatusMonitorPage(state, idx) {
    state.openTabIndex = idx;
  },
};

export default { state, getters, mutations };
