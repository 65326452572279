const state = {
  managementMode: false,
};

const getters = {
  getManagementModeFromMachineManagementPage(state) {
    return state.managementMode;
  },
};
const mutations = {
  toggleManagementModeToMachineManagementPage(state) {
    state.managementMode = !state.managementMode;
  },
};

export default { state, getters, mutations };
