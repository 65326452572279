import { companyInstance } from '@/api/index';
const state = {
  process: [],
  selectedIndex: -1,
  visibleDel: false,
  managementData: {
    id: -1,
    name: '',
    detail: '',
    machine_id: null,
    package_process_yn: false,
    process_verification_id: null,
    visible: false,
    r_process_machine_list: [],
  },
  newData: {
    name: '',
    detail: '',
    machine_id: null,
    package_process_yn: false,
    process_verification_id: null,
    visible: false,
    r_process_machine_list: [],
  },
};

const getters = {
  getProcessMulti(state) {
    return state.process.sort((a, b) => a.id - b.id);
  },
  getVisibleProcessMulti(state) {
    return state.process.filter(x => x.visible).sort((a, b) => a.id - b.id);
  },
  getDeletedProcessMulti(state) {
    return state.process.filter(x => !x.visible).sort((a, b) => a.id - b.id);
  },
  getShowDeleteProcessMultiFlag(state) {
    return state.visibleDel;
  },
  getSelectedIndexFromProcessMulti(state) {
    return state.selectedIndex;
  },
  getManagementDataFromProcessMulti(state) {
    return state.managementData;
  },
  getNewDataFromProcessMulti(state) {
    return state.newData;
  },
};

const mutations = {
  setProcessMulti(state, payload) {
    state.process = payload;
  },
  setShowDeleteProcessMultiFlag(state, payload) {
    state.visibleDel = payload;
  },
  setSelectedIndexToProcessMulti(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToProcessMulti(state, payload) {
    state.managementData = payload;
  },
  setNewDataToProcessMulti(state, payload) {
    state.newData = payload;
  },
  setManagementDataMachineIdToProcessMulti(state, id) {
    state.managementData.machine_id = id;
  },
  setNewDataMachineIdToProcessMulti(state, id) {
    state.newData.machine_id = id;
  },
};

const actions = {
  FETCH_PROCESS_MULTI({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/process-multi-select/info')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setProcessMulti', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_PROCESS_MULTI(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/process-multi-select/info', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_PROCESS_MULTI(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/process-multi-select/info', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_PROCESS_MULTI(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/process-multi-select/info/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  RESTORE_PROCESS_MULTI(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/process-multi-select/info/restore/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
