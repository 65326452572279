import { companyInstance } from '@/api/index.js';

const state = {
  payment_types: [],
};

const getters = {
  getPaymentTypes(state) {
    return state.payment_types;
  },
  getPaymentsForSelectricAll(state) {
    let options = [];
    options.push({ label: '전체', value: null, detail: null });
    const types = state.payment_types;
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getPaymentsForSelectric(state) {
    let options = [];
    options.push({ label: '선택', value: null, detail: null });
    const types = state.payment_types;
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
};

const mutations = {
  setPaymentTypes(state, payload) {
    state.payment_types = payload;
  },
};

const actions = {
  FETCH_PAYMENTS_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('payment-type')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setPaymentTypes', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
