import { companyInstance } from '@/api/index';
import clonedeep from 'lodash/cloneDeep';

const state = {
  sales: [],
};
const getters = {
  getSalesFromOutputRequest(state) {
    return state.sales;
  },
};
const mutations = {
  setSalesToOutputRequest(state, payload) {
    state.sales = payload;
  },
};
const actions = {
  FETCH_SALES_TO_OUTPUT_REQUEST({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plan/sales/info/start_date=1700-01-01&end_date=2500-01-01`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            response.data.data_list.forEach(x => {
              x.product_list = clonedeep(
                x.product_list.filter(x => x.output_yn == false),
              );
            });
            commit('setSalesToOutputRequest', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_SALES_TO_OUTPUT_REQUEST_TODAY({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plan/sales/info/start_date=${payload}&end_date=${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setSalesToOutputRequest', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
