import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  //return
  returnableList: [],
  selectedIndex: -1,
  searchText: '',
  companySearchText: '',
  managementData: null,
  start_date: null,
  end_date: null,

  //returnStatus
  return_list: [],
};
const getters = {
  //return
  getStartDateFromProductReturn(state) {
    return state.start_date;
  },
  getEndDateFromProductReturn(state) {
    return state.end_date;
  },
  getReturnableListFromProductReturn(state) {
    return state.returnableList.sort(
      (a, b) =>
        Number(
          b.create_time
            .replace(/-/gi, '')
            .replace(/:/gi, '')
            .replace(' ', ''),
        ) -
        Number(
          a.create_time
            .replace(/-/gi, '')
            .replace(/:/gi, '')
            .replace(' ', ''),
        ),
    );
  },
  getSelectedIndexFromProductReturn(state) {
    return state.selectedIndex;
  },
  getSearchTextFromProductReturn(state) {
    return state.searchText;
  },
  getCompanySearchTextFromProductReturn(state) {
    return state.companySearchText;
  },
  getManagementDataFromProductReturn(state) {
    return state.managementData;
  },

  //returnStatus
  getReturnListFromProductReturnStatus(state) {
    return state.return_list.sort(
      (a, b) => new Date(b.sales.input_date) - new Date(a.sales.input_date),
    );
  },
};
const mutations = {
  //return
  setStartDateToProductReturn(state, payload) {
    state.start_date = payload;
  },
  setEndDateToProductReturn(state, payload) {
    state.end_date = payload;
  },
  setReturnableListToProductReturn(state, payload) {
    state.returnableList = payload;
  },
  setSelectedIndexToProductReturn(state, payload) {
    state.selectedIndex = payload;
  },
  setSearchTextToProductReturn(state, payload) {
    state.searchText = payload;
  },
  setCompanySearchTextToProductReturn(state, payload) {
    state.companySearchText = payload;
  },
  setManagementDataToProductReturn(state, payload) {
    state.managementData = payload;
  },
  setManagementDataReturnTypeToProductReturn(state, payload) {
    state.managementData.product_in_out_type_id = payload;
  },

  //returnStatus
  setReturnListToProductReturnStatus(state, payload) {
    state.return_list = payload;
  },
};

const actions = {
  FETCH_RETURNABLE_PRODUCT_LIST({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/product/returnable')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setReturnableListToProductReturn', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_RETURNABLE_PRODUCT_LIST_DATE({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/product/returnable/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setReturnableListToProductReturn', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_PRODUCT_RETURN(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('plan/sales/return', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_PRODUCT_RETURN_LIST_DATE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `plan/sales/return/start_date=${payload.start}&end_date=${payload.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit(
              'setReturnListToProductReturnStatus',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DELETE_PRODUCT_RETURN(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`plan/sales/return/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
