const state = {
  managementMode: false,
};

const getters = {
  getManagementModeFromSystemCompanyPage(state) {
    return state.managementMode;
  },
};

const mutations = {
  toggleManagementModeToSystemCompanyPage(state) {
    state.managementMode = !state.managementMode;
  },
};

export default {
  state,
  getters,
  mutations,
};
