import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  plc_error_logs: [],
  plc_error_log: [],
  start_date: null,
  end_date: null,
};
const getters = {
  getPlcErrorLog(state) {
    return state.plc_error_logs;
  },
  getPlcErrorLogs(state) {
    return state.plc_error_log;
  },
  getStartDateFromPlcErrorLog(state) {
    return state.start_date;
  },
  getEndDateFromPlcErrorLog(state) {
    return state.end_date;
  },
};
const mutations = {
  setPlcErrorLog(state, payload) {
    state.plc_error_logs = payload;
  },
  setPlcErrorLogs(state, payload) {
    state.plc_error_log = payload;
  },
  setStartDateToPlcErrorLog(state, payload) {
    state.start_date = payload;
  },
  setEndDateToPlcErrorLog(state, payload) {
    state.end_date = payload;
  },
};
const actions = {
  FETCH_PLC_ERROR_LOG({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('plc/error-log')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcErrorLog', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PLC_ERROR_LOG_DAY({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(`plc/error-log/day/start=${newDate.start}&end=${newDate.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcErrorLog', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PLC_ERROR_LOG_DAYS({ commit }) {
    return new Promise((resolve, reject) => {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 1);
      let endDate = new Date();
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: formatDateNoHours(startDate),
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `plc/error-log/day/start_day=${newDate.start}&end_day=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcErrorLogs', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
