import { companyInstance } from '@/api/index.js';
import cloneDeep from 'lodash/cloneDeep';
import clonedeep from 'lodash/cloneDeep';
import { yyyymmdd } from '@/utils/func';

const state = {
  notices: [],
  not_expired_notices: [],
  notices_temp: [],
  selectedIndex: -1,
  management_data: {
    id: -1,
    expired_day: yyyymmdd(new Date()),
    title: '',
    detail: '',
  },
  new_data: {
    expired_day: yyyymmdd(new Date()),
    title: '',
    detail: '',
  },
};

const getters = {
  getNoticeList(state) {
    return state.notices.sort((a, b) => b.id - a.id);
  },
  getNoticeTempList(state) {
    return state.notices_temp;
  },
  getNotExpiredNoticeList(state) {
    return state.not_expired_notices;
  },
  getNoticeListTop3(state) {
    return state.not_expired_notices.sort((a, b) => b.id - a.id).slice(0, 3);
  },
  getManagementDataFromNoticePage(state) {
    return state.management_data;
  },
  getNewDataFromNoticePage(state) {
    return state.new_data;
  },
  getSelectedIndexFromNoticePage(state) {
    return state.selectedIndex;
  },
};

const mutations = {
  setNoticeList(state, payload) {
    state.notices = payload;
    state.notices_temp = clonedeep(payload);
  },
  setNotExpiredNoticeList(state, payload) {
    state.not_expired_notices = payload;
  },
  setSelectedIndexToNoticePage(state, index) {
    if (index != -1) {
      state.management_data = cloneDeep(state.notices[index]);
    } else {
      state.management_data = {
        id: -1,
        expired_day: yyyymmdd(new Date()),
        title: '',
        detail: '',
      };
    }
    state.selectedIndex = index;
  },
  clearNewDataToNoticePage(state) {
    state.new_data = {
      expired_day: yyyymmdd(new Date()),
      title: '',
      detail: '',
    };
  },
};

const actions = {
  async FETCH_NOTICE_LIST({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('notice')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setNoticeList', response.data.data_list);
            resolve(response.data.result);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_NOTICE_LIST_ONLY_NOT_EXPIRED({ commit }) {
    const today = yyyymmdd(new Date());
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`notice/only-not-expired/${today}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setNotExpiredNoticeList', response.data.data_list);
            resolve(response.data.result);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UPDATE_NOTICE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('notice', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_NOTICE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('notice', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response.data.result);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_NOTICE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`notice/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
