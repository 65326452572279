import Decimal from 'decimal.js';

// 필터 관련 함수가 존재하는 파일
export function formatDate(value) {
  const date = new Date(value);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month > 9 ? month : `0${month}`;
  let day = date.getDate();
  day = day > 9 ? day : `0${day}`;
  let hours = date.getHours();
  hours = hours > 9 ? hours : `0${hours}`;
  let minutes = date.getMinutes();
  minutes = minutes > 9 ? minutes : `0${minutes}`;
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function formatDateHHMMSS(value) {
  const date = new Date(value);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month > 9 ? month : `0${month}`;
  let day = date.getDate();
  day = day > 9 ? day : `0${day}`;
  let hours = date.getHours();
  hours = hours > 9 ? hours : `0${hours}`;
  let minutes = date.getMinutes();
  minutes = minutes > 9 ? minutes : `0${minutes}`;
  let seconds = date.getSeconds();
  seconds = seconds > 9 ? seconds : `0${seconds}`;
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export function format년월일요일시분초(value) {
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const date = new Date(value);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month > 9 ? month : `0${month}`;
  let day = date.getDate();
  day = day > 9 ? day : `0${day}`;
  let hours = date.getHours();
  hours = hours > 9 ? hours : `0${hours}`;
  let minutes = date.getMinutes();
  minutes = minutes > 9 ? minutes : `0${minutes}`;
  let seconds = date.getSeconds();
  seconds = seconds > 9 ? seconds : `0${seconds}`;
  let dayOfWeek = week[date.getDay()];
  return `${year}년 ${month}월 ${day}일 (${dayOfWeek}) ${hours}:${minutes}:${seconds}`;
}

export const yyyy년MM월dd일 = function(date) {
  var MM = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return [
    date.getFullYear(),
    '년 ' + (MM > 9 ? MM : '0' + MM),
    '월 ' + (dd > 9 ? dd : '0' + dd),
    '일 ',
  ].join('');
};
export const hhmm = function(date) {
  let hh = date.getHours();
  var mm = date.getMinutes();

  return [hh > 9 ? hh : '0' + hh, ':' + (mm > 9 ? mm : '0' + mm)].join('');
};

export function formatDateNoHours(value) {
  const date = new Date(value);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month > 9 ? month : `0${month}`;
  let day = date.getDate();
  day = day > 9 ? day : `0${day}`;

  return `${year}-${month}-${day}`;
}

export function makeComma(value) {
  if (value == undefined) return '';
  value = String(value).replace(/,/gi, '');
  if (value.trim().length == 0) {
    return '0';
  }

  let returnValue = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (returnValue.includes('.')) {
    returnValue =
      returnValue.split('.')[0] +
      '.' +
      returnValue.split('.')[1].replace(/,/gi, '');
  }

  return returnValue;
}
export function makeCommaNotZero(value) {
  if (value == undefined) return '';
  if (value.length == 0) {
    return '';
  }
  value = Number(value);
  if (value == 0) {
    return '';
  }

  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function makeNumber(value) {
  if (value == undefined) return 0;
  let newValue = String(value);
  if (newValue.trim().length == 0) return 0;
  newValue = newValue.replace(/,/gi, '');
  newValue = Number(newValue).toFixed(12);
  return Number(newValue);
}
export function makeNumberInteger(value) {
  if (value == undefined) return 0;
  let newValue = String(value);
  if (newValue.trim().length == 0) return 0;
  newValue = newValue.replace(/,/gi, '');
  newValue = Number(newValue).toFixed(0);
  return Number(newValue);
}
export function makeNumberFixed2(value) {
  if (value == undefined) return 0;
  let newValue = String(value);
  if (newValue.trim().length == 0) return 0;
  newValue = newValue.replace(/,/gi, '');
  newValue = Number(newValue).toFixed(2);
  return Number(newValue);
}
export function inputNumber(e, target, key, max, min, is_allow_minus) {
  let val = e.target.value;
  if (is_allow_minus) {
    if (val == '0-' || val == '-0') {
      target[key] = '-';
      e.target.value = '-';
    }
    if (val == '-') {
      target[key] = '0';
      e.target.value = '0';
    }
  }

  if (target[key] == '0') {
    val = val.replace(/0/, '');
  }

  if (val == undefined) {
    e.preventDefault();
    target[key] = String(0);
    e.target.value = String(0);
    return;
  }
  let newValue = String(val);
  if (newValue.trim().length == 0) {
    e.preventDefault();
    target[key] = String(0);
    e.target.value = String(0);
    return;
  }
  if (newValue == '0') {
    e.preventDefault();
    target[key] = String(0);
    e.target.value = String(0);
    return;
  } else if (newValue == '00') {
    e.preventDefault();
    target[key] = String(0);
    e.target.value = String(0);
    return;
  }
  let match = newValue.match(/\./g);
  match = match == null ? [] : match;
  const count = match.length;

  if (count == 1 && newValue[newValue.length - 1] == '.') {
    e.preventDefault();
    return;
  }
  newValue = newValue.replace(/,/gi, '');
  if (isNaN(Number(newValue))) {
    e.preventDefault();
    if (count > 0) {
      if (count > 1) {
        e.target.value = String(target[key])
          .split('.')[0]
          .replace(/,/gi, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        target[key] = String(target[key])
          .split('.')[0]
          .replace(/,/gi, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        e.target.value =
          String(target[key])
            .split('.')[0]
            .replace(/,/gi, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
          '.' +
          String(target[key]).split('.')[1];
        target[key] =
          String(target[key])
            .split('.')[0]
            .replace(/,/gi, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
          '.' +
          String(target[key]).split('.')[1];
      }
    } else {
      e.target.value = String(target[key])
        .replace(/,/gi, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      target[key] = String(target[key])
        .replace(/,/gi, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return;
  }
  if (count > 0) {
    newValue = Number(newValue).toFixed(newValue.split('.')[1].length);
  } else {
    newValue = Number(newValue);
  }

  // newValue = this.$makeNumber(newValue);
  if (max != undefined && newValue > max) {
    newValue = max;
  } else if (min != undefined && newValue < min) {
    newValue = min;
  } else if (newValue > 2147483647) {
    e.preventDefault();
    if (newValue % 1 > 0) {
      target[key] =
        String(target[key])
          .split('.')[0]
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
        '.' +
        String(target[key])
          .split('.')[1]
          .substr(0, 12);
      e.target.value =
        String(target[key].split('.')[0])
          .replace(/,/gi, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
        '.' +
        String(target[key].split('.')[1]).substr(0, 12);
    } else {
      target[key] = String(target[key]).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      e.target.value = String(target[key])
        .replace(/,/gi, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return;
  }
  if (count > 0 && newValue % 1 > 0) {
    target[key] =
      String(newValue)
        .split('.')[0]
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      '.' +
      String(newValue)
        .split('.')[1]
        .substr(0, 12);
    e.target.value =
      String(newValue)
        .split('.')[0]
        .replace(/,/gi, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      '.' +
      String(newValue)
        .split('.')[1]
        .substr(0, 12);
  } else {
    target[key] = String(newValue).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    e.target.value = String(newValue)
      .replace(/,/gi, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return;
}

export function inputNumberInt(e, target, key, max, min) {
  let val = e.target.value;

  if (target[key] == '0') {
    val = val.replace(/0/gi, '');
  }

  if (val == undefined) {
    e.preventDefault();
    target[key] = String(0);
    e.target.value = String(0);
    return;
  }

  let newValue = String(val);
  if (newValue.trim().length == 0) {
    e.preventDefault();
    target[key] = String(0);
    e.target.value = String(0);
    return;
  }

  if (newValue == '0') {
    e.preventDefault();
    target[key] = String(0);
    e.target.value = String(0);
    return;
  } else if (newValue == '00') {
    e.preventDefault();
    target[key] = String(0);
    e.target.value = String(0);
    return;
  }

  newValue = newValue.replace(/,/gi, '');
  if (isNaN(Number(newValue))) {
    e.preventDefault();
    e.target.value = String(target[key])
      .replace(/,/gi, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    target[key] = String(target[key])
      .replace(/,/gi, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return;
  }

  newValue = Number(newValue);
  if (max != undefined && newValue > max) {
    newValue = max;
  } else if (min != undefined && newValue < min) {
    newValue = min;
  } else if (newValue > 2147483647) {
    e.preventDefault();

    target[key] = String(target[key]).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    e.target.value = String(target[key])
      .replace(/,/gi, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return;
  }

  target[key] = String(newValue).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  e.target.value = String(newValue)
    .replace(/,/gi, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return;
}
export function inputNumberIntMinNotZero(e, target, key, max, min) {
  let val = e.target.value;

  if (target[key] == '0') {
    val = val.replace(/0/gi, '');
  }

  if (val == undefined) {
    e.preventDefault();
    target[key] = String(0);
    e.target.value = String(0);
    return;
  }

  let newValue = String(val);
  if (newValue.trim().length == 0) {
    e.preventDefault();
    target[key] = String(0);
    e.target.value = String(0);
    return;
  }

  newValue = newValue.replace(/,/gi, '');
  if (isNaN(Number(newValue))) {
    e.preventDefault();
    e.target.value = String(target[key])
      .replace(/,/gi, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    target[key] = String(target[key])
      .replace(/,/gi, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return;
  }

  newValue = Number(newValue);
  if (max != undefined && newValue > max) {
    newValue = max;
  } else if (min != undefined && newValue < min) {
    newValue = min;
  } else if (newValue > 2147483647) {
    e.preventDefault();

    target[key] = String(target[key]).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    e.target.value = String(target[key])
      .replace(/,/gi, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return;
  }

  target[key] = String(newValue).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  e.target.value = String(newValue)
    .replace(/,/gi, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return;
}

export function inputNumberIntNoTarget(e) {
  let val = e.target.value;

  let newValue = String(val);
  if (newValue.trim().length == 0) {
    e.preventDefault();

    e.target.value = String(0);
    return;
  }

  if (newValue == '0') {
    e.preventDefault();

    e.target.value = String(0);
    return;
  } else if (newValue == '00') {
    e.preventDefault();

    e.target.value = String(0);
    return;
  }

  newValue = newValue.replace(/,/gi, '');
  if (isNaN(Number(newValue))) {
    e.preventDefault();
    e.target.value = '0';

    return;
  }

  newValue = Number(newValue);

  if (newValue > 2147483647) {
    e.preventDefault();
    console.log('max not dot');
    console.log(e.target.value);

    e.target.value = String(
      String(e.target.value).substr(0, e.target.value.length - 1),
    )
      .replace(/,/gi, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return;
  }
  console.log('normal not dot');

  e.target.value = String(newValue)
    .replace(/,/gi, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return;
}
export function decimalAdd(a, b) {
  a = new Decimal(a);
  b = new Decimal(b);
  return Number(a.add(b).toFixed(12));
}
export function decimalSub(a, b) {
  a = new Decimal(a);
  b = new Decimal(b);
  return Number(a.sub(b).toFixed(12));
}
export function decimalMul(a, b) {
  a = new Decimal(a);
  b = new Decimal(b);
  return Number(a.mul(b).toFixed(12));
}
export function decimalDiv(a, b) {
  a = new Decimal(a);
  b = new Decimal(b);
  return Number(a.div(b).toFixed(12));
}
export function decimalMod(a, b) {
  a = new Decimal(a);
  b = new Decimal(b);
  return Number(a.mod(b).toFixed(12));
}

export function loopTest() {
  console.log(this.$options._componentTag);
}
