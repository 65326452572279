import { companyInstance } from '@/api/index';

const state = {
  materialManagementData: {},
  input_date: null,
  start_date: null,
  end_date: null,
  // 미입고창고
  materialSelectedIndex: -1,

  // 입고창고
  purchaseSelectedIndex: -1,
};

const getters = {
  getMaterialManagementDataFromInput(state) {
    return state.materialManagementData;
  },
  getMaterialBaseInputDateFromInput(state) {
    return state.input_date;
  },
  getStoreInputStartDateFromInput(state) {
    return state.start_date;
  },
  getStoreInputEndDateFromInput(state) {
    return state.end_date;
  },
  // 생산제품입고
  getMaterialSelectedIndexFromInput(state) {
    return state.materialSelectedIndex;
  },

  // 반품입고
  getPurchaseSelectedIndexFromInput(state) {
    return state.purchaseSelectedIndex;
  },
};
const mutations = {
  setMaterialManagementDataToInput(state, payload) {
    state.materialManagementData = payload;
  },
  setMaterialManagementStoreIdToInput(state, payload) {
    state.materialManagementData.store_id = payload;
    console.log(state.materialManagementData.store_id);
  },
  setMaterialManagementLocationIdToInput(state, payload) {
    state.materialManagementData.location_id = payload;
    console.log(state.materialManagementData.location_id);
  },
  setMaterialBaseInputDateToInput(state, payload) {
    state.input_date = payload;
  },
  setStoreInputStartDateToInput(state, payload) {
    state.start_date = payload;
  },
  setStoreInputEndDateToInput(state, payload) {
    state.end_date = payload;
  },
  // 생산제품입고
  setMaterialSelectedIndexToInput(state, payload) {
    state.materialSelectedIndex = payload;
  },
  // 반품입고

  setPurchaseSelectedIndexToInput(state, payload) {
    state.purchaseSelectedIndex = payload;
  },
};
const actions = {
  UPDATE_ONE_ELEMENT_MATERIAL_WITH_PURCHASE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/purchase/info/one-element', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
