const state = {
  openTabIndex: 0,
  managementMode: false,
};

const getters = {
  getOpenTabIndexFromPatchNotePage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromPatchNotePage(state) {
    return state.managementMode;
  },
};

const mutations = {
  setOpenTabIndexToPatchNotePage(state, payload) {
    state.openTabIndex = payload;
  },
  toggleManagementModeToPatchNotePage(state) {
    state.managementMode = !state.managementMode;
  },
};

const actions = {};

export default { state, getters, mutations, actions };
