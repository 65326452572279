import { formatDateNoHours } from '@/utils/filters';
const state = {
  subtabs: [
    { name: 'userform', title: '사용자 이용현황' },
    { name: 'menuform', title: '메뉴 이용현황' },
  ],
  openTabIndex: 0,

  start_date: undefined,
  end_date: undefined,
};
const getters = {
  getSubTabsFromUserActionPage(state) {
    return state.subtabs;
  },
  getOpenTabIndexFromUserActionPage(state) {
    return state.openTabIndex;
  },

  getDateFromUserActionPage(state) {
    return {
      start: formatDateNoHours(state.start_date),
      end: formatDateNoHours(state.end_date),
    };
  },
  getStartDateFromUserActionPage(state) {
    return state.start_date;
  },
  getEndDateFromUserActionPage(state) {
    return state.end_date;
  },
};

const mutations = {
  setOpenTabIndexToUserActionPage(state, index) {
    state.openTabIndex = index;
  },
  setStartDateToUserActionPage(state, payload) {
    state.start_date = payload;
  },
  setEndDateToUserActionPage(state, payload) {
    state.end_date = payload;
  },
  // resetDateToUserActionPage(state) {
  //   const today = new Date();
  //   state.end_date = today;
  //   const lastMonth = new Date();
  //   lastMonth.setMonth(lastMonth.getMonth() - 1);
  //   state.start_date = lastMonth;
  // },
};

export default {
  state,
  getters,
  mutations,
};
