const state = {
  openTabIndex: 0,
};

const getters = {
  getOpenTabIndexFromInputPrintPage(state) {
    return state.openTabIndex;
  },
};
const mutations = {
  setOpenTabIndexToInputPrintPage(state, idx) {
    state.openTabIndex = idx;
  },
};

export default { state, getters, mutations };
