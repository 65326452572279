import { companyInstance } from '@/api/index';
const state = {
  material_companys: [],
};

const getters = {
  getMaterialCompany(state) {
    return state.material_companys.sort((a, b) => a.id - b.id);
  },
  getMaterialCompanyNotSort(state) {
    return state.material_companys;
  },
};

const mutations = {
  setMaterialCompany(state, payload) {
    state.material_companys = payload;
  },
};

const actions = {
  FETCH_MATERIAL_COMPANY({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .get('material-company')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setMaterialCompany', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_MATERIAL_COMPANY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .post('material-company', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_MATERIAL_COMPANY_LIST(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .post('material-company/list', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_MATERIAL_COMPANY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .delete(`material-company/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_MATERIAL_COMPANY_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .delete(`material-company/all/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
