import { companyInstance } from '@/api';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  purchase_account: [],
  start_date: null,
  end_date: null,
  include_company_list: [],
};

const getters = {
  getPurchaseAccountFromPurchaseLedger(state) {
    return state.purchase_account;
  },
  getStartDateFromPurchaseLedger(state) {
    return state.start_date;
  },
  getEndDateFromPurchaseLedger(state) {
    return state.end_date;
  },
  getDateFromPurchaseLedger(state) {
    return {
      start: formatDateNoHours(state.start_date),
      end: formatDateNoHours(state.end_date),
    };
  },
  getIncludeCompanyListFromPurchaseLedger(state) {
    return state.include_company_list;
  },
};

const mutations = {
  setPurchaseAccountToPurchaseLedger(state, payload) {
    state.purchase_account = payload;
  },
  setStartDateToPurchaseLedger(state, payload) {
    state.start_date = payload;
  },
  setEndDateToPurchaseLedger(state, payload) {
    state.end_date = payload;
  },
  setIncludeCompanyListToPurchaseLedger(state, payload) {
    state.include_company_list = payload;
  },
};
const actions = {
  FETCH_PURCHASE_ACCOUNT_DAY_ALL_TO_PURCHASE_LEDGER({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `plan/purchase/account/all/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setPurchaseAccountToPurchaseLedger',
              response.data.data_list,
            );
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
