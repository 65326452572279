import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';
import { groupByMenuId } from '@/utils/func';

const state = {
  user_actions: [],
  user_actions_temp: [],
};
const getters = {
  getUserAction(state) {
    return state.user_actions.sort(
      (a, b) => new Date(b.create_time) - new Date(a.create_time),
    );
  },
  getUserActionGroupByMenuGroupId(state) {
    return groupByMenuId(state.user_actions, 'menu_group_id').sort(
      (a, b) => b.count - a.count,
    );
  },
  getUserActionGroupByMenuGroupIdTopSix(state) {
    return groupByMenuId(state.user_actions_temp, 'menu_group_id')
      .sort((a, b) => b.count - a.count)
      .splice(0, 6);
  },
};
const mutations = {
  setUserAction(state, payload) {
    state.user_actions = payload;
    state.user_actions_temp = payload;
  },
};
const actions = {
  async FETCH_USER_ACTION({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(`user/action/start=${newDate.start}&end=${newDate.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setUserAction', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_USER_ACTION(context, payload) {
    console.log('INSERT_USER_ACTION : payload > ', payload);
    return new Promise((resolve, reject) => {
      companyInstance
        .post('user/action', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('INSERT_USER_ACTION : return data > ', response.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
