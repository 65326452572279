import { companyInstance } from '@/api/index';

const state = {
  // 생산제품입고
  selectedIndex: -1,
  managementData: null,
  searchText: '',
  start_date: null,
  end_date: null,

  // 반품입고
  status_selectedIndex: -1,
  status_managementData: null,
  status_searchText: '',
  status_companySearchText: '',
  status_start_date: null,
  status_end_date: null,
};

const getters = {
  // 생산제품입고
  getSelectedIndexFromProductControl(state) {
    return state.selectedIndex;
  },
  getManagementDataFromProductControl(state) {
    return state.managementData;
  },
  getSearchTextFromProductControl(state) {
    return state.searchText;
  },
  getStartdateFromProductControl(state) {
    return state.start_date;
  },
  getEnddateFromProductControl(state) {
    return state.end_date;
  },

  // 반품입고
  getStartDateFromProductReturnStatus(state) {
    return state.status_start_date;
  },
  getEndDateFromProductReturnStatus(state) {
    return state.status_end_date;
  },
  getSelectedIndexFromProductReturnStatus(state) {
    return state.status_selectedIndex;
  },
  getSearchTextFromProductReturnStatus(state) {
    return state.status_searchText;
  },
  getCompanySearchTextFromProductReturnStatus(state) {
    return state.status_companySearchText;
  },
  getManagementDataFromProductReturnStatus(state) {
    return state.status_managementData;
  },
};
const mutations = {
  // 생산제품입고
  setSelectedIndexToProductControl(state, payload) {
    state.selectedIndex = payload;
  },
  setManagementDataToProductControl(state, payload) {
    state.managementData = payload;
  },
  setSearchTextToProductControl(state, payload) {
    state.searchText = payload;
  },
  setProductManagementStoreIdToProductControl(state, payload) {
    state.managementData.store_id = payload;
  },
  setProductManagementLocationIdToProductControl(state, payload) {
    state.managementData.location_id = payload;
  },
  setStartDateToProductControl(state, payload) {
    state.start_date = payload;
  },
  setEndDateToProductControl(state, payload) {
    state.end_date = payload;
  },

  // 반품입고
  setStartDateToProductReturnStatus(state, payload) {
    state.status_start_date = payload;
  },
  setEndDateToProductReturnStatus(state, payload) {
    state.status_end_date = payload;
  },
  setSelectedIndexToProductReturnStatus(state, payload) {
    state.status_selectedIndex = payload;
  },
  setSearchTextToProductReturnStatus(state, payload) {
    state.status_searchText = payload;
  },
  setCompanySearchTextToProductReturnStatus(state, payload) {
    state.status_companySearchText = payload;
  },
  setManagementDataToProductReturnStatus(state, payload) {
    state.status_managementData = payload;
  },
};
const actions = {
  UPDATE_RETURN_PRODUCT_STORE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/sales/return/store', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
