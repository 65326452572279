import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';
const state = {
  openTabIndex: 0,
  managementMode: false,
  search_type: null,
  get_machineList: [],
  machineList: [],
  machineCheckList: [],
  CheckList: [],
  checkResultList: [],
  filteredCheckResultList: [],
  repairHistory: [],
  totalRepairHistory: [],
  historyList: [],
  checkResultStartDate: '',
  checkResultEndDate: '',
  RepairHistoryStartDate: '',
  RepairHistoryEndDate: '',
  SearchType_RepairHistory: -1,
  UserSearchType_RepairHistory: -1,
};

const getters = {
  getOpenTabIndexFromMachineCheckPage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromMachineCheckPage(state) {
    return state.managementMode;
  },
  getSearchTypeFromMachinePage(state) {
    return state.search_type;
  },
  getFilteredMachineListFromMachinePage(state) {
    return state.machineList;
  },
  getMachineCheckListFromMachinePage(state) {
    return state.machineCheckList;
  },
  getMachineListFromMachinePage(state) {
    return state.get_machineList;
  },
  getCheckListFromMachinePage(state) {
    return state.CheckList;
  },
  getCheckResultListFromMachinePage(state) {
    return state.checkResultList;
  },
  getFilteredCheckResultListFromMachinePage(state) {
    return state.filteredCheckResultList;
  },
  getRepairHistoryFromMachinePage(state) {
    return state.repairHistory;
  },
  getTotalRepairHistoryFromMachinePage(state) {
    return state.totalRepairHistory;
  },
  getHistoryListFromMachinePage(state) {
    return state.historyList;
  },
  getStartDateFromCheckResultPage(state) {
    return state.checkResultStartDate;
  },
  getEndDateFromCheckResultPage(state) {
    return state.checkResultEndDate;
  },
  getStartDateFromRepairHistoryPage(state) {
    return state.RepairHistoryStartDate;
  },
  getEndDateFromRepairHistoryPage(state) {
    return state.RepairHistoryEndDate;
  },
};
const mutations = {
  setOpenTabIndexToMachineCheckPage(state, idx) {
    state.openTabIndex = idx;
  },
  toggleManagementModeToMachineCheckPage(state) {
    state.managementMode = !state.managementMode;
  },
  setSearchTypeToMachinePage(state, payload) {
    state.search_type = payload;
  },
  setFilteredMachineListToMachinePage(state, payload) {
    state.machineList = payload;
  },
  setMachineListToMachinePage(state, payload) {
    state.get_machineList = payload;
  },
  setMachineCheckListToMachinePage(state, payload) {
    state.machineCheckList = payload;
  },
  setCheckListToMachinePage(state, payload) {
    state.CheckList = payload;
  },
  updateCheckListToMachinePage(state, payload) {
    state.CheckList[payload.index].inspection_result = payload.value;
  },
  setCheckResultListToMachinePage(state, payload) {
    state.checkResultList = payload;
  },
  setFilteredCheckResultListToMachinePage(state, payload) {
    const clone = state.checkResultList;
    if (payload.machine_id == null) {
      if (payload.user_id == null) {
        state.filteredCheckResultList = clone;
      } else {
        state.filteredCheckResultList = clone.filter(
          row => row.user_id == payload.user_id,
        );
      }
    } else {
      if (payload.user_id == null) {
        state.filteredCheckResultList = clone.filter(
          row => row.machine_id == payload.machine_id,
        );
      } else {
        state.filteredCheckResultList = clone.filter(
          row =>
            row.machine_id == payload.machine_id &&
            row.user_id == payload.user_id,
        );
      }
    }
    state.filteredCheckResultList.sort(
      (a, b) => a.inspection_date - b.inspection_date,
    );
  },
  setRepairHistoryToMachinePage(state, payload) {
    const clone = state.totalRepairHistory;
    if (payload.machine_id == null) {
      if (payload.user_id == null) {
        state.repairHistory = clone;
      } else {
        state.repairHistory = clone.filter(
          row => row.user_id == payload.user_id,
        );
      }
    } else {
      if (payload.user_id == null) {
        state.repairHistory = clone.filter(
          row => row.machine_id == payload.machine_id,
        );
      } else {
        state.repairHistory = clone.filter(
          row =>
            row.machine_id == payload.machine_id &&
            row.user_id == payload.user_id,
        );
      }
      state.repairHistory.sort((a, b) => a.repair_date - b.repair_date);
    }
  },
  setTotalRepairHistoryToMachinePage(state, payload) {
    state.totalRepairHistory = payload;
  },
  setHistoryListToMachinePage(state, payload) {
    state.historyList = payload;
  },
  setStartDateToCheckResultPage(state, payload) {
    state.checkResultStartDate = payload;
  },
  setEndDateToCheckResultPage(state, payload) {
    state.checkResultEndDate = payload;
  },
  setStartDateToRepairHistoryPage(state, payload) {
    state.RepairHistoryStartDate = payload;
  },
  setEndDateToRepairHistoryPage(state, payload) {
    state.RepairHistoryEndDate = payload;
  },
};

const actions = {
  FETCH_INSPECTION_MACHINE({ commit }, machines) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('/inspection')
        .then(response => {
          if (response.data.result == 'success') {
            for (let i = 0; i < machines.length; i++) {
              for (let j = 0; j < response.data.data_list.length; j++) {
                if (machines[i].id == response.data.data_list[j].machine_id) {
                  Object.assign(response.data.data_list[j], {
                    machine_location: machines[i].machine_location,
                    name: machines[i].name,
                  });
                }
              }
            }
            commit(
              'setMachineListToMachinePage',
              response.data.data_list.sort(
                (a, b) => a.machine_id - b.machine_id,
              ),
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UPDATE_INSPECTION_MACHINE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('/inspection', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_INSPECTION_MACHINE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('/inspection', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_INSPECTION_MACHINE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`/inspection/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_INSPECTION_DATA(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('/inspection-history', payload)
        .then(response => {
          console.log(response.data);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_INSPECTION_DATA({ commit }, machines) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('/inspection')
        .then(response => {
          if (response.data.result == 'success') {
            for (let i = 0; i < machines.length; i++) {
              for (let j = 0; j < response.data.data_list.length; j++) {
                if (machines[i].id == response.data.data_list[j].machine_id) {
                  Object.assign(response.data.data_list[j], {
                    machine_location: machines[i].machine_location,
                    name: machines[i].name,
                    check_items: machines[i].check_items,
                    pass_criteria: machines[i].pass_criteria,
                  });
                }
              }
            }
            commit('setMachineCheckListToMachinePage', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_CHECK_RESULT_DATA({ state, commit }) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(state.checkResultEndDate);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: state.checkResultStartDate,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `/inspection-history/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            for (let i = 0; i < state.machineList.length; i++) {
              for (let j = 0; j < response.data.data_list.length; j++) {
                if (
                  state.machineList[i].id ==
                  response.data.data_list[j].inspection_id
                ) {
                  Object.assign(response.data.data_list[j], {
                    machine_location: state.machineList[i].machine_location,
                    name: state.machineList[i].name,
                    check_items: state.machineList[i].check_items,
                    machine_id: state.machineList[i].machine_id,
                    pass_criteria: state.machineList[i].pass_criteria,
                  });
                }
              }
            }
            commit('setCheckResultListToMachinePage', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 수리이력
  FETCH_REPAIR_HISTORY_DATA({ state, commit }) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(state.RepairHistoryEndDate);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: state.RepairHistoryStartDate,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `/repair-history/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            for (let i = 0; i < state.machineList.length; i++) {
              for (let j = 0; j < response.data.data_list.length; j++) {
                if (
                  state.machineList[i].id ==
                  response.data.data_list[j].inspection_id
                ) {
                  Object.assign(response.data.data_list[j], {
                    machine_location: state.machineList[i].machine_location,
                    name: state.machineList[i].name,
                    machine_id: state.machineList[i].machine_id,
                    check_items: state.machineList[i].check_items,
                  });
                }
              }
            }
            commit(
              'setTotalRepairHistoryToMachinePage',
              response.data.data_list.sort(
                (a, b) => a.repair_date - b.repair_date,
              ),
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_REPAIR_HISTORY_DATA(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('/repair-history', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_REPAIR_HISTORY_DATA(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('/repair-history', payload)
        .then(response => {
          console.log(response.data);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_REPAIR_HISTORY_DATA(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`/repair-history/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
