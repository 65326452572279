import {
  testapisGet,
  testapisPut,
  //   testapisDelete,
  testapisPost,
  //   testapisGetWithParam,
} from '@/api/forTesting';

const actions = {
  async TestPutApi(context, payload) {
    // get
    console.log(payload);
    const response = await testapisPut('menu/sub', payload);

    return response;
  },
  async TestGetApi() {
    // get
    const response = await testapisGet('menu/all');

    return response;
  },
  async TestPostApi(context, payload) {
    // get
    console.log(payload);
    const response = await testapisPost('base/location/store', payload);

    return response;
  },
  async TestDeleteApi(context, payload) {
    // get
    console.log(payload);
    const response = await testapisGet('menu/all');

    return response;
  },
};

export default {
  actions,
};
