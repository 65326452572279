import { companyInstance } from '@/api/index.js';

const state = {
  company_logo: null,
};

const getters = {
  getCompanyLogo(state) {
    return state.company_logo;
  },
};

const mutations = {
  setCompanyLogo(state, payload) {
    state.company_logo = 'data:image/png;base64,' + payload;
  },
};

const actions = {
  async FETCH_LOGO_IMAGE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('company-logo')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setCompanyLogo', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_LOGO_IMAGE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('company-logo', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async DELETE_LOGO_IMAGE() {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete('company-logo')
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
