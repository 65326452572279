const state = {
  openTabIndex: 0,
  managementMode: false,
};

const getters = {
  getOpenTabIndexFromGumsanManagementPage(state) {
    return state.openTabIndex;
  },
};

const mutations = {
  setOpenTabIndexToGumsanManagementPage(state, index) {
    state.openTabIndex = index;
  },
};

export default {
  state,
  getters,
  mutations,
};
