import { companyInstance } from '@/api/index.js';

const state = {
  user_action_type: [],
};
const getters = {
  getUserActionType(state) {
    return state.user_action_type;
  },
  getUserActionId_ACCESS(state) {
    return state.user_action_type.find(x => x.name == 'access').id || -1;
  },
};
const mutations = {
  setUserActionType(state, payload) {
    state.user_action_type = payload;
  },
};
const actions = {
  async FETCH_USER_ACTION_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('user/action-type')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setUserActionType', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
