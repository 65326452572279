import { companyInstance } from '@/api/index';

const state = {
  search_type_id: 0,
  start_date: null,
  end_date: null,
  sales: [],
  managementData: null,
  selectedIndex: -1,
};
const getters = {
  getSearchTypeIdFromPlan(state) {
    return state.search_type_id;
  },
  getStartDateFromPlan(state) {
    return state.start_date;
  },
  getEndDateFromPlan(state) {
    return state.end_date;
  },
  getSalesFromPlan(state) {
    return state.sales;
  },
  getManagementDataFromPlan(state) {
    return state.managementData;
  },
  getSelectedIndexFromPlan(state) {
    return state.selectedIndex;
  },
};
const mutations = {
  setSearchTypeIdToPlan(state, payload) {
    state.search_type_id = payload;
  },
  setStartDateToPlan(state, payload) {
    state.start_date = payload;
  },
  setEndDateToPlan(state, payload) {
    state.end_date = payload;
  },
  setSalesToPlan(state, payload) {
    state.sales = payload;
  },
  setManagementDataToPlan(state, payload) {
    state.managementData = payload;
  },
  setSelectedIndexToPlan(state, payload) {
    state.selectedIndex = payload;
  },
};
const actions = {
  FETCH_SALES_TO_PLAN({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = `plan/sales/info/start_date=${payload.start_date}&end_date=${payload.end_date}`;
      url += `&completed=false`;

      companyInstance
        .get(url)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setSalesToPlan', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_SALES_TO_PLAN_SEARCH_BY_INPUT_DATE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = `plan/sales/info/by-input-date/start_date=${payload.start_date}&end_date=${payload.end_date}`;
      url += `&completed=false`;

      companyInstance
        .get(url)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setSalesToPlan', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_SALES_PRODUCT_PLAN(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/sales-product/plan', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
