import { companyInstance } from '@/api/index.js';

const state = {
  lot_types: [],
};
const getters = {
  getLotType(state) {
    return state.lot_types.sort((a, b) => a.id - b.id);
  },
  getLotTypeNotSort(state) {
    return state.lot_types;
  },
  getWaitLotType(state) {
    return state.lot_types
      .filter(x => x.detail.includes('order'))
      .sort((a, b) => a.id - b.id);
  },
  getProcessingLotType(state) {
    return state.lot_types
      .filter(x => x.detail.includes('non order'))
      .sort((a, b) => a.id - b.id);
  },
  getDoneLotType(state) {
    return state.lot_types
      .filter(x => x.detail.includes('return'))
      .sort((a, b) => a.id - b.id);
  },
};
const mutations = {
  setLotType(state, payload) {
    state.lot_types = payload;
  },
  getLotTypeFromId(state, id) {
    return state.lot_types.find(x => x.id == id);
  },
};

const actions = {
  FETCH_LOT_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/lot-type')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setLotType', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
