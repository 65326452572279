import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';
import clonedeep from 'lodash/cloneDeep';

const state = {
  product_control: [],
  not_null_product_control: [],
  product_control_day_list: [],
  product_remain_control: [],
  product_total_stock: [],
  product_occupied_stock: [],
};

const getters = {
  getProductControl(state) {
    return state.product_control;
  },
  getProductRemainControl(state) {
    return state.product_remain_control;
  },
  getNotNullProductControl(state) {
    return state.not_null_product_control;
  },
  getProductControlDayList(state) {
    return state.product_control_day_list;
  },
  getProductTotalStock(state) {
    return state.product_total_stock;
  },
  getProductOccupiedStock(state) {
    return state.product_occupied_stock;
  },
};
const mutations = {
  setProductControl(state, payload) {
    payload = payload.map(x => {
      x.is_modify = false;
      x.quantity_temp = clonedeep(x.quantity);
      return x;
    });
    state.product_control = payload;
  },
  setProductRemainControl(state, payload) {
    state.product_remain_control = payload;
  },
  setNotNullProductControl(state, payload) {
    state.not_null_product_control = payload;
  },
  setProductControlDayList(state, payload) {
    state.product_control_day_list = payload;
  },
  setProductTotalStock(state, payload) {
    state.product_total_stock = payload;
  },
  setProductOccupiedStock(state, payload) {
    state.product_occupied_stock = payload;
  },
};
const actions = {
  async INSERT_PRODUCT_CONTROL_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('mag/product/control/all', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async INSERT_PRODUCT_CONTROL_ALL_LIST(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('mag/product/control/all/list', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async FETCH_PRODUCT_CONTROL_FROM_LOT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/product/control/lot/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_PRODUCT_CONTROL_FROM_LOT_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/product/control/lot/all/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_PRODUCT_CONTROL({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/product/control`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setProductControl', response.data.data_list);
            console.log(response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_PRODUCT_CONTROL_DAY_LIST({ commit }, payload) {
    let endDate = new Date(payload.end_date);
    endDate.setDate(endDate.getDate() + 1);
    endDate = formatDateNoHours(endDate);
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/product/control/start_date=${payload.start_date}&end_date=${endDate}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setProductControlDayList', response.data.data_list);
            console.log(response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_PRODUCT_TOTAL_STOCK({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/product/stock')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setProductTotalStock', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async FETCH_PRODUCT_REMAIN_CONTROL({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/product/control/remain')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setProductRemainControl', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async FETCH_NOT_NULL_PRODUCT_CONTROL({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/product/control/notnull')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setNotNullProductControl', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async FETCH_PRODUCT_OCCUPIED_STOCK({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/product/occupied-stock')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setProductOccupiedStock', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async DELETE_PRODUCT_CONTROL_LIST(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`mag/product/control/list`, { data: payload })
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async UPDATE_PRODUCT_CONTROL_STOCK(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('mag/product/control/stock', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
