import { companyInstance } from '@/api/index';
const state = {
  materials: [],
  selectedIndex: -1,
  check_only_group_material: false,
  managementData: {
    id: -1,
    name: '',
    detail: '',
    standard: '',
    inspection: null,
    stock_size: 0,
    tax: null,
    barcode: '',
    material_group_yn: false,
    resource_type_id: null,
    incoming_unit_id: null,
    using_unit_id: null,
    default_store_id: null,
    order_unit: 0,
    company_list: [],
  },
  del_manage_array: [],
  newData: {
    name: '',
    detail: '',
    standard: '',
    inspection: null,
    stock_size: 0,
    tax: null,
    barcode: '',
    material_group_yn: false,
    resource_type_id: null,
    incoming_unit_id: null,
    using_unit_id: null,
    default_store_id: null,
    order_unit: 0,
  },
  newCompanys: [],
  groupMaterials: [],
  del_new_array: [],
};

const getters = {
  getMaterial(state) {
    return state.materials.sort((a, b) => a.id - b.id);
  },
  getVisibleMaterial(state) {
    return state.materials.filter(x => x.visible).sort((a, b) => a.id - b.id);
  },
  getDeletedMaterial(state) {
    return state.materials.filter(x => !x.visible).sort((a, b) => a.id - b.id);
  },
  getCheckOnlyGroupMaterial(state) {
    return state.check_only_group_material;
  },
  getSelectedIndexFromMaterial(state) {
    return state.selectedIndex;
  },
  getManagementDataFromMaterial(state) {
    return state.managementData;
  },
  getNewDataFromMaterial(state) {
    return state.newData;
  },
  getNewCompanyFromMaterial(state) {
    return state.newCompanys;
  },
  getGroupMaterialFromMaterial(state) {
    return state.groupMaterials;
  },
};

const mutations = {
  setMaterial(state, payload) {
    payload.forEach(el => {
      if (el.detail == null) {
        el.detail = '';
      }
    });

    state.materials = payload;
  },
  setCheckOnlyGroupMaterial(state, payload) {
    state.check_only_group_material = payload;
  },
  setSelectedIndexToMaterial(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToMaterial(state, payload) {
    state.managementData = payload;
  },
  setNewDataToMaterial(state, payload) {
    state.newData = payload;
  },
  setManagementMaterialTypeId(state, type_id) {
    state.managementData.resource_type_id = type_id;
  },
  setNewMaterialTypeId(state, type_id) {
    state.newData.resource_type_id = type_id;
  },
  setManagementMaterialTax(state, tax) {
    state.managementData.tax = tax;
  },
  setNewMaterialTax(state, tax) {
    state.newData.tax = tax;
  },
  setManagementMaterialUnitsIncoming(state, id) {
    state.managementData.incoming_unit_id = id;
  },
  setManagementMaterialUnitsUsing(state, id) {
    state.managementData.using_unit_id = id;
  },
  setNewMaterialUnitsIncoming(state, id) {
    state.newData.incoming_unit_id = id;
  },
  setNewMaterialUnitsUsing(state, id) {
    state.newData.using_unit_id = id;
  },
  setManagementMaterialInspection(state, id) {
    state.managementData.inspection = id;
  },
  setNewMaterialInspection(state, id) {
    state.newData.inspection = id;
  },
  setManagementMaterialDefaultStore(state, id) {
    state.managementData.default_store_id = id;
  },
  setNewMaterialDefaultStore(state, id) {
    state.newData.default_store_id = id;
  },
  setNewMaterialCompany(state, payload) {
    state.newCompanys = payload;
  },
  setGroupMaterialToMaterial(state, payload) {
    state.groupMaterials = payload;
  },
  setNewMaterialGroupYnToMaterial(state, payload) {
    console.log(payload);
    state.newData.material_group_yn = payload;
  },
  setManagementMaterialGroupYnToMaterial(state, payload) {
    state.managementData.material_group_yn = payload;
  },
  setManagementMaterialmaterialGroupList(state, payload) {
    state.managementData.material_group_list = payload;
  },
};

const actions = {
  FETCH_MATERIAL({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/material/info')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setMaterial', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_MATERIAL_WITH_COMPANY({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/material/with/company')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setMaterial', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_MATERIAL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/material/info', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_MATERIAL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/material/info', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_MATERIAL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/material/info/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  RESTORE_MATERIAL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/material/info/restore/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
