import { companyInstance } from '@/api';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  startDate: null,
  endDate: null,
  searchText: '',
  PrintCompleteList: [],
};
const getters = {
  getStartDateFromPrintComplete(state) {
    return state.startDate;
  },
  getEndDateFromPrintComplete(state) {
    return state.endDate;
  },
  getSearchTextFromPrintComplete(state) {
    return state.searchText;
  },
  getPrintCompleteListFromPrintComplete(state) {
    return state.PrintCompleteList.filter(x => x.product_in_out_type_id == 1);
  },
};
const mutations = {
  setStartDateToPrintComplete(state, payload) {
    state.startDate = payload;
  },
  setEndDateToPrintComplete(state, payload) {
    state.endDate = payload;
  },
  setSearchTextToPrintComplete(state, payload) {
    state.searchText = payload;
  },
  setPrintCompleteListToPrintComplete(state, payload) {
    state.PrintCompleteList = payload;
  },
  setProductManagementStoreIdToPrintComplete(state, payload) {
    state.managementData.store_id = payload;
  },
  setProductManagementLocationIdToPrintComplete(state, payload) {
    state.managementData.location_id = payload;
  },
};
const actions = {
  FETCH_PRINT_COMPLETE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `mag/product/in-out/barcode/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            response.data.data_list.forEach(el => {
              el.check_print = false;
            });
            commit(
              'setPrintCompleteListToPrintComplete',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
