const state = {
  openTabIndex: 0,
  managementMode: false,
};

const getters = {
  getOpenTabIndexFromMaterialStatusPage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromMaterialStatusPage(state) {
    return state.managementMode;
  },
};
const mutations = {
  setOpenTabIndexToMaterialStatusPage(state, idx) {
    state.openTabIndex = idx;
  },
  setManagementModeToMaterialStatusPage(state, payload) {
    state.managementMode = payload;
  },
};

export default { state, getters, mutations };
