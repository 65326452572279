import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  payments: [],
  selectedIndex: -1,
  start_date: null,
  end_date: null,
  search_type: null,

  managementData: {
    id: -1,
    company_id: -1,
    payment_type_id: null,
    total_value: 0,
    input_date: '',
  },
  cloneData: {
    id: -1,
    company_id: -1,
    payment_type_id: null,
    total_value: 0,
    input_date: '',
  },

  newData: {
    company_id: -1,
    payment_type_id: null,
    total_value: 0,
    input_date: '',
  },
};

const getters = {
  getPayments(state) {
    return state.payments
      .sort((a, b) => b.id - a.id)
      .sort((a, b) => new Date(b.input_date) - new Date(a.input_date));
  },
  getSelectedIndexFromPayment(state) {
    return state.selectedIndex;
  },
  getDateFromPayment(state) {
    return {
      start: formatDateNoHours(state.start_date),
      end: formatDateNoHours(state.end_date),
    };
  },
  getStartDateFromPayment(state) {
    return state.start_date;
  },
  getEndDateFromPayment(state) {
    return state.end_date;
  },
  getManagementDataFromPayment(state) {
    return state.managementData;
  },
  getCloneDataFromPayment(state) {
    return state.cloneData;
  },
  getNewDataFromPayment(state) {
    return state.newData;
  },
  getSearchTypeFromPayment(state) {
    return state.search_type;
  },
};

const mutations = {
  setPayments(state, payload) {
    state.payments = payload;
  },
  setSelectedIndexToPayment(state, index) {
    state.selectedIndex = index;
  },

  setStartDateToPayment(state, payload) {
    state.start_date = payload;
  },
  setEndDateToPayment(state, payload) {
    state.end_date = payload;
  },
  setCloneDataToPayment(state, payload) {
    state.cloneData = payload;
  },
  // setManagementPaymentTypeId(state, type_id){
  //   state.managementData.payment_type_id = type_id
  // },
  setManagementDataToPayment(state, payload) {
    state.managementData = payload;
  },
  setManagementDataPaymentTypeIdToPayment(state, id) {
    state.managementData.payment_type_id = id;
  },

  setNewDataToPayment(state, payload) {
    state.newData = payload;
  },
  setNewDataPaymentTypeIdToPayment(state, id) {
    state.newData.payment_type_id = id;
  },
  setSearchTypeToPayment(state, payload) {
    state.search_type = payload;
  },
};

const actions = {
  FETCH_PAYMENT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`payment/start=${payload.start}&end=${payload.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setPayments', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_PAYMENTS(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('payment', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_PAYMENTS(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('payment', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DELETE_PAYMENTS(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`payment/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
