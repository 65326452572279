const state = {
  openTabIndex: 0,
  managementMode: false,
  search_type: null,
};

const getters = {
  getOpenTabIndexFromProductPage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromProductPage(state) {
    return state.managementMode;
  },
  getSearchTypeFromProductPage(state) {
    return state.search_type;
  },
};
const mutations = {
  setOpenTabIndexToProductPage(state, idx) {
    state.openTabIndex = idx;
  },
  toggleManagementModeToProductPage(state) {
    state.managementMode = !state.managementMode;
  },
  setSearchTypeToProductPage(state, payload) {
    state.search_type = payload;
  },
};

export default { state, getters, mutations };
