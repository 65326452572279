const state = {
  openTabIndex: 0,
  managementMode: false,
  startDate: '',
  endDate: '',
  collection_amount_type: null,
  store: [],
};

const getters = {
  getOpenTabIndexFromOutputManagementPage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromOutputManagementPage(state) {
    return state.managementMode;
  },
  getStartDateFromProductOutputPage(state) {
    return state.startDate;
  },
  getEndDateFromProductOutputPage(state) {
    return state.endDate;
  },
  getCollectionAmountTypeFromProductOutputPage(state) {
    return state.collection_amount_type;
  },
};
const mutations = {
  setOpenTabIndexToOutputManagementPage(state, idx) {
    state.openTabIndex = idx;
  },
  toggleManagementModeToOutputManagementPage(state) {
    state.managementMode = !state.managementMode;
  },
  setStartDateToProductOutputPage(state, payload) {
    state.startDate = payload;
  },
  setEndDateToProductOutputPage(state, payload) {
    state.endDate = payload;
  },
  setCollectionAmountTypeToProductOutputPage(state, payload) {
    state.collection_amount_type = payload;
  },
  setStoreToOutputPage(state, payload) {
    state.store = payload;
  },
};

export default { state, getters, mutations };
