import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  check_lists: [],
  start_date: null,
  end_date: null,
  managementData: null,
  modifyChkData: null,
  selectedIndex: -1,
};
const getters = {
  getCheckListFromMaterialVerification(state) {
    return state.check_lists;
  },
  getStartDateFromMaterialVerification(state) {
    return state.start_date;
  },
  getEndDateFromMaterialVerification(state) {
    return state.end_date;
  },
  getDateFromMaterialVerification(state) {
    return {
      start: formatDateNoHours(state.start_date),
      end: formatDateNoHours(state.end_date),
    };
  },
  getManagementDataFromMaterialVerification(state) {
    return state.managementData;
  },
  getModifyChkDataFromMaterialVerification(state) {
    return state.modifyChkData;
  },
  getSelectedIndexFromMaterialVerification(state) {
    return state.selectedIndex;
  },
};
const mutations = {
  setCheckListToMaterialVerification(state, payload) {
    state.check_lists = payload;
  },
  setStartDateToMaterialVerification(state, payload) {
    state.start_date = payload;
  },
  setEndDateToMaterialVerification(state, payload) {
    state.end_date = payload;
  },
  setManagementDataToMaterialVerification(state, payload) {
    state.managementData = payload;
  },
  setModifyChkDataToMaterialVerification(state, payload) {
    state.modifyChkData = payload;
  },
  setSelectedIndexToMaterialVerification(state, payload) {
    state.selectedIndex = payload;
  },
};
const actions = {
  FETCH_MATERIAL_VERIFICATION_DEFECT({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/material/verification/defect/start_day=${newDate.start}&end_day=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setCheckListToMaterialVerification',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_MATERIAL_DEFECT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/material/defect/all/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            response.data.data_list.forEach(el => {
              el.check_quantity = el.pass_quantity + el.unpass_quantity;
            });
            commit(
              'setManagementDataToMaterialVerification',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_MATERIAL_QUALITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('mag/material/quality', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DELETE_MATERIAL_QUALITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`mag/material/quality/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UPDATE_MATERIAL_QUALITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('mag/material/quality', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
