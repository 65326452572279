import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  process_reports: [],
  startDate: null,
  endDate: null,
  checkOnlyFail: true,
  radio: {
    all: true,
    include: false,
    notInclude: false,
  },
  filterResourceTypeArray: null,
  filterProcessArray: [],

  searchFilterResourceType: [],
  searchFilterProcess: [],
  searchRadio: {
    all: true,
    include: false,
    notInclude: false,
  },
  searchStartDate: null,
  searchEndDate: null,
};

const getters = {
  getProcessReportFromReportProcess(state) {
    return state.process_reports;
  },
  getCheckOnlyFailFromReportProcess(state) {
    return state.checkOnlyFail;
  },
  getStartDateFromReportProcess(state) {
    return state.startDate;
  },
  getEndDateFromReportProcess(state) {
    return state.endDate;
  },
  getDateFromReportProcess(state) {
    return {
      start: formatDateNoHours(state.startDate),
      end: formatDateNoHours(state.endDate),
    };
  },
  getRadioFromReportProcess(state) {
    return state.radio;
  },
  getFilterResourceTypeArrayFromReportProcess(state) {
    return state.filterResourceTypeArray;
  },
  getFilterProcessArrayFromReportProcess(state) {
    return state.filterProcessArray;
  },
  getSearchFilterResourceTypeFromReportProcess(state) {
    return state.searchFilterResourceType;
  },
  getSearchFilterProcessFromReportProcess(state) {
    return state.searchFilterProcess;
  },
  getSearchRadioFromReportProcess(state) {
    return state.searchRadio;
  },
  getSearchStartDateFromReportProcess(state) {
    return state.searchStartDate;
  },
  getSearchEndDateFromReportProcess(state) {
    return state.searchEndDate;
  },
};
const mutations = {
  setProcessReportToReportProcess(state, payload) {
    state.process_reports = payload;
  },
  setCheckOnlyFailToReportProcess(state, payload) {
    state.checkOnlyFail = payload;
  },
  setStartDateToReportProcess(state, payload) {
    state.startDate = payload;
  },
  setEndDateToReportProcess(state, payload) {
    state.endDate = payload;
  },
  setRadioToReportProcess(state, payload) {
    state.radio = payload;
  },
  setFilterResourceTypeArrayToReportProcess(state, payload) {
    state.filterResourceTypeArray = payload;
  },
  setFilterProcessArrayToReportProcess(state, payload) {
    state.filterProcessArray = payload;
  },
  setSearchFilterResourceTypeToReportProcess(state, payload) {
    state.searchFilterResourceType = payload;
  },
  setSearchFilterProcessToReportProcess(state, payload) {
    state.searchFilterProcess = payload;
  },
  setSearchRadioToReportProcess(state, payload) {
    state.searchRadio = payload;
  },
  setSearchStartDateToReportProcess(state, payload) {
    state.searchStartDate = payload;
  },
  setSearchEndDateToReportProcess(state, payload) {
    state.searchEndDate = payload;
  },
};
const actions = {
  FETCH_PROCESS_QUALITY_REPORT_TO_REPORT_PROCESS({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/seq/quality-report/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setProcessReportToReportProcess', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
