import { companyInstance } from '@/api/index';
const state = {
  material_groups: [],
};

const getters = {
  getMaterialGroup(state) {
    return state.material_groups;
  },
};

const mutations = {
  setMaterialGroup(state, payload) {
    state.material_groups = payload;
  },
};

const actions = {
  FETCH_MATERIAL_GROUP({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .get('material-group')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setMaterialGroup', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_MATERIAL_GROUP(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .post('material-group', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_MATERIAL_GROUP_LIST(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .post('material-group/list', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_MATERIAL_GROUP(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .delete(`material-group/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_MATERIAL_GROUP_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .delete(`material-group/all/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
