import { companyInstance } from '@/api/index';

const state = {
  check_lists: [],
  target_lot: null,
  search_text: '',
  managementData: null,
  modifyChkData: null,
  selectedIndex: -1,
};
const getters = {
  getCheckListFromSeqVerification(state) {
    return state.check_lists;
  },
  getTargetLotFromSeqVerification(state) {
    return state.target_lot;
  },
  getManagementDataFromSeqVerification(state) {
    return state.managementData;
  },
  getModifyChkDataFromSeqVerification(state) {
    return state.modifyChkData;
  },
  getSelectedIndexFromSeqVerification(state) {
    return state.selectedIndex;
  },
  getSearchTextFromSeqVerification(state) {
    return state.search_text;
  },
};
const mutations = {
  setCheckListToSeqVerification(state, payload) {
    state.check_lists = payload;
  },
  setTargetLotToSeqVerification(state, payload) {
    state.target_lot = payload;
  },
  setManagementDataToSeqVerification(state, payload) {
    state.managementData = payload;
  },
  setModifyChkDataToSeqVerification(state, payload) {
    state.modifyChkData = payload;
  },
  setSelectedIndexToSeqVerification(state, payload) {
    state.selectedIndex = payload;
  },
  setSearchTextToSeqVerification(state, payload) {
    state.search_text = payload;
  },
};
const actions = {
  FETCH_SEQ_VERIFICATION_DEFECT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/seq/verification/defect/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setCheckListToSeqVerification', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_SEQ_DEFECT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/seq/defect/all/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            response.data.data_list.forEach(el => {
              el.check_quantity = el.pass_quantity + el.unpass_quantity;
            });
            commit(
              'setManagementDataToSeqVerification',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_SEQ_QUALITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('mag/seq/quality', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DELETE_SEQ_QUALITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`mag/seq/quality/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UPDATE_SEQ_QUALITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('mag/seq/quality', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
