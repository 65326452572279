import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';
const state = {
  target_quantity: [],
  start_date: null,
  end_date: null,
  selectedIndex: -1,
  managementData: {
    id: null,
    work_date: null,
    product_id: null,
    quantity: 0,
    user_id: null,
    comment: '',
  },
  newData: {
    work_date: formatDateNoHours(new Date()),
    product_id: null,
    quantity: 0,
    user_id: null,
    comment: '',
  },
};

const getters = {
  getTargetQuantity(state) {
    return state.target_quantity;
  },
  getSelectedIndexFromQuantity(state) {
    return state.selectedIndex;
  },
  getManagementDataFromQuantity(state) {
    return state.managementData;
  },
  getNewDataFromQuantity(state) {
    return state.newData;
  },
  getStartDateFromQuantity(state) {
    return state.start_date;
  },
  getEndDateFromQuantity(state) {
    return state.end_date;
  },
};

const mutations = {
  setTargetQuantity(state, payload) {
    state.target_quantity = payload;
  },
  setSelectedIndexToQuantity(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToQuantity(state, payload) {
    state.managementData = payload;
  },
  setNewDataToQuantity(state, payload) {
    state.newData = payload;
  },
  setStartDateToQuantity(state, payload) {
    state.start_date = payload;
  },
  setEndDateToQuantity(state, payload) {
    state.end_date = payload;
  },
};

const actions = {
  FETCH_TARGET_QUANTITY({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plan/target-quantity/start=${payload.start}&end=${payload.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setTargetQuantity', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);

          reject(error);
        });
    });
  },
  FETCH_TARGET_QUANTITY_NOW({ commit }) {
    return new Promise((resolve, reject) => {
      let startDate = new Date();
      let endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 1);

      const newDate = {
        start: formatDateNoHours(startDate),
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          // `plan/target-quantity/start_date=${newDate.start}&end_date=${newDate.end}`,
          `plan/target-quantity/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setTargetQuantity', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);

          reject(error);
        });
    });
  },
  INSERT_TARGET_QUANTITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('plan/target-quantity', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_TARGET_QUANTITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/target-quantity', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_TARGET_QUANTITY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`plan/target-quantity/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
