import store from '@/store/index.js';
import clonedeep from 'lodash/cloneDeep';
import { makeNumber } from './filters';

export const groupArrayObject = function(xs, key) {
  const obj = xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

  let arr = [];
  for (let k in obj) {
    arr.push({
      group_key: k,
      element: obj[k],
    });
  }
  return arr;
};

export const getExpireDate = function(dateTemp, year_add, month_add, day_add) {
  let date = dateTemp;
  // console.log('date', date);
  if (typeof date != Date) {
    date = new Date(date);
  }

  var year = date.getFullYear();
  var month = date.getMonth();
  var day = date.getDate();
  // console.log('year_add', year_add);
  // console.log('month_add', month_add);
  // console.log('day_add', day_add);

  return new Date(year + year_add, month + month_add, day + day_add);
};

export const groupByMenuId = function(xs, key) {
  const obj = xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
  let arr = [];
  for (let k in obj) {
    arr.push({
      menu_group_id: k,
      count: obj[k].length,
    });
  }
  return arr;
};

export const clone = function(obj) {
  if (obj === null || typeof obj !== 'object') return obj;

  var copy = obj.constructor();

  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) {
      copy[attr] = obj[attr];
    }
  }
  return copy;
};

export const yyyymmdd = function(date) {
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return [
    date.getFullYear(),
    '-' + (mm > 9 ? mm : '0' + mm),
    '-' + (dd > 9 ? dd : '0' + dd),
  ].join('');
};

export const yyyy년MM월dd일HH시mm분ss초 = function(date) {
  var MM = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();
  var hh = date.getHours();
  var mm = date.getMinutes();
  var ss = date.getSeconds();

  return [
    date.getFullYear(),
    '년 ' + (MM > 9 ? MM : '0' + MM),
    '월 ' + (dd > 9 ? dd : '0' + dd),
    '일 (' + (hh > 9 ? hh : '0' + hh),
    ':' + (mm > 9 ? mm : '0' + mm),
    ':' + (ss > 9 ? ss : '0' + ss),
    ')',
  ].join('');
};

export const yyyy년MM월dd일 = function(date2) {
  const date = new Date(date2);
  var MM = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return [
    date.getFullYear(),
    '년 ' + (MM > 9 ? MM : '0' + MM),
    '월 ' + (dd > 9 ? dd : '0' + dd),
    '일',
  ].join('');
};

export const HH시mm분 = function(date2) {
  const date = new Date(date2);
  var hh = date.getHours();
  var mm = date.getMinutes();

  return [hh > 9 ? hh : '0' + hh, ':' + (mm > 9 ? mm : '0' + mm)].join('');
};

export const X요일 = function(date2) {
  const date = new Date(date2);
  const week = [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일',
  ];
  let dayOfWeek = week[date.getDay()];

  return dayOfWeek;
};

export const yyyyMMddHHmmss = function(date) {
  var MM = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();
  var hh = date.getHours();
  var mm = date.getMinutes();
  var ss = date.getSeconds();

  return [
    date.getFullYear(),
    '-' + (MM > 9 ? MM : '0' + MM),
    '-' + (dd > 9 ? dd : '0' + dd),
    ' (' + (hh > 9 ? hh : '0' + hh),
    ':' + (mm > 9 ? mm : '0' + mm),
    ':' + (ss > 9 ? ss : '0' + ss),
    ')',
  ].join('');
};

export const getPageTitle = function(path) {
  // console.log(store.getters.getMenuItems);
  const topMenus = clonedeep(store.getters.getMenuItems);
  const subMenus = topMenus.map(x => x.sub_menu).reduce((a, b) => a.concat(b));
  const foundPage = subMenus.find(x => path == x.sub_detail);

  if (foundPage == undefined) {
    if (path == '/patch-note') {
      return '패치노트관리';
    } else return '';
  } else {
    return foundPage.name;
  }
};

export const makeKoreanNumber = function(num) {
  if (typeof num != Number) {
    num = makeNumber(num);
  }

  if (num == 0) {
    return '영';
  } else {
    const 자릿수 = ['', '십', '백', '천', '만', '억', '조', '경', '해'];
    const 천단위자릿수 = ['', '만', '억', '조', '경', '해'];
    const 숫자 = ['일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];

    const indexTemp =
      Math.floor(String(num).length % 4) != 0
        ? Math.floor(String(num).length / 4)
        : Math.floor(String(num).length / 4) - 1;

    let arrTemp = [];
    const strArray = String(num)
      .split('')
      .reverse();
    for (let index = 0; index < indexTemp + 1; index++) {
      arrTemp.push(strArray.splice(0, 4));
    }
    let resultArr = [];
    arrTemp.forEach((x, index1) => {
      let str = '';
      str += 천단위자릿수[index1];
      x.map(z => {
        z = this.$makeNumber(z);
        return z;
      }).forEach((y, index2) => {
        if (y == 0) {
          str += '';
        } else {
          str += 자릿수[index2] + 숫자[y - 1];
        }
      });
      resultArr.push(str);
    });
    resultArr = resultArr
      .map(x => {
        let arr = x.split('');
        arr = arr.reverse();
        x = arr;
        return x;
      })
      .reverse();
    resultArr = resultArr.reduce((a, b) => a.concat(b)).reduce((a, b) => a + b);
    console.log(resultArr);
    return resultArr;
  }
};
