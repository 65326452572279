import { companyInstance } from '@/api/index.js';

const state = {
  material_in_out_types: [],
};
const getters = {
  getMaterialInOutType(state) {
    return state.material_in_out_types.sort((a, b) => a.id - b.id);
  },
  getMaterialInOutTypeNotSort(state) {
    return state.material_in_out_types;
  },
  getBuyInType(state) {
    return state.material_in_out_types.filter(x => x.detail.includes('buy in'));
  },
  getUsingOutType(state) {
    return state.material_in_out_types.filter(x =>
      x.detail.includes('using out'),
    );
  },
  getReturnOutType(state) {
    return state.material_in_out_types.filter(x =>
      x.detail.includes('return out'),
    );
  },
  getReturnTypes(state) {
    return state.material_in_out_types.filter(x =>
      ['return out', 'discard out'].includes(x.detail),
    );
  },
  getReturnTypesForSelectric(state) {
    const types = state.material_in_out_types
      .filter(x => ['return out', 'discard out'].includes(x.detail))
      .sort((a, b) => a.id - b.id);

    let options = [];

    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getReturnOutTypeId(state) {
    return state.material_in_out_types.find(x => x.detail == 'return out') !=
      undefined
      ? state.material_in_out_types.find(x => x.detail == 'return out').id
      : null;
  },
  getDiscardOutTypeId(state) {
    return state.material_in_out_types.find(x => x.detail == 'discard out') !=
      undefined
      ? state.material_in_out_types.find(x => x.detail == 'discard out').id
      : null;
  },
};
const mutations = {
  setMaterialInOutType(state, payload) {
    state.material_in_out_types = payload;
  },
};

const actions = {
  FETCH_MATERIAL_IN_OUT_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/material/in-out-type')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setMaterialInOutType', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
