import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      modal_title: 'getModalTitle',
      modal_content: 'getModalContent',
      isModalOpen: 'getIsModalOpen',
    }),
  },
  methods: {
    ...mapMutations(['OpenModal', 'CloseModal']),
    openOneButtonModal(str_title, str_content) {
      this.OpenModal({
        title: str_title,
        content: str_content,
      });
    },
  },
};
