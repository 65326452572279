import { companyInstance } from '@/api/index.js';
import clonedeep from 'lodash/cloneDeep';
const state = {
  resource_types: [],
};
const getters = {
  getResourceType(state) {
    return state.resource_types.sort((a, b) => a.id - b.id);
  },
  getResourceTypeNotSort(state) {
    return state.resource_types;
  },
  getMaterialType(state) {
    return state.resource_types
      .filter(x => x.detail.includes('material'))
      .sort((a, b) => a.id - b.id);
  },
  getProductType(state) {
    return state.resource_types
      .filter(x => x.detail.includes('product'))
      .sort((a, b) => a.id - b.id);
  },
  getResourceTypeForSelectric(state) {
    let options = [];
    const types = clonedeep(state.resource_types).sort((a, b) => a.id - b.id);
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getMaterialTypeForSelectric(state) {
    let options = [];
    const types = clonedeep(state.resource_types).filter(x =>
      x.detail.includes('material'),
    );
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getMaterialTypeForSelectricSelect(state) {
    let options = [];
    options.push({ label: '선택', value: null, detail: null });
    const types = clonedeep(state.resource_types).filter(x =>
      x.detail.includes('material'),
    );
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getMaterialTypeForSelectricAll(state) {
    let options = [];
    options.push({ label: '전체', value: null, detail: null });
    const types = clonedeep(state.resource_types).filter(x =>
      x.detail.includes('material'),
    );

    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getProductTypeForSelectric(state) {
    let options = [];
    const types = clonedeep(state.resource_types).filter(x =>
      x.detail.includes('product'),
    );
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getProductTypeForSelectricSelect(state) {
    let options = [];
    options.push({ label: '선택', value: null, detail: null });
    const types = clonedeep(state.resource_types).filter(x =>
      x.detail.includes('product'),
    );
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getProductTypeForSelectricAll(state) {
    let options = [];
    options.push({ label: '전체', value: null, detail: null });
    const types = clonedeep(state.resource_types).filter(x =>
      x.detail.includes('product'),
    );

    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
};
const mutations = {
  setResourceType(state, payload) {
    state.resource_types = payload;
  },
};

const actions = {
  FETCH_RESOURCE_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/resource-type')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setResourceType', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
