import { companyInstance } from '@/api/index';
import { formatDateNoHours, makeComma } from '@/utils/filters';
import clonedeep from 'lodash/cloneDeep';

const state = {
  purchase: [],
  purchase_material_purchase: [],
  selectedIndex: -1,
  start_date: null,
  end_date: null,
  check_only_not_completed: true,
  managementData: {
    id: null,
    company_id: null,
    purchase_type_id: null,
    completed: false,
    incoming_date: null,
    input_date: null,
    detail: '',
    purchase_materials: [],
  },
  cloneData: {
    id: null,
    company_id: null,
    purchase_type_id: null,
    completed: false,
    incoming_date: null,
    input_date: null,
    detail: '',
    purchase_materials: [],
  },
  newData: {
    company_id: null,
    purchase_type_id: 1,
    completed: false,
    incoming_date: null,
    input_date: null,
    detail: '',
    purchase_materials: [
      // {
      //   material_id: null,
      //   unit_id: null,
      //   box_unit_id: null,
      //   standard: '',
      //   quantity: 0,
      //   cost: 0, //단가
      //   supply_value: 0, //공급가
      //   tax: 0, //부가세
      //   total_cost: 0, //합계액
      //   discount_value : 0, //단일 할인액
      // },
    ],
  },
};
const getters = {
  getPurchase(state) {
    return state.purchase.sort((a, b) => b.id - a.id);
  },
  getPurchaseOnlyInputNotCompleted(state) {
    return state.purchase
      .sort((a, b) => b.id - a.id)
      .filter(
        x =>
          x.material_list.find(y => y.input_yn == false) != undefined &&
          x.completed == false,
      );
  },
  getOrderPurchaseOnlyInputNotCompleted(state) {
    return state.purchase
      .sort((a, b) => b.id - a.id)
      .filter(
        x =>
          x.material_list.find(y => y.input_yn == false) != undefined &&
          x.completed == false,
      )
      .filter(x => x.purchase_type_id == 1);
  },
  getStartDateFromPurchase(state) {
    return state.start_date;
  },
  getEndDateFromPurchase(state) {
    return state.end_date;
  },
  getDateFromPurchase(state) {
    return {
      start: formatDateNoHours(state.start_date),
      end: formatDateNoHours(state.end_date),
    };
  },
  getCheckOnlyNotCompletedFromPurchase(state) {
    return state.check_only_not_completed;
  },
  getManagementDataFromPurchase(state) {
    return state.managementData;
  },
  getManagementDataMaterialListFromPurchase(state) {
    return state.managementData.material_list;
  },
  getManagementDataPurchaseMaterialsFromPurchase(state) {
    return state.managementData.purchase_materials;
  },
  getNewDataFromPurchase(state) {
    return state.newData;
  },
  getCloneDataFromPurchase(state) {
    return state.cloneData;
  },
  getSelectedIndexFromPurchase(state) {
    return state.selectedIndex;
  },
  getPurchaseMaterialPurchase(state) {
    return state.purchase_material_purchase;
  },
  getPurchaseMaterialPurchaseOnlyNotInput(state) {
    return state.purchase_material_purchase
      .sort((a, b) => b.id - a.id)
      .filter(x => x.input_yn == false)
      .filter(x => x.purchase_type_id == 1);
  },
};
const mutations = {
  setPurchase(state, payload) {
    state.purchase = payload;
  },
  setManagementDataToPurchase(state, payload) {
    if (payload.id != null) {
      const reMapData = function(x) {
        if (x.discount == null) {
          x.discount = '0';
        } else {
          x.discount = makeComma(x.discount);
        }
        if (x.cost == null) {
          x.cost = '0';
        } else {
          x.cost = makeComma(x.cost);
        }
        x.check_print = false;
        x.barcode_num =
          state.managementData.input_date.replaceAll('-', '') +
          ('00000' + String(x.id)).substr(-5);
        return x;
      };
      payload.material_list.sort((a, b) => {
        return (
          a.input_yn - b.input_yn ||
          new Date(b.input_date) - new Date(a.input_date)
        );
      });
      payload.material_list = payload.material_list.map(reMapData);
      payload.purchase_materials = clonedeep(payload.material_list);
      payload.purchase_order_list = payload.purchase_order_list.map(reMapData);
    }
    state.managementData = payload;
  },
  deleteManagementDataMaterialListItem(state, index) {
    state.managementData.material_list.splice(index, 1);
  },
  setNewDataToPurchase(state, payload) {
    state.newData = payload;
  },
  setNewDataCompanyIdToPurchase(state, payload) {
    state.newData.company_id = payload;
  },
  setCloneDataToPurchase(state, payload) {
    state.cloneData = payload;
  },
  setSelectedIndexToPurchase(state, index) {
    state.selectedIndex = index;
  },
  toggleCheckOnlyNotCompletedToPurchase(state) {
    state.check_only_not_completed = !state.check_only_not_completed;
  },
  setStartDateToPurchase(state, payload) {
    state.start_date = payload;
  },
  setEndDateToPurchase(state, payload) {
    state.end_date = payload;
  },
  setPurchaseMaterialPurchase(state, payload) {
    state.purchase_material_purchase = payload;
  },
};
const actions = {
  FETCH_PURCHASE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = `plan/purchase/info/start_date=${payload.start_date}&end_date=${payload.end_date}`;
      if (
        payload.check_only_not_completed != undefined &&
        payload.check_only_not_completed == true
      ) {
        url += `&completed=false`;
      }
      companyInstance
        .get(url)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setPurchase', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PURCHASE_MATERIAL_PURCHASE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('plan/purchase-material')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setPurchaseMaterialPurchase', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_PURCHASE_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('plan/purchase/info/all', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_PURCHASE_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/purchase/info/all', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_PURCHASE_ALL_UPDATE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/purchase/info/all/update', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_PURCHASE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`plan/purchase/info/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_PURCHASE_PRODUCT_LIST(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/purchase/material/list', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_PURCHASE_COMPLETE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/purchase/info/complete', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_RESTORE_PURCHASE_COMPLETE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/purchase/info/restore-complete', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_PURCHASE_ACCOUNT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('plan/purchase/account', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_PURCHASE_AND_INSERT_MATERIAL_CONTROL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('plan/purchase/info/all/non-order', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
