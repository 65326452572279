import { formatDateNoHours } from '@/utils/filters';
import { companyInstance } from '@/api/index';

const state = {
  start_date: null,
  end_date: null,
  sales: [],
  selected_product_list: [],
  selected_material_list: [],
  group_material_list: [],
};

const getters = {
  getStartDateFromCalculation(state) {
    return state.start_date;
  },
  getEndDateFromCalculation(state) {
    return state.end_date;
  },
  getSelectedProductListFromCalculation(state) {
    return state.selected_product_list.sort((a, b) => b.id - a.id);
  },
  getSelectedMaterialListFromCalculation(state) {
    return state.selected_material_list.sort((a, b) => b.id - a.id);
  },
  getDateFromCalculation(state) {
    return {
      start: formatDateNoHours(state.start_date),
      end: formatDateNoHours(state.end_date),
    };
  },
  getSalesFromCalculation(state) {
    return state.sales.filter(
      x =>
        x.completed == false &&
        x.product_list.map(y => y.order_yn).includes(false),
    );
  },
  getGroupMaterialListFromCalculation(state) {
    return state.group_material_list;
  },
};
const mutations = {
  setStartDateToCalculation(state, payload) {
    state.start_date = payload;
  },
  setEndDateToCalculation(state, payload) {
    state.end_date = payload;
  },
  setSelectedProductListToCalculation(state, payload) {
    state.selected_product_list = payload;
  },
  setSelectedMaterialListToCalculation(state, payload) {
    state.selected_material_list = payload;
  },
  setGroupMaterialListToCalculation(state, payload) {
    state.group_material_list = payload;
  },
  setSalesToCalculation(state, payload) {
    state.sales = payload;
  },
};
const actions = {
  FETCH_SALES_TO_CALCULATION({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = `plan/sales/info/start_date=${payload.start_date}&end_date=${payload.end_date}&completed=false`;
      companyInstance
        .get(url)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setSalesToCalculation', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
