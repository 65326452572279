import { companyInstance } from '@/api';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  startDate: null,
  endDate: null,
  searchText: '',
  companyText: '',
  searchStockText: '',
  selectedIndex: -1,
  materialStatusList: [],
  stockStatusList: [],
  modalClose: true,
  searchStoreLocation: null,
  searchMaterialResource: null,
  exceptStockZero: false,
};
const getters = {
  getStartDateFromMaterialStatus(state) {
    return state.startDate;
  },
  getEndDateFromMaterialStatus(state) {
    return state.endDate;
  },
  getDateFromMaterialStatus(state) {
    return {
      start: formatDateNoHours(state.startDate),
      end: formatDateNoHours(state.endDate),
    };
  },
  getSearchTextFromMaterialStatus(state) {
    return state.searchText;
  },
  getCompanyTextFromMaterialStatus(state) {
    return state.companyText;
  },
  getMaterialStatusListFromMaterialStatus(state) {
    return state.materialStatusList.sort(
      (a, b) =>
        Number(
          b.create_time
            .replace(/-/gi, '')
            .replace(/:/gi, '')
            .replace(' ', ''),
        ) -
        Number(
          a.create_time
            .replace(/-/gi, '')
            .replace(/:/gi, '')
            .replace(' ', ''),
        ),
    );
  },
  getSearchStockTextFromMaterialStockStatus(state) {
    return state.searchStockText;
  },
  getMaterialStockStatusListFromMaterialStockStatus(state) {
    return state.stockStatusList;
  },
  getSelectedIndexFromStock(state) {
    return state.selectedIndex;
  },
  getModalClose(state) {
    return state.modalClose;
  },
  getSearchStoreLocationFromMaterialStockStatus(state) {
    return state.searchStoreLocation;
  },
  getSearchMaterialResourceFromMaterialStockStatus(state) {
    return state.searchMaterialResource;
  },
  getCheckExceptStorckZeroFromMaterialStockStatus(state) {
    return state.exceptStockZero;
  },
};
const mutations = {
  setModalClose(state, payload) {
    state.modalClose = payload;
  },
  setStartDateToMaterialStatus(state, payload) {
    state.startDate = payload;
  },
  setEndDateToMaterialStatus(state, payload) {
    state.endDate = payload;
  },
  setSearchTextToMaterialStatus(state, payload) {
    state.searchText = payload;
  },
  setCompanyTextToMaterialStatus(state, payload) {
    state.companyText = payload;
  },
  setMaterialStatusListToMaterialStatus(state, payload) {
    state.materialStatusList = payload;
  },
  setSearchStockTextToMaterialStockStatus(state, payload) {
    state.searchStockText = payload;
  },
  setMaterialStockStatusListToMaterialStockStatus(state, payload) {
    state.stockStatusList = payload;
  },
  setSelectedIndexToStock(state, index) {
    state.selectedIndex = index;
  },
  setSearchStoreLocationToMaterialStockStatus(state, payload) {
    state.searchStoreLocation = payload;
  },
  setSearchMaterialResourceToMaterialStockStatus(state, payload) {
    state.searchMaterialResource = payload;
  },
  setCheckExceptStockZeroToMaterialStockStatus(state, payload) {
    state.exceptStockZero = payload;
  },
};
const actions = {
  FETCH_MATERIAL_STATUS_DAY({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `mag/material/in-out/barcode/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit(
              'setMaterialStatusListToMaterialStatus',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_MATERIAL_STATUS({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/material/in-out/barcode`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit(
              'setMaterialStockStatusListToMaterialStockStatus',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
