import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      isSpinnerShow: 'getIsSpinnerShow',
    }),
  },
  methods: {
    ...mapMutations(['showSpinner', 'hideSpinner']),
  },
};
