import { companyInstance } from '@/api/index';
import { formatDateNoHours, makeNumberFixed2 } from '@/utils/filters';
import { decimalAdd, decimalMul, decimalDiv } from '@/utils/filters';

const state = {
  startDate: null,
  endDate: null,
  radioState: {
    radio_material: true,
    radio_process: false,
    radio_product: false,
  },
  radioSearch: {
    radio_material: true,
    radio_process: false,
    radio_product: false,
  },
  materialReportData: [],
  seqReportData: [],
  productReportData: [],
};

const getters = {
  getStartDateFromReportAll(state) {
    return state.startDate;
  },
  getEndDateFromReportAll(state) {
    return state.endDate;
  },
  getDateFromReportAll(state) {
    return {
      start: formatDateNoHours(state.startDate),
      end: formatDateNoHours(state.endDate),
    };
  },
  getRadioStateFromReportAll(state) {
    return state.radioState;
  },
  getRadioSearchFromReportAll(state) {
    return state.radioSearch;
  },
  getMaterialReportDataFromReportAll(state) {
    return state.materialReportData;
  },
  getSeqReportDataFromReportAll(state) {
    return state.seqReportData;
  },
  getProductReportDataFromReportAll(state) {
    return state.productReportData;
  },
  getMaterialReportDataGroupByMaterialFromReportAll(state) {
    const obj = state.materialReportData.reduce(function(rv, x) {
      (rv[x['material_id']] = rv[x['material_id']] || []).push(x);
      return rv;
    }, {});
    let arr = [];
    for (let k in obj) {
      arr.push({
        material_id: k,
        in_quantity: obj[k]
          .map(x => x.in_quantity)
          .reduce((a, b) => decimalAdd(a, b), 0),
        pass_quantity: obj[k]
          .map(x => x.pass_quantity)
          .reduce((a, b) => decimalAdd(a, b), 0),
        unpass_quantity: obj[k]
          .map(x => x.unpass_quantity)
          .reduce((a, b) => decimalAdd(a, b), 0),
        unpass_percent: makeNumberFixed2(
          (obj[k]
            .map(x => x.unpass_quantity)
            .reduce((a, b) => decimalAdd(a, b), 0) /
            obj[k]
              .map(x => x.in_quantity)
              .reduce((a, b) => decimalAdd(a, b), 0)) *
            100,
        ),
        pass_percent: makeNumberFixed2(
          (obj[k]
            .map(x => x.pass_quantity)
            .reduce((a, b) => decimalAdd(a, b), 0) /
            obj[k]
              .map(x => x.in_quantity)
              .reduce((a, b) => decimalAdd(a, b), 0)) *
            100,
        ),
      });
    }
    return arr;
  },
  getMaterialReportDataGroupByInputDateFromReportAll(state) {
    const obj = state.materialReportData.reduce(function(rv, x) {
      (rv[x['input_date']] = rv[x['input_date']] || []).push(x);
      return rv;
    }, {});
    let arr = [];
    for (let k in obj) {
      arr.push({
        input_date: k,
        in_quantity: obj[k]
          .map(x => x.in_quantity)
          .reduce((a, b) => decimalAdd(a, b), 0),
        element: obj[k],
        unpass_percent: makeNumberFixed2(
          obj[k]
            .map(x => x.unpass_percent)
            .reduce((a, b) => decimalAdd(a, b), 0) / obj[k].length,
        ),
        pass_percent:
          100 -
          makeNumberFixed2(
            obj[k]
              .map(x => x.unpass_percent)
              .reduce((a, b) => decimalAdd(a, b), 0) / obj[k].length,
          ),
      });
    }
    return arr;
  },
  getSeqReportDataGroupByProcessFromReportAll(state) {
    const obj = state.seqReportData.reduce(function(rv, x) {
      (rv[x['process_id']] = rv[x['process_id']] || []).push(x);
      return rv;
    }, {});
    let arr = [];
    for (let k in obj) {
      const pass_quantity = obj[k]
        .map(x => x.pass_quantity)
        .reduce((a, b) => decimalAdd(a, b), 0);
      const unpass_quantity = obj[k]
        .map(x => x.unpass_quantity)
        .reduce((a, b) => decimalAdd(a, b), 0);
      const in_quantity = pass_quantity + unpass_quantity;
      arr.push({
        process_id: k,
        in_quantity: in_quantity,
        pass_quantity: pass_quantity,
        unpass_quantity: unpass_quantity,
        unpass_percent: decimalMul(
          decimalDiv(unpass_quantity, in_quantity),
          100,
        ),
        pass_percent: decimalMul(decimalDiv(pass_quantity, in_quantity), 100),
      });
    }
    return arr;
  },
  getSeqReportDataGroupByInputDateFromReportAll(state) {
    const obj = state.seqReportData.reduce(function(rv, x) {
      (rv[x['input_date']] = rv[x['input_date']] || []).push(x);
      return rv;
    }, {});
    let arr = [];
    for (let k in obj) {
      const pass_quantity = obj[k]
        .map(x => x.pass_quantity)
        .reduce((a, b) => decimalAdd(a, b), 0);
      const unpass_quantity = obj[k]
        .map(x => x.unpass_quantity)
        .reduce((a, b) => decimalAdd(a, b), 0);
      const in_quantity = pass_quantity + unpass_quantity;
      arr.push({
        input_date: k,
        in_quantity: in_quantity,
        element: obj[k],
        unpass_percent: makeNumberFixed2(
          obj[k]
            .map(x => x.unpass_percent)
            .reduce((a, b) => decimalAdd(a, b), 0) / obj[k].length,
        ),
        pass_percent:
          100 -
          makeNumberFixed2(
            obj[k]
              .map(x => x.unpass_percent)
              .reduce((a, b) => decimalAdd(a, b), 0) / obj[k].length,
          ),
      });
    }
    return arr;
  },
  getProductReportDataGroupByProductFromReportAll(state) {
    const obj = state.productReportData.reduce(function(rv, x) {
      (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
      return rv;
    }, {});
    let arr = [];
    for (let k in obj) {
      const pass_quantity = obj[k]
        .map(x => x.pass_quantity)
        .reduce((a, b) => decimalAdd(a, b), 0);
      const unpass_quantity = obj[k]
        .map(x => x.unpass_quantity)
        .reduce((a, b) => decimalAdd(a, b), 0);
      const in_quantity = pass_quantity + unpass_quantity;
      arr.push({
        product_id: k,
        in_quantity: in_quantity,
        pass_quantity: pass_quantity,
        unpass_quantity: unpass_quantity,
        unpass_percent: decimalMul(
          decimalDiv(unpass_quantity, in_quantity),
          100,
        ),
        pass_percent: decimalMul(decimalDiv(pass_quantity, in_quantity), 100),
      });
    }
    return arr;
  },
  getProductReportDataGroupByInputDateFromReportAll(state) {
    const obj = state.productReportData.reduce(function(rv, x) {
      (rv[x['input_date']] = rv[x['input_date']] || []).push(x);
      return rv;
    }, {});
    let arr = [];
    for (let k in obj) {
      const pass_quantity = obj[k]
        .map(x => x.pass_quantity)
        .reduce((a, b) => decimalAdd(a, b), 0);
      const unpass_quantity = obj[k]
        .map(x => x.unpass_quantity)
        .reduce((a, b) => decimalAdd(a, b), 0);
      const in_quantity = pass_quantity + unpass_quantity;
      arr.push({
        input_date: k,
        in_quantity: in_quantity,
        element: obj[k],
        unpass_percent: makeNumberFixed2(
          obj[k]
            .map(x => x.unpass_percent)
            .reduce((a, b) => decimalAdd(a, b), 0) / obj[k].length,
        ),
        pass_percent:
          100 -
          makeNumberFixed2(
            obj[k]
              .map(x => x.unpass_percent)
              .reduce((a, b) => decimalAdd(a, b), 0) / obj[k].length,
          ),
      });
    }
    return arr;
  },
};
const mutations = {
  setStartDateToReportAll(state, payload) {
    state.startDate = payload;
  },
  setEndDateToReportAll(state, payload) {
    state.endDate = payload;
  },
  setRadioStateToReportAll(state, payload) {
    switch (payload) {
      case 'material':
        state.radioState.radio_material = true;
        state.radioState.radio_product = false;
        state.radioState.radio_process = false;
        break;
      case 'process':
        state.radioState.radio_material = false;
        state.radioState.radio_product = false;
        state.radioState.radio_process = true;
        break;
      case 'product':
        state.radioState.radio_material = false;
        state.radioState.radio_product = true;
        state.radioState.radio_process = false;
        break;
    }
  },
  setRadioSearchToReportAll(state, payload) {
    switch (payload) {
      case 'material':
        state.radioSearch.radio_material = true;
        state.radioSearch.radio_product = false;
        state.radioSearch.radio_process = false;
        break;
      case 'process':
        state.radioSearch.radio_material = false;
        state.radioSearch.radio_product = false;
        state.radioSearch.radio_process = true;
        break;
      case 'product':
        state.radioSearch.radio_material = false;
        state.radioSearch.radio_product = true;
        state.radioSearch.radio_process = false;
        break;
    }
  },
  setMaterialReportDataToReportAll(state, payload) {
    state.materialReportData = payload;
  },
  setSeqReportDataToReportAll(state, payload) {
    state.seqReportData = payload;
  },
  setProductReportDataToReportAll(state, payload) {
    state.productReportData = payload;
  },
};
const actions = {
  FETCH_MATERIAL_QUALITY_REPORT({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/material/quality-report/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setMaterialReportDataToReportAll', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_SEQ_QUALITY_REPORT({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/seq/quality-report/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setSeqReportDataToReportAll', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_PRODUCT_QUALITY_REPORT({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/product/quality-report/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setProductReportDataToReportAll', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
