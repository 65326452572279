import { companyInstance } from '@/api';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  sales_account: [],
  start_date: null,
  end_date: null,
  include_company_list: [],
};

const getters = {
  getSalesAccountFromSalesLedger(state) {
    return state.sales_account;
  },
  getStartDateFromSalesLedger(state) {
    return state.start_date;
  },
  getEndDateFromSalesLedger(state) {
    return state.end_date;
  },
  getIncludeCompanyListFromSalesLedger(state) {
    return state.include_company_list;
  },
};

const mutations = {
  setSalesAccountToSalesLedger(state, payload) {
    state.sales_account = payload;
  },
  setStartDateToSalesLedger(state, payload) {
    state.start_date = payload;
  },
  setEndDateToSalesLedger(state, payload) {
    state.end_date = payload;
  },
  setIncludeCompanyListToSalesLedger(state, payload) {
    state.include_company_list = payload;
  },
};
const actions = {
  FETCH_SALES_ACCOUNT_DAY_ALL_TO_SALES_LEDGER({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `plan/sales/account/all/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setSalesAccountToSalesLedger', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
