import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  product_verifications: [],
  managementData: null,
  selectedIndex: null,
  start_date: null,
  end_date: null,
  searchText: '',
  sanchae_sensor_log: [],
};
const getters = {
  getProductVerificationFromSanchaeProductVerification(state) {
    return state.product_verifications;
  },
  getManagementDataFromSanchaeProductVerification(state) {
    return state.managementData;
  },
  getSanchaeSensorLogFromSanchaeProductverification(state) {
    return state.sanchae_sensor_log;
  },
  getSelectedIndexFromSanchaeProductVerification(state) {
    return state.selectedIndex;
  },
  getStartDateFromSanchaeProductVerification(state) {
    return state.start_date;
  },
  getEndDateFromSanchaeProductVerification(state) {
    return state.end_date;
  },
  getSearchTextFromSanchaeProductVerification(state) {
    return state.searchText;
  },
};
const mutations = {
  setProductVerificationToSanchaeProductVerification(state, payload) {
    payload.map(x => {
      x.jeonhujin = x.jeonhujin != null ? x.jeonhujin : 0;
      x.temp_setting = x.temp_setting != null ? x.temp_setting : 0;
      x.water_start = x.water_start != null ? x.water_start : 0;
      x.water_middle = x.water_middle != null ? x.water_middle : 0;
      x.water_end = x.water_end != null ? x.water_end : 0;
      x.temp_start = x.temp_start != null ? x.temp_start : 0;
      x.temp_middle = x.temp_middle != null ? x.temp_middle : 0;
      x.temp_end = x.temp_end != null ? x.temp_end : 0;
      x.humi_start = x.humi_start != null ? x.humi_start : 0;
      x.humi_middle = x.humi_middle != null ? x.humi_middle : 0;
      x.humi_end = x.humi_end != null ? x.humi_end : 0;
      x.rail_speed_start = x.rail_speed_start != null ? x.rail_speed_start : 0;
      x.rail_speed_end = x.rail_speed_end != null ? x.rail_speed_end : 0;
      x.fail_quantity = x.fail_quantity != null ? x.fail_quantity : 0;
      return x;
    });
    state.product_verifications = payload;
  },
  setManagementDataToSanchaeProductVerification(state, payload) {
    state.managementData = payload;
  },
  setSanchaeSensorLogToSanchaeProductverification(state, payload) {
    state.sanchae_sensor_log = payload;
  },
  setSelectedIndexToSanchaeProductVerification(state, payload) {
    state.selectedIndex = payload;
  },
  setStartDateToSanchaeProductVerification(state, payload) {
    state.start_date = payload;
  },
  setEndDateToSanchaeProductVerification(state, payload) {
    state.end_date = payload;
  },
  setSearchTextToSanchaeProductVerification(state, payload) {
    state.searchText = payload;
  },
};
const actions = {
  FETCH_SANCHAE_PRODUCT_VERIFICATION({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`custom/product_verification`)
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setProductVerificationToSanchaeProductVerification',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_V_PRODUCT_VERIFICATION({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`custom/v_product_verification`)
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setProductVerificationToSanchaeProductVerification',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_V_PRODUCT_VERIFICATION_DAY({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `custom/v_product_verification/day/start=${payload.start_date}&end=${payload.end_date}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setProductVerificationToSanchaeProductVerification',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_SANCHAE_TH_LOG({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `custom/v_sanchae_th_sensor/day/start=${newDate.start}&end=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setSanchaeSensorLogToSanchaeProductverification',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_SANCHAE_PRODUCT_VERIFICATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post(`custom/product_verification`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UPDATE_SANCHAE_PRODUCT_VERIFICATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put(`custom/product_verification`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DELETE_SANCHAE_PRODUCT_VERIFICATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`custom/product_verification${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
