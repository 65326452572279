import { companyInstance } from '@/api/index.js';

const state = {
  sales_types: [],
};
const getters = {
  getSalesType(state) {
    return state.sales_types.sort((a, b) => a.id - b.id);
  },
  getSalesTypeNotSort(state) {
    return state.sales_types;
  },
  getPlanSalesType(state) {
    return state.sales_types.find(x => x.detail.includes('plan'));
  },
  getDirectSalesType(state) {
    return state.sales_types.find(x => x.detail.includes('direct'));
  },
  getReturnSalesType(state) {
    return state.sales_types.filter(x => x.detail.includes('return'));
  },
  getSalesTypeForSelectric(state) {
    let options = [];
    const types = state.sales_types.sort((a, b) => a.id - b.id);
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getSalesTypeForSelectric_NoReturn(state) {
    let options = [];
    const types = state.sales_types
      .filter(x => !x.detail.includes('return'))
      .sort((a, b) => a.id - b.id);
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
};
const mutations = {
  setSalesType(state, payload) {
    state.sales_types = payload;
  },
};

const actions = {
  FETCH_SALES_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('plan/sales/type')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setSalesType', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
