// 로그인, 회원 가입, (ex) 회원 탈퇴

import axios from 'axios';
import { setInterceptors } from './common/interceptors_autologin';

//자동 로그인용 인스턴스 생성
function createAutoLoginInstance() {
  const instance = axios.create({
    baseURL: localStorage.getItem('companyUrl'),
  });
  return setInterceptors(instance);
}
function refreshToken(payload) {
  const instanceTemp = createAutoLoginInstance();
  return instanceTemp.post('auth/refresh-token', payload);
}

export { refreshToken };
