import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  user_report: [],
};

const getters = {
  getUserReportFromReport(state) {
    return state.user_report;
  },
};
const mutations = {
  setUserReportToReport(state, payload) {
    state.user_report = payload;
  },
};
const actions = {
  async FETCH_USER_REPORT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(`login-log/day/start=${newDate.start}&end=${newDate.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setUserReportToReport', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
