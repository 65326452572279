import { companyInstance } from '@/api';

const state = {
  targetUser: null,
  month: null,
  year: null,
  calendarOptions: [],
  manageCalendarOptions: [],
  calendarMonthList: [],
  calendarModalData: {
    title: '',
    detail: '',
    system_event_type_id: 1,
  },
};

const getters = {
  getCalendarOptionsFromCalendar(state) {
    return state.calendarOptions;
  },
  getmanageCalendarOptionsFromMainCalendar(state) {
    return state.manageCalendarOptions;
  },
  getCalendarMonthFromCalendar(state) {
    return state.calendarMonthList;
  },
  getTargetUserFromCalendar(state) {
    return state.targetUser;
  },
  getCalendarModalDataFromCalendar(state) {
    return state.calendarModalData;
  },
  getMonthFromCalendar(state) {
    return state.month;
  },
  getYearFromCalendar(state) {
    return state.year;
  },
};

const mutations = {
  setCalendarOptionsToCalendar(state, payload) {
    state.calendarOptions = payload;
  },
  setmanageCalendarOptionsFromMainCalendar(state, payload) {
    state.manageCalendarOptions = payload;
  },
  setCalendarMonthToCalendar(state, payload) {
    state.calendarMonthList = payload;
  },
  setTargetUserToCalendar(state, payload) {
    state.targetUser = payload;
  },
  setCalendarModalDataToCalendar(state, payload) {
    state.calendarModalData = payload;
  },
  setMonthToCalendar(state, payload) {
    state.month = payload;
  },
  setYearToCalendar(state, payload) {
    state.year = payload;
  },
};

const actions = {
  async FETCH_CALENDAR_MONTH({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`system/event/v/${payload}`) /* payload = month => 2021-01 */
        .then(response => {
          if (response.data.result == 'success') {
            console.log('RES', response);
            commit('setCalendarMonthToCalendar', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_CALENDAR({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`user/system/event/type/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('RES', response);
            commit('setCalendarOptionsToCalendar', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_CALENDAR(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('user/system/event/type', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_CALENDAR_MODAL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('system/event', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_CALENDAR_MODAL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('system/event', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async DELETE_CALENDAR_MODAL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`system/event/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
