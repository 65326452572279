/**
 * 2021-10-07 이재원
 * 스페어 관리 페이지
 */

import { companyInstance } from '@/api/index';
// import { formatDateNoHours } from '@/utils/filters';

const state = {
  spare_parts: [],
  r_part_machines: [],
  str_search_machine: '',
  str_search_spare_part: '',
  chk_only_not_zero_quantity: true,
  management_data: {
    // 조회 & 수정
    id: null, //Number
    name: '', //String
    standard: '', //String
    unit_id: null, //Number,
    store_id: null, //Number
    store_location_id: null, //Number
    r_part_machine_list: [], // { machine_id : number }[]
    quantity: 0, //Number,
  },
  new_data: {
    // 등록
    name: '', //String
    standard: '', //String
    unit_id: null, //Number,
    store_id: null, //Number
    store_location_id: null, //Number
    r_part_machine_list: [], // { machine_id : number }[]
    quantity: 0, //Number,
  },
};
const getters = {
  getSparePartFromSpareManagementForm(state) {
    return state.spare_parts;
  },
  getRPartMachineFromSpareManagementForm(state) {
    return state.r_part_machines;
  },
  getStrSearchMachineFromSpareManagementForm(state) {
    return state.str_search_machine;
  },
  getStrSearchSparePartFromSpareManagementForm(state) {
    return state.str_search_spare_part;
  },
  getChkOnlyNoyZeroQuantityFromSpareManagementForm(state) {
    return state.chk_only_not_zero_quantity;
  },
  getManagementDataFromSpareManagementForm(state) {
    return state.management_data;
  },
  getNewDataFromSpareManagementForm(state) {
    return state.new_data;
  },
};
const mutations = {
  setSparePartToSpareManagementForm(state, payload) {
    state.spare_parts = payload
      .sort((a, b) => a.id - b.id)
      .map(x => {
        x.name_temp = x.name;
        x.standard_temp = x.standard;
        x.quantity_temp = x.quantity;
        x.mod_name = false;
        x.mod_standard = false;
        x.mod_quantity = false;
        return x;
      });
  },
  setRPartMachineToSpareManagementForm(state, payload) {
    state.r_part_machines = payload;
  },
  setStrSearchMachineToSpareManagementForm(state, payload) {
    state.str_search_machine = payload;
  },
  setStrSearchSparePartToSpareManagementForm(state, payload) {
    state.str_search_spare_part = payload;
  },
  setChkOnlyNoyZeroQuantityToSpareManagementForm(state, payload) {
    state.chk_only_not_zero_quantity = payload;
  },
  setManagementDataToSpareManagementForm(state, payload) {
    state.management_data = payload;
  },
  setNewDataToSpareManagementForm(state, payload) {
    state.new_data = payload;
  },
};
const actions = {
  FETCH_SPARE_PART({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('part')
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setSparePartToSpareManagementForm',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_SPARE_PART(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('part', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_SPARE_PART(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('part', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_SPARE_PART(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`part/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_R_PART_MACHINE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('r-part-machine')
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setRPartMachineToSpareManagementForm',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_R_PART_MACHINE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('r-part-machine', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_R_PART_MACHINE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`r-part-machine/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
