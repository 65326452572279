export default {
  methods: {
    dragElement(elmnt) {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;

      let modalHeader = document.querySelector(
        `.${elmnt.className} .modal_header`,
      );
      let FirstModalHeader = document.querySelector(
        `.${elmnt.classList[1]} .modal_header`,
      );
      let errorModal = elmnt.id == 'errorModal' ? elmnt : '';
      if (errorModal) {
        errorModal.querySelector('.modal_header').onmousedown = dragMouseDown;
      } else if (modalHeader) {
        modalHeader.onmousedown = dragMouseDown;
      } else if (FirstModalHeader) {
        FirstModalHeader.onmousedown = dragMouseDown;
      } else {
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        e.stopPropagation();
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }
      function elementDrag(e) {
        e = e || window.event;
        e.stopPropagation();
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
        elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
      }
      function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
  },
  mounted() {
    let modalPopup = document.querySelector('.modalPopup');
    let packageModal = document.querySelector('.package_modal');
    let orderBarcodeModal = document.querySelector('.order_barcode_modal');
    let orderDetailModal = document.querySelector('.order_detail_print_modal');
    let errorModal = document.querySelectorAll('#errorModal');
    let productSearch = document.querySelector('#product_search_modal');
    let companySearch = document.querySelector('#company_search_modal');
    if (modalPopup) {
      if (!packageModal) {
        this.dragElement(modalPopup);
      }
    }
    if (orderBarcodeModal) {
      this.dragElement(orderBarcodeModal);
    }
    if (orderDetailModal) {
      this.dragElement(orderDetailModal);
    }
    if (errorModal) {
      errorModal.forEach(el => {
        this.dragElement(el);
      });
    }
    if (productSearch) {
      this.dragElement(productSearch);
    }
    if (companySearch) {
      this.dragElement(companySearch);
    }
  },
  destroyed() {
    window.removeEventListener(
      'keydown',
      (document.onkeydown = e => {
        e = e || window.event;
        if (e.keyCode == 27) {
          this.$emit('onclose');
          if (this.caledarModalData != undefined) {
            this.isModalClose();
          }
        }
        if (e.keyCode == 13) {
          if (this.isModalOpen) {
            this.$emit('OnYesClick', this.index, this);
          }
          if (e.target.tagName != 'INPUT') {
            if (this.CloseModal) {
              this.CloseModal();
            }
          } else {
            if (this.isModalOpen) {
              e.preventDefault();
              e.target.blur();
              if (this.CloseModal) {
                this.CloseModal();
              }
            }
          }
        }
      }),
    );
  },
  created() {
    window.addEventListener(
      'keydown',
      (document.onkeydown = e => {
        e = e || window.event;
        if (e.keyCode == 27) {
          if (this.$emit('onclose')) {
            this.$emit('onclose');
          }
          if (this.CloseModal) {
            this.CloseModal();
          }
          if (this.isModalClose) {
            this.isModalClose();
          }
        }
        if (e.keyCode == 13) {
          if (this.isModalOpen) {
            this.$emit('OnYesClick', this.index, this);
          }
          if (e.target.tagName != 'INPUT') {
            if (this.CloseModal) {
              this.CloseModal();
              if (e.target.className == 'btn_check') {
                e.preventDefault();
                e.target.blur();
                let saupInput = document.querySelector(
                  `.login_input_text .input_login_text`,
                );
                saupInput.focus();
              }
            }
          } else {
            if (
              this.isModalOpen &&
              document.querySelector('.login_input_text')
            ) {
              e.preventDefault();
              e.target.blur();
              let inputClass = e.target.className;
              let saupInput = document.querySelector(
                `.login_input_text .${inputClass}`,
              );
              if (this.CloseModal) {
                this.CloseModal();
              }
              saupInput.focus();
            }
          }
        }
      }),
    );
  },
};
