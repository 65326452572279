import { instance } from '@/api/index.js';
import { yyyymmdd } from '@/utils/func';

const state = {
  patchNoteList: [],
  patchNoteOnlyList: [],
  newPatchNote: null,
  selectIndex: -1,
  managementData: {
    id: -1,
    create_time: yyyymmdd(new Date()),
    title: '',
    content: '',
    system_company_id: null,
    flag: false,
  },
  newData: {
    create_time: yyyymmdd(new Date()),
    title: '',
    content: '',
    system_company_id: null,
    flag: false,
  },
  systemCompanyList: [],
};

const getters = {
  getPatchNoteListFromPatchNote(state) {
    return state.patchNoteList.sort(
      (a, b) => new Date(b.create_time) - new Date(a.create_time),
    );
  },
  getPatchNoteOnlyListFromPatchNote(state) {
    return state.patchNoteOnlyList
      .filter(
        x =>
          x.flag &&
          (x.system_company_id == null ||
            x.system_company_id == localStorage.getItem('saupKey')) &&
          new Date(x.create_time) <= new Date(yyyymmdd(new Date())),
      )
      .sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
  },
  getNewPatchNoteFromPatchNote(state) {
    return state.newPatchNote;
  },
  getSelectIndexFromPatchNote(state) {
    return state.selectIndex;
  },
  getmanagementDataFromPatchNote(state) {
    return state.managementData;
  },
  getNewDataFromPatchNote(state) {
    return state.newData;
  },
  getSystemCompanyListFromPatchNote(state) {
    return state.systemCompanyList;
  },
  getCompanyOptionsFromPatchNote(state) {
    let options = [];
    options.push({ label: '전체', value: null, detail: null });
    const companyList = state.systemCompanyList.map(x => {
      return { label: x.name, value: x.id, detail: x.name };
    });
    return options.concat(companyList);
  },
};

const mutations = {
  setPatchNoteListToPatchNote(state, payload) {
    state.patchNoteList = payload;
  },
  setPatchNoteOnlyListToPatchNote(state, payload) {
    state.patchNoteOnlyList = payload;
  },
  setNewPatchNoteFromPatchNote(state, payload) {
    state.newPatchNote = payload;
  },
  setSelectIndexToPatchNote(state, payload) {
    state.selectIndex = payload;
  },
  setManagementDataToPatchNote(state, payload) {
    state.managementData = payload;
  },
  setManagementDataSystemCompanyIdToPatchNote(state, payload) {
    state.managementData.system_company_id = payload;
  },
  setManagementDataContentToPatchNote(state, payload) {
    state.managementData.content = payload;
  },
  toggleManagementDataFlagToPatchNote(state) {
    state.managementData.flag = !state.managementData.flag;
  },
  setNewDataToPatchNote(state, payload) {
    state.newData = payload;
  },
  setNewDataSystemCompanyIdToPatchNote(state, payload) {
    state.newData.system_company_id = payload;
  },
  setNewDataContentToPatchNote(state, payload) {
    state.newData.content = payload;
  },
  setSystemCompanyListToPatchNote(state, payload) {
    state.systemCompanyList = payload;
  },
};

const actions = {
  async FETCH_PATCH_NOTE({ commit }) {
    return new Promise((resolve, reject) => {
      instance
        .get('patch-note')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPatchNoteListToPatchNote', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async INSERT_PATCH_NOTE(context, payload) {
    return new Promise((resolve, reject) => {
      instance
        .post('patch-note', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async UPDATE_PATCH_NOTE(context, payload) {
    return new Promise((resolve, reject) => {
      instance
        .put('patch-note', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async DELETE_PATCH_NOTE(context, payload) {
    return new Promise((resolve, reject) => {
      instance
        .delete(`patch-note/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async FETCH_PATCH_NOTE_LIST({ commit }) {
    return new Promise((resolve, reject) => {
      instance
        .get('patch-note/list')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPatchNoteOnlyListToPatchNote', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async FETCH_PATCH_NOTE_NEW_ONE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      instance
        .get(`patch-note/one/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setNewPatchNoteFromPatchNote', response.data.data_list[0]);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async FETCH_PATCH_NOTE_SELECT_ONE(context, payload) {
    return new Promise((resolve, reject) => {
      instance
        .get(`patch-note/one/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async FETCH_ALL_SYSTEM_COMPANY({ commit }) {
    return new Promise((resolve, reject) => {
      instance
        .get('system/company')
        .then(response => {
          console.log('FETCH_SYSTEM_COMPANY', response);
          if (response.data.result == 'success') {
            commit('setSystemCompanyListToPatchNote', response.data.data_list);
            resolve(resolve);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
