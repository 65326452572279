import { companyInstance } from './index';

function testapisGet(path, payload) {
  console.log(payload);
  return companyInstance.get(path);
}
function testapisGetWithParam(path, payload) {
  console.log(payload);
  return companyInstance.get(`${path}/${payload}`);
}
function testapisPut(path, param) {
  return companyInstance.put(path, param);
}
function testapisPost(path, param) {
  return companyInstance.post(path, param);
}
function testapisDelete(path, param) {
  return companyInstance.delete(path + '/' + param);
}

export {
  testapisGet,
  testapisPost,
  testapisPut,
  testapisDelete,
  testapisGetWithParam,
};
