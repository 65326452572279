const state = {
  openTabIndex: 0,
  managementMode: false,
};

const getters = {
  getOpenTabIndexFromPurchasePage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromPurchasePage(state) {
    return state.managementMode;
  },
};
const mutations = {
  setOpenTabIndexToPurchasePage(state, idx) {
    state.openTabIndex = idx;
  },
  toggleManagementModeToPurchasePage(state) {
    state.managementMode = !state.managementMode;
  },
};

export default { state, getters, mutations };
