import { companyInstance } from '@/api/index.js';
// import clonedeep from 'lodash/cloneDeep';

const state = {
  users: [],
  users_temp: [],
  selectedIndex: -1,
  management_data: null,
  new_data: {
    name: '',
    account: '',
    email: '',
    user_role_type_id: 1,
    user_state_type_id: 1,
    password: '',
  },
  userRoleType: [],
};

const getters = {
  getUsersFromUserPage(state) {
    console.log('gettersUser', state.users);
    return state.users;
  },
  getUsersTempFromUserPage(state) {
    return state.users_temp;
  },
  getManagementDataFromUserPage(state) {
    return state.management_data;
  },
  getNewDataFromUserPage(state) {
    return state.new_data;
  },
  getUserRoleTypeFromUserPage(state) {
    return state.userRoleType;
  },
  getUserRoleTypeOptionsFromUserPage(state) {
    let options = [];
    state.userRoleType.forEach(element => {
      options.push({
        label: element.name,
        value: element.id,
      });
    });
    return options;
  },
  getTableSelectedIndexFromUserPage(state) {
    return state.selectedIndex;
  },
};

const mutations = {
  setUsersToUserPage(state, payload) {
    console.log('mutationUser,', payload);
    state.users = payload.sort((a, b) => a.id - b.id);
    state.users_temp = payload.sort((a, b) => a.id - b.id);
  },
  setSelectedIndexToUserPage(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToUserPage(state, payload) {
    state.management_data = payload;
  },
  setManageUserRoleTypeIdToUserPage(state, payload) {
    state.management_data.user_role_type_id = payload;
  },
  setNewUserRoleTypeIdToUserPage(state, payload) {
    state.new_data.user_role_type_id = payload;
  },
  setManageUserStateTypeIdToUserPage(state, payload) {
    state.management_data.user_state_type_id = payload;
  },
  setNewUserStateTypeIdToUserPage(state, payload) {
    state.new_data.user_state_type_id = payload;
  },
  setUserRolesToUserPage(state, payload) {
    state.userRoleType = payload;
  },
  clearNewDataToUserPage(state) {
    state.new_data = {
      name: '',
      account: '',
      email: '',
      user_role_type_id: 3,
      user_state_type_id: 1,
      password: '',
    };
  },
};

const actions = {
  FETCH_USER_LIST({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('user')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setUsersToUserPage', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_USER_ROLE_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('user/role')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setUserRolesToUserPage', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_USER_ROLE_TYPE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('user/role', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async CHECK_ACCOUNT(context, account) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('user/check', account)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            console.log('return response');
            resolve(response);
          }
          console.log('return reject');
          reject(response);
        })
        .catch(error => {
          console.log('return error');
          reject(error);
        });
    });
  },
  async UPDATE_USER(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('user', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async ADD_USER({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('user', payload)
        .then(response => {
          if (response.data.result == 'success') {
            commit('clearNewDataToUserPage');
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async REMOVE_USER(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`user/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
