import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  startDate: null,
  endDate: null,
  radio_material: true,
  radio_process: false,
  radio_product: false,
  material_defect_list: [],
  seq_defect_list: [],
  product_defect_list: [],
};
const getters = {
  getStartDateFromQualityPrint(state) {
    return state.startDate;
  },
  getEndDateFromQualityPrint(state) {
    return state.endDate;
  },
  getDateFromQualityPrint(state) {
    return {
      start: formatDateNoHours(state.startDate),
      end: formatDateNoHours(state.endDate),
    };
  },
  getRadioStateFromQualityPrint(state) {
    return {
      radio_material: state.radio_material,
      radio_process: state.radio_process,
      radio_product: state.radio_product,
    };
  },
  getMaterialDefectListFromQualityPrint(state) {
    return state.material_defect_list;
  },
  getSeqDefectListFromQualityPrint(state) {
    return state.seq_defect_list;
  },
  getProductDefectListFromQualityPrint(state) {
    return state.product_defect_list;
  },
};
const mutations = {
  setStartDateToQualityPrint(state, payload) {
    state.startDate = payload;
  },
  setEndDateToQualityPrint(state, payload) {
    state.endDate = payload;
  },
  setRadioStateToQualityPrint(state, payload) {
    console.log(payload);
    switch (payload) {
      case 'material':
        state.radio_material = true;
        state.radio_product = false;
        state.radio_process = false;
        break;
      case 'process':
        state.radio_material = false;
        state.radio_product = false;
        state.radio_process = true;
        break;
      case 'product':
        state.radio_material = false;
        state.radio_product = true;
        state.radio_process = false;
        break;
    }
  },
  setMaterialDefectListToQualityPrint(state, payload) {
    state.material_defect_list = payload;
  },
  setSeqDefectListToQualityPrint(state, payload) {
    state.seq_defect_list = payload;
  },
  setProductDefectListToQualityPrint(state, payload) {
    state.product_defect_list = payload;
  },
};
const actions = {
  FETCH_MATERIAL_VERIFICATION_DEFECT_ALL({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/material/verification/defect/all/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            response.data.data_list.forEach(element => {
              element.check_print = false;
            });
            commit(
              'setMaterialDefectListToQualityPrint',
              response.data.data_list.reverse(),
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_PRODUCT_VERIFICATION_DEFECT_ALL({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/product/verification/defect/all/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            response.data.data_list.forEach(element => {
              element.check_print = false;
            });
            commit(
              'setProductDefectListToQualityPrint',
              response.data.data_list.reverse(),
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_SEQ_VERIFICATION_DEFECT_ALL({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/seq/verification/defect/all/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            response.data.data_list.forEach(element => {
              element.check_print = false;
            });
            commit(
              'setSeqDefectListToQualityPrint',
              response.data.data_list.reverse(),
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
