<template>
  <div id="app">
    <GlobalEvents
      @click="handleAppClick"
      @keydown="handleKeydown"
    ></GlobalEvents>
    <router-view></router-view>
    <modal></modal>
    <session-out-modal
      v-if="isVisibleSessionOutModal"
      :session_timer="session_timer"
      @refresh="
        session_timer = Number(session_life);
        $store.commit('setShowSessionOutModalFlag', false);
      "
    ></session-out-modal>
    <ring-loader
      :loading="isSpinnerShow"
      :color="'#004a89'"
      :size="'50px'"
    ></ring-loader>
    <div v-if="isSpinnerShow" class="modal_layer" style="z-index : 9999"></div>
  </div>
</template>

<script>
import Modal from '@/layouts/components/Modal.vue';
import SessionOutModal from '@/layouts/components/SessionOutModal.vue';
import RingLoader from 'vue-spinner/src/RotateLoader';
import SpinnerMixin from '@/mixins/spinner.js';
import GlobalEvents from 'vue-global-events';
import { mapGetters } from 'vuex';
import { deleteCookie } from '@/utils/cookies.js';
export default {
  mixins: [SpinnerMixin],
  components: {
    Modal,
    RingLoader,
    SessionOutModal,
    GlobalEvents,
  },
  data() {
    return {
      // session
      session_timer: null,
      session_interval: null,
    };
  },
  computed: {
    ...mapGetters({
      isVisibleSessionOutModal: 'getShowSessionOutModalFlag',
      session_life: 'getSessionLife',
      userId: 'getUserId',
      favoriteList: 'getFavoriteFromFavoriteMenuPage',
      users: 'getUsersFromUserPage',
    }),
  },
  methods: {
    handleAppClick() {
      if (this.isVisibleSessionOutModal) return;
      this.session_timer = Number(this.session_life);
    },
    handleKeydown() {
      if (this.isVisibleSessionOutModal) return;
      this.session_timer = Number(this.session_life);
    },
    async checkSession() {
      const beforeSession =
        sessionStorage.getItem('session') != null
          ? JSON.parse(sessionStorage.getItem('session'))
          : null;
      if (beforeSession != null) {
        await this.$store
          .dispatch('CHECK_SESSION_ACCOUNT', this.userId)
          .then(dbSession => {
            if (dbSession != beforeSession.key) {
              deleteCookie('user');
              deleteCookie('auth');
              // clearInterval(this.session_interval);
              // this.session_interval = null;
              this.$store.commit('setShowSessionOutModalFlag', false);
              this.$router.push('/login');
              return false;
            } else {
              return true;
            }
          })
          .catch(error => {
            console.log(error);
            deleteCookie('user');
            deleteCookie('auth');
            // clearInterval(this.session_interval);
            // this.session_interval = null;
            this.$store.commit('setShowSessionOutModalFlag', false);
            this.$router.push('/login');
            return false;
          });
      } else {
        deleteCookie('user');
        deleteCookie('auth');
        // clearInterval(this.session_interval);
        // this.session_interval = null;
        this.$store.commit('setShowSessionOutModalFlag', false);
        this.$router.push('/login');

        return false;
      }
    },
  },
  watch: {
    session_timer(newValue) {
      if (
        this.$route.path.includes('monitor') ||
        this.$route.path.includes('login')
      ) {
        this.session_timer = Number(this.session_life);
        return;
      } else {
        if (
          this.userId != undefined &&
          this.users.length > 0 &&
          this.favoriteList.length < 1
        ) {
          const foundUser = this.users.find(x => x.account == this.userId);
          this.$store
            .dispatch('FETCH_FAVORITE_MENU', foundUser.id)
            .then(() => {})
            .catch(error => {
              console.log(error);
            });
        }
      }
      if (newValue == 20) {
        this.$store.commit('setShowSessionOutModalFlag', true);
      } else if (newValue == 0) {
        // clearInterval(this.session_interval);
        // this.session_interval = null;
        deleteCookie('user');
        deleteCookie('auth');

        const beforeSession =
          sessionStorage.getItem('session') != null
            ? JSON.parse(sessionStorage.getItem('session')).key
            : null;
        if (beforeSession != null) {
          this.$store.dispatch('DELETE_SESSION', beforeSession);
        }
        sessionStorage.removeItem('session');
      } else if (newValue % 5 == 0) {
        this.checkSession();
      }
    },
  },
  created() {
    if (this.session_interval == null) {
      this.session_timer = Number(this.session_life);
      this.session_interval = setInterval(() => {
        this.session_timer--;
      }, 1000);
    }
  },
};
</script>

<style lang="scss">
@import './assets/scss/style.scss';
#app {
  height: 100%;
}

.v-spinner {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
