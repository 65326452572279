<template>
  <div class="modalComponent">
    <div class="modal" id="errorModal" style="z-index : 9998">
      <!-- style="top: 50%; left: 50%" -->
      <div class="modal_header">
        <h3 class="title">
          {{ sessionTimeCheck ? '자동로그아웃 알림' : '자동 로그아웃' }}
        </h3>
      </div>
      <div class="modal_body" v-html="modalContent"></div>
      <div class="modal_footer">
        <!-- <button onclick="modalClose('#modal1')">취소</button> -->
        <button @click="sessionTimeCheck ? CloseModal() : logout()">
          확인
        </button>
      </div>
    </div>
    <div style="z-index : 9997" class="modal_layer"></div>
  </div>
</template>

<script>
// import ModalMixin from '@/mixins/modal';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  mixins: [DRAG_MODAL_MIXIN],
  props: {
    session_timer: {
      type: Number,
      require: true,
    },
  },
  computed: {
    sessionTimeCheck() {
      return this.session_timer > 0;
    },
    modalContent() {
      return this.sessionTimeCheck
        ? `장시간 사용하지 않아 곧 로그아웃됩니다.<br />로그인을 유지하시려면 확인을 눌러주세요.<br /><br />남은시간 : ${this.session_timer}초`
        : '30분간 시스템을 사용하지 않아 자동로그아웃 되었습니다.';
    },
  },
  methods: {
    CloseModal() {
      this.$emit('refresh');
    },
    logout() {
      this.$router.push('/login');
      this.$emit('refresh');
    },
  },
};
</script>

<style scoped></style>
