import { companyInstance } from '@/api';
// import { yyyymmdd } from '@/utils/func';

const state = {
  tank_log: [],
  tank_temp_log: [],
  start_date: null,
  end_date: null,
  tank_filter: {
    tank1: true,
    tank2: true,
    tank3: true,
    tank4: true,
  },
  ph_or_temp_filter: {
    ph: true,
    temp: true,
  },
};
const getters = {
  getTankFilterFromDailyTankLogPage(state) {
    return state.tank_filter;
  },
  getPhOrTempFilterFromDailyTankLogPage(state) {
    return state.ph_or_temp_filter;
  },
  getTankLogFromDailyTankLogPage(state) {
    return state.tank_log;
  },
  getTankTempLogFromDailyTankLogPage(state) {
    return state.tank_temp_log;
  },
  getStartDateFromDailyTankLogPage(state) {
    return state.start_date;
  },
  getEndDateFromDailyTankLogPage(state) {
    return state.end_date;
  },
};
const mutations = {
  setTankLogToDailyTankLogPage(state, payload) {
    const obj = payload.reduce(function(rv, x) {
      (rv[x['plc_sensor_id']] = rv[x['plc_sensor_id']] || []).push(x);
      return rv;
    }, {});
    let list_ = [];

    for (let k in obj) {
      list_.push({
        plc_sensor_id: Number(k),
        element: obj[k].sort(
          (a, b) => new Date(a.input_date) - new Date(b.input_date),
        ),
      });
    }
    state.tank_log = list_.filter(x => [5, 6, 7, 8].includes(x.plc_sensor_id));
    state.tank_temp_log = list_.filter(x =>
      [1, 2, 3, 4].includes(x.plc_sensor_id),
    );
  },

  setStartDateToDailyTankLogPage(state, payload) {
    state.start_date = payload;
  },
  setEndDateToDailyTankLogPage(state, payload) {
    state.end_date = payload;
  },
};
const actions = {
  FETCH_DAILY_TANK_LOG_DAY({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plc/sensor-log/day-not-view`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setTankLogToDailyTankLogPage', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
