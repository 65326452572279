import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index.js';
import routes from './routes.js';
import { deleteCookie } from '@/utils/cookies.js';

//import store from '@/store/index';
// import LoginPage from '@/views/LoginPage.vue';
// import SignupPage from '@/views/SignupPage.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    // console.log('err', err);
    // if (err.name !== 'NavigationDuplicated') throw err;
  });
};

router.beforeEach(async (to, from, next) => {
  // console.log('... to ... > ', to.path);
  console.log(to.path);
  if (to.path.includes('/login')) {
    next();
    return;
  }
  store.commit('addOpenedMenu', to);
  if (!to.meta.noAuth && !store.getters.isLogin) {
    console.log('인증이 필요합니다');
    next('/login');
    return;
  } else {
    if (store.getters.getUsersFromUserPage.length == 0) {
      await store.dispatch('FETCH_USER_LIST');
    }
    if (store.getters.getMenuItems.length == 0) {
      await store.dispatch('FETCH_ALL_MENUS');
    }
    await store
      .dispatch('CHECK_SESSION_ACCOUNT', store.getters.getUserId)
      .then(async checkedSession => {
        let existSession = sessionStorage.getItem('session');
        if (existSession == null) {
          deleteCookie('user');
          deleteCookie('auth');
          next('/login');
          return;
        } else {
          existSession = JSON.parse(existSession);
          if (existSession.key == checkedSession) {
            next();
            return;
          } else {
            deleteCookie('user');
            deleteCookie('auth');
            next('/login');
            return;
          }
        }
      })
      .catch(error => {
        console.log(error);
        deleteCookie('user');
        deleteCookie('auth');
        next('/login');
        return;
      });
  }
});

export default router;
