const state = {
  showLotSearch: false,
};

const getters = {
  getShowLotSearchFromProductionPage(state) {
    return state.showLotSearch;
  },
};
const mutations = {
  setShowLotSearchToProductionPage(state, payload) {
    state.showLotSearch = payload;
  },
};

export default { state, getters, mutations };
