import { companyInstance } from '@/api/index';
const state = {
  process: [],
  selectedIndex: -1,
  visibleDel: false,
  managementData: {
    id: -1,
    name: '',
    detail: '',
    machine_id: null,
    package_process_yn: false,
    process_verification_id: null,
  },
  newData: {
    name: '',
    detail: '',
    machine_id: null,
    package_process_yn: false,
    process_verification_id: null,
  },
};

const getters = {
  getProcess(state) {
    return state.process.sort((a, b) => a.id - b.id);
  },
  getVisibleProcess(state) {
    return state.process.filter(x => x.visible).sort((a, b) => a.id - b.id);
  },
  getDeletedProcess(state) {
    return state.process.filter(x => !x.visible).sort((a, b) => a.id - b.id);
  },
  getShowDeleteProcessFlag(state) {
    return state.visibleDel;
  },
  getSelectedIndexFromProcess(state) {
    return state.selectedIndex;
  },
  getManagementDataFromProcess(state) {
    return state.managementData;
  },
  getNewDataFromProcess(state) {
    return state.newData;
  },
};

const mutations = {
  setProcess(state, payload) {
    state.process = payload;
  },
  setShowDeleteProcessFlag(state, payload) {
    state.visibleDel = payload;
  },
  setSelectedIndexToProcess(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToProcess(state, payload) {
    state.managementData = payload;
  },
  setNewDataToProcess(state, payload) {
    state.newData = payload;
  },
  setManagementDataMachineIdToProcess(state, id) {
    state.managementData.machine_id = id;
  },
  setNewDataMachineIdToProcess(state, id) {
    state.newData.machine_id = id;
  },
};

const actions = {
  FETCH_PROCESS({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/process/info')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setProcess', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_PROCESS(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/process/info', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_PROCESS(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/process/info', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_PROCESS(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/process/info/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  RESTORE_PROCESS(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/process/info/restore/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
