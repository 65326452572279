const state = {
  subtabs: [
    { name: 'unit_management', title: '단위 관리' },
    { name: 'unit_conversion_management', title: '단위 환산 관리' },
    { name: 'warehouse_management', title: '창고 관리' },
    { name: 'machine_management', title: '설비기기 관리' },
    { name: 'box_management', title: '박스포장 관리' },
  ],
  openTabIndex: 0,
  managementMode: false,
};
const getters = {
  getSubTabsFromCodePage(state) {
    return state.subtabs;
  },
  getOpenTabIndexFromCodePage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromCodePage(state) {
    return state.managementMode;
  },
};
const mutations = {
  setOpenTabIndexToCodePage(state, idx) {
    state.openTabIndex = idx;
  },
  toggleManagementModeToCodePage(state) {
    state.managementMode = !state.managementMode;
  },
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
