import { companyInstance } from '@/api';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  startDate: null,
  endDate: null,
  radioList: {
    check_all: true,
    check_include: false,
    check_exclude: false,
    checkIn: '',
  },
  filterArray: [],
  filterCheck: [-1],
  productLedgerList: [],
  include_company_list: [],
};

const getters = {
  getStartDateFromProductLedger(state) {
    return state.startDate;
  },
  getEndDateFromProductLedger(state) {
    return state.endDate;
  },
  getFilterArrayFromProductLedger(state) {
    return state.filterArray;
  },
  getFilterCheckFromProductLedger(state) {
    return state.filterCheck;
  },
  getProductLedgerListFromProductLedger(state) {
    return state.productLedgerList;
  },
  getCheckedRadioFromProductLedger(state) {
    return state.radioList;
  },
  getCheckedBoxFromProductLedger(state) {
    return state.checkBoxList;
  },
  getIncludeCompanyListFromProductLedger(state) {
    return state.include_company_list;
  },
};

const mutations = {
  setStartDateToProductLedger(state, payload) {
    state.startDate = payload;
  },
  setEndDateToProductLedger(state, payload) {
    state.endDate = payload;
  },
  setFilterArrayToProductLedger(state, payload) {
    state.filterArray = payload;
  },
  setFilterCheckToProductLedger(state, payload) {
    state.filterCheck = payload;
  },
  setCheckedRadioToProductLedger(state, payload) {
    if (payload == 'check_all') {
      state.radioList.check_all = true;
      state.radioList.check_include = false;
      state.radioList.check_exclude = false;
      state.radioList.checkIn = '';
    } else if (payload == 'check_include') {
      state.radioList.check_all = false;
      state.radioList.check_include = true;
      state.radioList.check_exclude = false;
      state.radioList.checkIn = '포함';
    } else if (payload == 'check_exclude') {
      state.radioList.check_all = false;
      state.radioList.check_include = false;
      state.radioList.check_exclude = true;
      state.radioList.checkIn = '제외';
    } else {
      return '';
    }
  },

  setProductLedgerListToProductLedger(state, payload) {
    state.productLedgerList = payload;
  },
};
const actions = {
  FETCH_PRODUCT_LEDGER({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `mag/product/in-out/barcode/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit(
              'setProductLedgerListToProductLedger',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
