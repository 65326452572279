import { companyInstance } from '@/api';
import { yyyymmdd } from '@/utils/func';

const state = {
  productStatusList: [],
  stockStatusList: [],
};
const getters = {
  getProductStatusListFromDailyOutput(state) {
    return state.productStatusList.sort(
      (a, b) =>
        Number(b.input_date.replace(/-/gi, '')) -
        Number(a.input_date.replace(/-/gi, '')),
    );
  },
};
const mutations = {
  setProductStatusListToDailyOutput(state, payload) {
    state.productStatusList = payload;
  },
};
const actions = {
  FETCH_PRODUCT_STATUS_DAY_DAILY({ commit }) {
    return new Promise((resolve, reject) => {
      const newDate = new Date();
      let endDate = new Date();
      endDate.setDate(endDate.getDate() + 1);
      companyInstance
        .get(
          `mag/product/in-out/barcode/start_date=${yyyymmdd(
            newDate,
          )}&end_date=${yyyymmdd(endDate)}`,
        )
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            console.log(response);
            commit(
              'setProductStatusListToDailyOutput',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
