<template>
  <div class="modalComponent">
    <div
      v-show="isModalOpen"
      class="modal"
      :class="{ not_production: modal_title == '생산불가' }"
      id="errorModal"
      style="z-index : 9998"
      :style="isModalOpen == false ? 'top: 50%; left: 50%;' : ''"
    >
      <div class="modal_header">
        <h3 class="title">{{ modal_title }}</h3>
        <button class="modal_close" @click="CloseModal"></button>
      </div>
      <div class="modal_body" v-html="modal_content"></div>
      <div class="modal_footer">
        <!-- <button onclick="modalClose('#modal1')">취소</button> -->
        <button @click="CloseModal">
          확인
        </button>
      </div>
    </div>
    <div
      v-if="isModalOpen"
      style="z-index : 9997"
      class="modal_layer"
      @click="CloseModal"
    ></div>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  mixins: [ModalMixin, DRAG_MODAL_MIXIN],
};
</script>

<style scoped></style>
