import { companyInstance } from '@/api/index';

const state = {
  collections: [], // 가져온 데이터
  selectedIndex: -1, // 선택한 줄
  start_date: null,
  end_date: null,
  company_start_date: null,
  company_end_date: null,
  search_type: null, // 수금구분 필터
  managementData: {
    id: -1,
    company_id: -1, // 매출처
    collection_type_id: null, // 수금 구분
    total_value: 0, // 수금액
    input_date: '', // 수금일
    collecting_date: null,
  },
  newData: {
    company_id: -1,
    collection_type_id: null,
    total_value: 0,
    input_date: '',
    collecting_date: null,
  },
  cloneData: {
    id: -1,
    company_id: -1,
    collection_type_id: null,
    total_value: 0,
    input_date: '',
    collecting_date: null,
  },
};

const getters = {
  getCollection(state) {
    return state.collections
      .sort((a, b) => b.id - a.id)
      .sort((a, b) => new Date(b.input_date) - new Date(a.input_date));
  },
  getSelectedIndexFromCollection(state) {
    return state.selectedIndex;
  },
  getStartDateFromCollection(state) {
    return state.start_date;
  },
  getEndDateFromCollection(state) {
    return state.end_date;
  },
  getCompanyStartDateFromCollection(state) {
    return state.company_start_date;
  },
  getCompanyEndDateFromCollection(state) {
    return state.company_end_date;
  },
  getSearchTypeFromCollection(state) {
    return state.search_type;
  },
  getManagementDataFromCollection(state) {
    return state.managementData;
  },
  getNewDataFromCollection(state) {
    return state.newData;
  },
  getCloneDataFromCollection(state) {
    return state.cloneData;
  },
};

const mutations = {
  setCollection(state, payload) {
    state.collections = payload;
  },
  setSelectedIndexToCollection(state, index) {
    state.selectedIndex = index;
  },
  setStartDateToCollection(state, payload) {
    state.start_date = payload;
  },
  setEndDateToCollection(state, payload) {
    state.end_date = payload;
  },
  setCompanyStartDateToCollection(state, payload) {
    state.company_start_date = payload;
  },
  setCompanyEndDateToCollection(state, payload) {
    state.company_end_date = payload;
  },
  setSearchTypeToCollection(state, payload) {
    state.search_type = payload;
  },

  // 기존 데이터
  setManagementDataToCollection(state, payload) {
    state.managementData = payload;
  },

  setManagementCollectionTypeId(state, collection_type_id) {
    state.managementData.collection_type_id = collection_type_id;
  },

  // 새로운 데이터
  setNewDataToCollection(state, payload) {
    state.newData = payload;
  },

  setNewCollectionTypeId(state, collection_type_id) {
    state.newData.collection_type_id = collection_type_id;
  },

  // 수정하는 데이터
  setCloneDataToCollection(state, payload) {
    state.cloneData = payload;
  },
};

const actions = {
  FETCH_COLLECTION({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`collection/start=${payload.start}&end=${payload.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setCollection', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_COLLECTION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('collection', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_COLLECTION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('collection', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_COLLECTION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`collection/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
