import { companyInstance } from '@/api/index';
// import clonedeep from 'lodash/cloneDeep';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  start_date: null,
  end_date: null,
  uwon_xray_log: [],
  uwon_xray_log_now: [],
};
const getters = {
  getStartDateFromUwonXrayLog(state) {
    return state.start_date;
  },
  getEndDateFromUwonXrayLog(state) {
    return state.end_date;
  },
  getUwonXrayLog(state) {
    return state.uwon_xray_log;
  },
  getUwonXrayLogNow(state) {
    return state.uwon_xray_log_now;
  },
};
const mutations = {
  setStartDateToUwonXrayLog(state, payload) {
    state.start_date = payload;
  },
  setEndDateToUwonXrayLog(state, payload) {
    state.end_date = payload;
  },
  setUwonXrayLog(state, payload) {
    state.uwon_xray_log = payload;
  },
  setUwonXrayLogNow(state, payload) {
    state.uwon_xray_log_now = payload;
  },
};
const actions = {
  FETCH_UWON_XRAY_LOG({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(`custom/uwon_xray_log/start=${newDate.start}&end=${newDate.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setUwonXrayLog', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_UWON_XRAY_LOG_NOW({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`custom/uwon_xray_log_now`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setUwonXrayLogNow', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
