const state = {
  subtabs: [
    { name: 'modification', title: '조회&수정' },
    { name: 'registration', title: '등록' },
  ],
  openTabIndex: 0,
  managementMode: false,
};

const getters = {
  getManagementModeFromUserPage(state) {
    return state.managementMode;
  },
  getSubTabsFromUserPage(state) {
    return state.subtabs;
  },
  getOpenTabIndexFromUserPage(state) {
    return state.openTabIndex;
  },
};

const mutations = {
  toggleManagementModeToUserPage(state) {
    state.managementMode = !state.managementMode;
  },
  setOpenTabIndexToUserPage(state, index) {
    state.openTabIndex = index;
  },
};

export default {
  state,
  getters,
  mutations,
};
