import { companyInstance } from '@/api/index';
import clonedeep from 'lodash/cloneDeep';

const state = {
  material_control: [],
  material_total_stock: [],
  material_occupied_stock: [],
};

const getters = {
  getMaterialControl(state) {
    return state.material_control;
  },
  getMaterialTotalStock(state) {
    return state.material_total_stock;
  },
  getMaterialOccupiedStock(state) {
    return state.material_occupied_stock;
  },
};
const mutations = {
  setMaterialControl(state, payload) {
    payload = payload.map(x => {
      x.is_modify = false;
      x.quantity_temp = clonedeep(x.quantity);
      return x;
    });
    state.material_control = payload;
  },
  setMaterialTotalStock(state, payload) {
    state.material_total_stock = payload;
  },
  setMaterialOccupiedStock(state, payload) {
    state.material_occupied_stock = payload;
  },
};
const actions = {
  async FETCH_MATERIAL_TOTAL_STOCK({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/material/stock')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setMaterialTotalStock', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async FETCH_MATERIAL_OCCUPIED_STOCK({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/material/occupied-stock')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setMaterialOccupiedStock', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async FETCH_MATERIAL_CONTROL({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/material/control')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setMaterialControl', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async INSERT_MATERIAL_CONTROL_N_IN_OUT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('mag/material/control/all', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async UPDATE_MATERIAL_CONTROL_STOCK(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('mag/material/control/stock', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
