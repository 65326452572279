import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  companyManagementData: {},
  companySelectedIndex: -1,
  purchase_account: [],
  inputSelectedIndex: -1,
  inputManagementData: null,
  purchaseManagementData: null,
  input_start_date: null,
  input_end_date: null,
  input_date: null,
};

const getters = {
  getCompanySelectedIndexFromInput(state) {
    return state.companySelectedIndex;
  },
  getInputDateFromInput(state) {
    return state.input_date;
  },
  getCompanyManagementDataFromInput(state) {
    return state.companyManagementData;
  },
  getCompanyManagementDataMaterialListFromInput(state) {
    if (state.companyManagementData.material_list != undefined) {
      return state.companyManagementData.material_list.filter(
        x => x.input_yn == false,
      );
    } else return state.companyManagementData.material_list;
  },
  getPurchaseAccountFromInput(state) {
    return state.purchase_account;
  },
  getInputSelectedIndexFromInput(state) {
    return state.inputSelectedIndex;
  },
  getInputManagementDataFromInput(state) {
    return state.inputManagementData;
  },
  getPurchaseManagementDataFromInput(state) {
    return state.purchaseManagementData;
  },
  getDateFromInput(state) {
    return {
      start: formatDateNoHours(state.input_start_date),
      end: formatDateNoHours(state.input_end_date),
    };
  },
  getInputStartDateFromInput(state) {
    return state.input_start_date;
  },
  getInputEndDateFromInput(state) {
    return state.input_end_date;
  },
};
const mutations = {
  setCompanySelectedIndexToInput(state, index) {
    state.companySelectedIndex = index;
  },
  setInputDateToInput(state, payload) {
    state.input_date = payload;
  },
  setCompanyManagementDataToInput(state, payload) {
    state.companyManagementData = payload;
  },

  setCompanyManagementStoreIdToInput(state, payload) {
    state.companyManagementData.material_list.filter(x => x.input_yn == false)[
      payload.index
    ].store_id = payload.value;
  },
  setCompanyManagementLocationIdToInput(state, payload) {
    state.companyManagementData.material_list.filter(x => x.input_yn == false)[
      payload.index
    ].location_id = payload.value;
  },
  setPurchaseAccountToInput(state, payload) {
    state.purchase_account = payload;
  },
  setInputSelectedIndexToInput(state, payload) {
    state.inputSelectedIndex = payload;
  },
  setInputManagementDataToInput(state, payload) {
    state.inputManagementData = payload;
  },
  setPurchaseManagementDataToInput(state, payload) {
    state.purchaseManagementData = payload;
  },
  setInputStartDateToInput(state, payload) {
    state.input_start_date = payload;
  },
  setInputEndDateToInput(state, payload) {
    state.input_end_date = payload;
  },
};

const actions = {
  FETCH_PURCHASE_ACCOUNT_ALL({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('plan/purchase/account/all')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPurchaseAccountToInput', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PURCHASE_ACCOUNT_DAY_ALL({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `plan/purchase/account/all/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setPurchaseAccountToInput',
              response.data.data_list.reverse(),
            );
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_PURCHASE_ACCOUNT_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete('plan/purchase/account/all', { data: payload })
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_PURCHASE_ACCOUNT_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plan/purchase/account/all', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
