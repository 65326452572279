import { companyInstance } from '@/api/index.js';
import clonedeep from 'lodash/cloneDeep';

const state = {
  boxs: [],
  selectedIndex: -1,
  manangementData: {
    id: -1,
    name: '',
    detail: '',
    quantity: 0,
    r_box_unit_material_list: [],
  },
  cloneData: {
    id: -1,
    name: '',
    detail: '',
    quantity: 0,
    r_box_unit_material_list: [],
  },
};
const getters = {
  getBoxs(state) {
    return state.boxs.sort((a, b) => a.id - b.id);
  },
  getBoxsNotSorted(state) {
    return state.boxs;
  },
  getManagementDataFromBox(state) {
    return state.manangementData;
  },
  getCloneDataFromBox(state) {
    return state.cloneData;
  },
  getSelectedIndexFromBox(state) {
    return state.selectedIndex;
  },
  getBoxForSelectric(state) {
    let options = [];
    options.push({ label: '낱개', value: null, detail: '' });
    const types = clonedeep(state.boxs).sort((a, b) => a.id - b.id);
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
};
const mutations = {
  setBoxs(state, payload) {
    state.boxs = payload;
  },
  setSelectedIndexToBox(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToBox(state, payload) {
    state.manangementData = payload;
  },
  setCloneDataToBox(state, payload) {
    state.cloneData = payload;
  },
};
const actions = {
  async FETCH_BOX({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/box/unit')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setBoxs', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_BOX(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/box/unit', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_PACKAGE_BOX(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/box/unit/material', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_BOX(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/box/unit', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async DELETE_BOX(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete('base/box/unit', { data: payload })
        .then(response => {
          console.log('payload', payload);
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
