import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  //return
  returnableList: [],
  returnableListAll: [],
  selectedIndex: -1,
  searchText: '',
  searchText2: '',
  companySearchText: '',
  managementData: null,
  start_date: null,
  end_date: null,

  //returnStatus
  return_list: [],
  status_selectedIndex: -1,
  status_searchText: '',
  status_companySearchText: '',
  status_managementData: null,
  status_start_date: null,
  status_end_date: null,
};
const getters = {
  //return
  getStartDateFromMaterialReturn(state) {
    return state.start_date;
  },
  getEndDateFromMaterialReturn(state) {
    return state.end_date;
  },
  getReturnableListFromMaterialReturn(state) {
    return state.returnableList.sort(
      (a, b) =>
        Number(
          b.create_time
            .replace(/-/gi, '')
            .replace(/:/gi, '')
            .replace(' ', ''),
        ) -
        Number(
          a.create_time
            .replace(/-/gi, '')
            .replace(/:/gi, '')
            .replace(' ', ''),
        ),
    );
  },
  getReturnableListFromMaterialReturnAll(state) {
    return state.returnableListAll.sort(
      (a, b) =>
        Number(
          b.create_time
            .replace(/-/gi, '')
            .replace(/:/gi, '')
            .replace(' ', ''),
        ) -
        Number(
          a.create_time
            .replace(/-/gi, '')
            .replace(/:/gi, '')
            .replace(' ', ''),
        ),
    );
  },
  getSelectedIndexFromMaterialReturn(state) {
    return state.selectedIndex;
  },
  getSearchTextFromMaterialReturn(state) {
    return state.searchText;
  },
  getSearchTextFromMaterialReturnOut(state) {
    return state.searchText2;
  },
  getCompanySearchTextFromMaterialReturn(state) {
    return state.companySearchText;
  },
  getManagementDataFromMaterialReturn(state) {
    return state.managementData;
  },

  //returnStatus
  getStartDateFromMaterialReturnStatus(state) {
    return state.status_start_date;
  },
  getEndDateFromMaterialReturnStatus(state) {
    return state.status_end_date;
  },
  getReturnListFromMaterialReturnStatus(state) {
    return state.return_list.sort(
      (a, b) =>
        new Date(b.purchase.input_date) - new Date(a.purchase.input_date),
    );
  },
  getSelectedIndexFromMaterialReturnStatus(state) {
    return state.status_selectedIndex;
  },
  getSearchTextFromMaterialReturnStatus(state) {
    return state.status_searchText;
  },
  getCompanySearchTextFromMaterialReturnStatus(state) {
    return state.status_companySearchText;
  },
  getManagementDataFromMaterialReturnStatus(state) {
    return state.status_managementData;
  },
};
const mutations = {
  //return
  setStartDateToMaterialReturn(state, payload) {
    state.start_date = payload;
  },
  setEndDateToMaterialReturn(state, payload) {
    state.end_date = payload;
  },
  setReturnableListToMaterialReturn(state, payload) {
    state.returnableList = payload;
  },
  setReturnableListToMaterialReturnAll(state, payload) {
    state.returnableListAll = payload;
  },
  setSelectedIndexToMaterialReturn(state, payload) {
    state.selectedIndex = payload;
  },
  setSearchTextToMaterialReturn(state, payload) {
    state.searchText = payload;
  },
  setSearchTextToMaterialReturnOut(state, payload) {
    state.searchText2 = payload;
  },
  setCompanySearchTextToMaterialReturn(state, payload) {
    state.companySearchText = payload;
  },
  setManagementDataToMaterialReturn(state, payload) {
    state.managementData = payload;
  },
  setManagementDataReturnTypeToMaterialReturn(state, payload) {
    state.managementData.material_in_out_type_id = payload;
  },

  //returnStatus
  setStartDateToMaterialReturnStatus(state, payload) {
    state.status_start_date = payload;
  },
  setEndDateToMaterialReturnStatus(state, payload) {
    state.status_end_date = payload;
  },
  setReturnListToMaterialReturnStatus(state, payload) {
    state.return_list = payload;
  },
  setSelectedIndexToMaterialReturnStatus(state, payload) {
    state.status_selectedIndex = payload;
  },
  setSearchTextToMaterialReturnStatus(state, payload) {
    state.status_searchText = payload;
  },
  setCompanySearchTextToMaterialReturnStatus(state, payload) {
    state.status_companySearchText = payload;
  },
  setManagementDataToMaterialReturnStatus(state, payload) {
    state.status_managementData = payload;
  },
};

const actions = {
  FETCH_RETURNABLE_MATERIAL_LIST({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/material/control/purchase/material')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit(
              'setReturnableListToMaterialReturnAll',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_RETURNABLE_MATERIAL_LIST_DATE({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/material/control/purchase/material/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit(
              'setReturnableListToMaterialReturn',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_MATERIAL_RETURN(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('plan/purchase/return', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_MATERIAL_RETURN_LIST_DATE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `plan/purchase/return/start_date=${payload.start}&end_date=${payload.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit(
              'setReturnListToMaterialReturnStatus',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DELETE_MATERIAL_RETURN(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`plan/purchase/return/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
