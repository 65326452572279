import { companyInstance } from '@/api/index';

const state = {
  collections: [],
  selectedIndex: -1,
  start_date: null,
  end_date: null,
  search_type: null,
  managementData: {
    id: -1,
    company_id: -1,
    collection_type_id: null,
    total_value: 0,
    input_date: '',
    collecting_date: '',
    sales_product_id: null,
  },
  radioList: {
    check_all: true,
    check_include: false,
    check_exclude: false,
    checkIn: '',
  },
  filterArray: [],
};

const getters = {
  getExchangeCollection(state) {
    return state.collections
      .sort((a, b) => b.id - a.id)
      .sort((a, b) => new Date(b.input_date) - new Date(a.input_date));
  },
  getSelectedIndexFromExchangeCollection(state) {
    return state.selectedIndex;
  },
  getStartDateFromExchangeCollection(state) {
    return state.start_date;
  },
  getEndDateFromExchangeCollection(state) {
    return state.end_date;
  },
  getSearchTypeFromExchangeCollection(state) {
    return state.search_type;
  },
  getManagementDataFromExchangeCollection(state) {
    return state.managementData;
  },
  getCheckedRadioFromTransactionReport(state) {
    return state.radioList;
  },
  getFilterArrayFromTransactionReport(state) {
    return state.filterArray;
  },
};

const mutations = {
  setExchangeCollection(state, payload) {
    state.collections = payload;
  },
  setSelectedIndexToExchangeCollection(state, index) {
    state.selectedIndex = index;
  },
  setStartDateToExchangeCollection(state, payload) {
    state.start_date = payload;
  },
  setEndDateToExchangeCollection(state, payload) {
    state.end_date = payload;
  },
  setSearchTypeToExchangeCollection(state, payload) {
    state.search_type = payload;
  },
  setManagementDataToExchangeCollection(state, payload) {
    state.managementData = payload;
  },
  setFilterArrayToTransactionReport(state, payload) {
    state.filterArray = payload;
  },
  setCheckedRadioToTransactionReport(state, payload) {
    if (payload == 'check_all') {
      state.radioList.check_all = true;
      state.radioList.check_include = false;
      state.radioList.check_exclude = false;
      state.radioList.checkIn = '';
    } else if (payload == 'check_include') {
      state.radioList.check_all = false;
      state.radioList.check_include = true;
      state.radioList.check_exclude = false;
      state.radioList.checkIn = '포함';
    } else if (payload == 'check_exclude') {
      state.radioList.check_all = false;
      state.radioList.check_include = false;
      state.radioList.check_exclude = true;
      state.radioList.checkIn = '제외';
    } else {
      return '';
    }
  },
};

const actions = {
  FETCH_EXCHANGE_COLLECTION({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`collection/start=${payload.start}&end=${payload.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setExchangeCollection', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_EXCHANGE_COLLECTION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('collection', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_EXCHANGE_COLLECTION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('collection', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_EXCHANGE_COLLECTION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`collection/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
