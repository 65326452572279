const state = {
  isSpinnerShow: false,
};
const getters = {
  getIsSpinnerShow(state) {
    return state.isSpinnerShow;
  },
};
const mutations = {
  showSpinner(state) {
    state.isSpinnerShow = true;
  },
  hideSpinner(state) {
    state.isSpinnerShow = false;
  },
};

export default {
  state,
  getters,
  mutations,
};
