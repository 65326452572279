const state = {
  openTabIndex: 0,
  managementMode: false,
};

const getters = {
  getOpenTabIndexFromProcessPage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromProcessPage(state) {
    return state.managementMode;
  },
};
const mutations = {
  setOpenTabIndexToProcessPage(state, idx) {
    state.openTabIndex = idx;
  },
  toggleManagementModeToProcessPage(state) {
    state.managementMode = !state.managementMode;
  },
};

export default { state, getters, mutations };
