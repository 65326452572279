import { companyInstance } from '@/api';
// import { cloneDeep } from 'lodash';

const state = {
  searchText: '',
  targetUser: null,
  filterCheck: [],
  editCheck: '',
};
const getters = {
  getSearchTextFromAuthority(state) {
    return state.searchText;
  },
  getTargetUserFromAuthority(state) {
    return state.targetUser;
  },
  getFilterCheckFromAuthority(state) {
    return state.filterCheck.sort((a, b) => a.id - b.id);
  },
  getEditCheckFromAuthority(state) {
    return state.editCheck;
  },
};
const mutations = {
  setSearchTextToAuthority(state, payload) {
    state.searchText = payload;
  },
  setTargetUserToAuthority(state, payload) {
    state.targetUser = payload;
  },
  setFilterCheckToAuthority(state, payload) {
    const sortCheck = payload.sort((a, b) => a.id - b.id);
    sortCheck.splice(sortCheck.length - 1, 1);
    state.filterCheck = sortCheck;
    state.editCheck = JSON.stringify(sortCheck);
  },
};
const actions = {
  async FETCH_USER_AUTH({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`user/menu/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setFilterCheckToAuthority', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_USER_AUTH(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('menu/user/all', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
