import { companyInstance } from '@/api';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  startDate: null,
  endDate: null,
  searchText: '',
  companyText: '',
  searchStockText: '',
  selectedIndex: -1,
  productStatusList: [],
  productStatusGroupList: [],
  stockStatusList: [],
  modalClose: true,
  searchStoreLocation: null,
  searchProductResource: null,
  exceptStockZero: false,
};
const getters = {
  getStartDateFromProductStatus(state) {
    return state.startDate;
  },
  getEndDateFromProductStatus(state) {
    return state.endDate;
  },
  getDateFromProductStatus(state) {
    return {
      start: formatDateNoHours(state.startDate),
      end: formatDateNoHours(state.endDate),
    };
  },
  getSearchTextFromProductStatus(state) {
    return state.searchText;
  },
  getCompanyTextFromProductStatus(state) {
    return state.companyText;
  },
  getProductStatusListFromProductStatus(state) {
    return state.productStatusList.sort(
      (a, b) =>
        Number(b.input_date.replace(/-/gi, '')) -
        Number(a.input_date.replace(/-/gi, '')),
    );
  },
  getSearchStockTextFromProductStockStatus(state) {
    return state.searchStockText;
  },
  getProductStockStatusListFromProductStockStatus(state) {
    return state.stockStatusList;
  },
  getProductStockStatusGroupListFromProductStockStatus(state) {
    return state.productStatusGroupList;
  },
  getSelectedIndexFromProductStock(state) {
    return state.selectedIndex;
  },
  getModalCloseFromProductStock(state) {
    return state.modalClose;
  },
  getSearchStoreLocationFromProductStockStatus(state) {
    return state.searchStoreLocation;
  },
  getSearchProductResourceFromProductStock(state) {
    return state.searchProductResource;
  },
  getCheckExceptStorckZeroFromProductStockStatus(state) {
    return state.exceptStockZero;
  },
};
const mutations = {
  setModalClose(state, payload) {
    state.modalClose = payload;
  },
  setStartDateToProductStatus(state, payload) {
    state.startDate = payload;
  },
  setEndDateToProductStatus(state, payload) {
    state.endDate = payload;
  },
  setSearchTextToProductStatus(state, payload) {
    state.searchText = payload;
  },
  setCompanyTextToProductStatus(state, payload) {
    state.companyText = payload;
  },
  setProductStatusListToProductStatus(state, payload) {
    state.productStatusList = payload;
  },
  setSearchStockTextToProductStockStatus(state, payload) {
    state.searchStockText = payload;
  },
  setProductStockStatusListToProductStockStatus(state, payload) {
    state.stockStatusList = payload;
  },
  setProductStockStatusGroupListToProductStockStatus(state, payload) {
    state.productStatusGroupList = payload;
  },
  setSelectedIndexToProductStock(state, index) {
    state.selectedIndex = index;
  },
  setSearchStoreLocationToProductStock(state, payload) {
    state.searchStoreLocation = payload;
  },
  setSearchProductResourceToProductStock(state, payload) {
    state.searchProductResource = payload;
  },
  setCheckExceptStockZeroToProductStock(state, payload) {
    state.exceptStockZero = payload;
  },
};
const actions = {
  FETCH_PRODUCT_STATUS_DAY({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `mag/product/in-out/barcode/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit(
              'setProductStatusListToProductStatus',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_PRODUCT_STATUS({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/product/in-out/barcode`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit(
              'setProductStockStatusListToProductStockStatus',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_PRODUCT_STATUS_GROUP({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/product/inout-group`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit(
              'setProductStockStatusGroupListToProductStockStatus',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
