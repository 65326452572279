import { companyInstance } from '@/api/index.js';
import clonedeep from 'lodash/cloneDeep';

const state = {
  unitCodes: [],
  selectedIndex: -1,
  management_data: {
    id: -1,
    name: '',
    detail: '',
  },
};
const getters = {
  getUnitCodes(state) {
    return state.unitCodes.sort((a, b) => a.id - b.id);
  },
  getSelectedIndexFromUnit(state) {
    return state.selectedIndex;
  },
  getManagementDataFromUnit(state) {
    return state.management_data;
  },
  getUnitForSelectric(state) {
    let options = [];

    options.push({ label: '선택', value: null, detail: null });
    const types = clonedeep(state.unitCodes).sort((a, b) => a.id - b.id);
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
};
const mutations = {
  setSelectedIndexToUnit(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToUnit(state, payload) {
    state.management_data = payload;
  },
  setUnitCodes(state, payload) {
    state.unitCodes = payload;
  },
  removeUnitCode(state, index) {
    console.log('remove unit codes / index : ', index);

    state.unitCodes.splice(index, 1);
  },
  // updateUnitCode(state, index) {
  //   console.log('update unit codes');

  //   state.unitCodes[index].name = state.management_data.name;
  //   state.unitCodes[index].detail = state.management_data.detail;
  //   if (state.unitCodes[index].state == 'old') {
  //     state.unitCodes[index].state = 'updated';
  //   }
  // },
  // insertUnitCode(state, payload) {
  //   console.log(payload);
  //   // state.unitCodes.push(payload);
  // },
};
const actions = {
  async FETCH_UNIT({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/unit')
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            commit('setUnitCodes', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async INSERT_UNIT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/unit', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_UNIT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/unit', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async DELETE_UNIT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/unit/${payload}`)
        .then(response => {
          console.log('payload', payload);
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
