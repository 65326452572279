import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  start_date: null,
  end_date: null,
  quotation: [],
  search_type: null,
  selectedIndex: -1,
  managementData: null,
};

const getters = {
  getQuotation(state) {
    return state.quotation;
  },
  getStartDateFromQuotation(state) {
    return state.start_date;
  },
  getEndDateFromQuotation(state) {
    return state.end_date;
  },
  getSearchTypeFromQuotation(state) {
    return state.search_type;
  },
  getSelectedIndexFromQuotation(state) {
    return state.selectedIndex;
  },
  getManagementDataFromQuotation(state) {
    return state.managementData;
  },
};

const mutations = {
  setQuotation(state, payload) {
    state.quotation = payload;
  },
  setStartDateToQuotation(state, payload) {
    state.start_date = payload;
  },
  setEndDateToQuotation(state, payload) {
    state.end_date = payload;
  },
  setSearchTypeToQuotation(state, payload) {
    state.search_type = payload;
  },
  setSelectedIndexToQuotation(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToQuotation(state, index) {
    state.managementData = index;
  },
};

const actions = {
  FETCH_QUOTATION({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(`quotation/start_date=${newDate.start}&end_date=${newDate.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setQuotation', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_QUOTATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('quotation', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_QUOTATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('quotation', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_QUOTATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`quotation/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};
export default { state, getters, mutations, actions };
