import { companyInstance } from '@/api/index.js';
const state = {
  selectedIndex: -1,
  selectedPartId: -1,
  temp_machine: [],
  partList: [],
  replaceList: [],
  filteredReplaceList: [],
  machines: [],
};

const getters = {
  getSelectedIndexFrompartsReplaeManagementForm(state) {
    return state.selectedIndex;
  },
  getPartsListFrompartsReplaeManagementForm(state) {
    return state.partList;
  },
  getReplaceListFrompartsReplaeManagementForm(state) {
    return state.replaceList;
  },
  getFilteredReplaceListFrompartsReplaeManagementForm(state) {
    return state.filteredReplaceList
      .sort((a, b) => a.part_id - b.part_id)
      .filter(x => x.life_time != null && x.life_time != '');
  },
  getPartsForSelectric(state) {
    let options = [];
    options.push({ label: '선택', value: null, detail: '' });
    const types = state.partList.sort((a, b) => a.id - b.id);
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getChainedMachinesForSelectric(state) {
    let options = [];
    options.push({ label: '선택', value: null, detail: '' });
    let types = state.replaceList.sort((a, b) => a.id - b.id);
    types
      .filter(x => x.part_id == state.selectedPartId)
      .forEach(el => {
        let machine = state.machines.filter(
          machine => machine.id == el.machine_id,
        )[0];
        options.push({
          label: machine.name,
          value: machine.id,
          detail: machine.detail,
        });
      });
    return options;
  },
};
const mutations = {
  setSelectedIndexTopartsReplaeManagementForm(state, idx) {
    state.selectedIndex = idx;
  },
  setSelectedPartIdToPartsReplaceManagementForm(state, id) {
    state.selectedPartId = id;
  },
  setMachinesToReplaceMnanagementPage(state, payload) {
    state.machines = payload;
  },
  setPartsListToReplaceMnanagementPage(state, payload) {
    state.partList = payload;
  },
  setReplaceListToReplaceMnanagementPage(state, payload) {
    state.replaceList = payload;
  },
  setFilteredReplaceListToReplaceMnanagementPage(state, payload) {
    state.filteredReplaceList = payload;
  },
};

const actions = {
  FETCH_PARTS_REPLACE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setMachinesToReplaceMnanagementPage', payload);
      companyInstance
        .get(`/part`)
        .then(response => {
          if (response.data.result == 'success') {
            let list = [];
            for (let i = 0; i < response.data.data_list.length; i++) {
              for (
                let j = 0;
                j < response.data.data_list[i].r_part_machine_list.length;
                j++
              ) {
                let machine = payload.filter(
                  x =>
                    x.id ==
                    response.data.data_list[i].r_part_machine_list[j]
                      .machine_id,
                )[0];
                list.push({
                  id: response.data.data_list[i].r_part_machine_list[j].id,
                  name: response.data.data_list[i].name,
                  part_id: response.data.data_list[i].id,
                  machine_id:
                    response.data.data_list[i].r_part_machine_list[j]
                      .machine_id,
                  machine_name: machine.name,
                  machine_location: machine.machine_location,
                  life_time:
                    response.data.data_list[i].r_part_machine_list[j].life_time,
                  change_dt:
                    response.data.data_list[i].r_part_machine_list[j].change_dt,
                });
              }
            }
            commit('setReplaceListToReplaceMnanagementPage', list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PARTS({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`/part`)
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setPartsListToReplaceMnanagementPage',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_REPLACE_PART(context, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload);
      companyInstance
        .put(`/r-part-machine`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  REPLACE_PART_COMPLETE(context, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload);
      companyInstance
        .post(`/part-history/parts-replacement`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_REPLACE_PART(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`/r-part-machine/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
