import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';
import { yyyymmdd } from '@/utils/func';

const state = {
  lots: [],
  lotsToWorkOrder: [],
  processing_lots: [],
  lotsTodayWorkOrder: [],

  start_date: null,
  end_date: null,
  selectedIndex: -1,
  managementFixResource: false,
  managementFixExpectMaterial: [],
  managementFixExpectProduct: [],
  newFixResource: false,
  newFixExpectMaterial: [],
  newFixExpectProduct: [],
  managementData: {
    id: null,
    input_date: null,
    deadline_date: null,
    product_id: null,
    bom_id: null,
    quantity: 0,
    sales_id: null,
    company_id: null,
    detail: '',
    lot_detail: [],
    emergency_yn: false,
  },
  newData: {
    input_date: null,
    deadline_date: null,
    product_id: null,
    bom_id: null,
    quantity: 0,
    sales_id: null,
    company_id: null,
    detail: '',
    lot_detail: [],
    emergency_yn: false,
  },
  newSelectedSalesProducts: [],
  managementSelectedSalesProducts: [],
  newGroupMaterials: [],
  managementGroupMaterials: [],
};

const getters = {
  getLot(state) {
    return state.lots.sort((a, b) => b.lot_number - a.lot_number);
  },
  getLotFromWorkOrder(state) {
    return state.lotsToWorkOrder.sort((a, b) => b.lot_number - a.lot_number);
  },
  getLotFromTodayWorkOrder(state) {
    return state.lotsTodayWorkOrder.sort((a, b) => b.lot_number - a.lot_number);
  },
  getProcessingLot(state) {
    return state.processing_lots;
  },
  getManagementFixResourceFromWorkOrder(state) {
    return state.managementFixResource;
  },
  getManagementFixExpectMaterial(state) {
    return state.managementFixExpectMaterial;
  },
  getManagementFixExpectProduct(state) {
    return state.managementFixExpectProduct;
  },
  getNewFixResourceFromWorkOrder(state) {
    return state.newFixResource;
  },
  getNewFixExpectMaterial(state) {
    return state.newFixExpectMaterial;
  },
  getNewFixExpectProduct(state) {
    return state.newFixExpectProduct;
  },
  getNewGroupMaterialFromWorkOrder(state) {
    return state.newGroupMaterials;
  },
  getManagementGroupMaterialFromWorkOrder(state) {
    return state.managementGroupMaterials;
  },
  getSelectedIndexFromWorkOrder(state) {
    return state.selectedIndex;
  },
  getManagementDataFromWorkOrder(state) {
    return state.managementData;
  },
  getNewDataFromWorkOrder(state) {
    return state.newData;
  },
  getNewSelectedSalesProducts(state) {
    return state.newSelectedSalesProducts;
  },
  getManagementSelectedSalesProducts(state) {
    return state.newSelectedSalesProducts;
  },
  getStartDateFromWorkOrder(state) {
    return state.start_date;
  },
  getEndDateFromWorkOrder(state) {
    return state.end_date;
  },
  getDateFromWorkOrder(state) {
    return {
      start: formatDateNoHours(state.start_date),
      end: formatDateNoHours(state.end_date),
    };
  },
};

const mutations = {
  setLot(state, payload) {
    state.lots = payload;
  },
  setLotToWorkOrder(state, payload) {
    state.lotsToWorkOrder = payload;
  },
  setLotToTodayWorkOrder(state, payload) {
    state.lotsTodayWorkOrder = payload;
  },
  setProcessingLot(state, payload) {
    state.processing_lots = payload;
  },
  setManagementFixResourceToWorkOrder(state, payload) {
    state.managementFixResource = payload;
  },
  setManagementFixExpectMaterial(state, payload) {
    state.managementFixExpectMaterial = payload;
  },
  setManagementFixExpectProduct(state, payload) {
    state.managementFixExpectProduct = payload;
  },
  setNewFixResourceToWorkOrder(state, payload) {
    state.newFixResource = payload;
  },
  setNewFixExpectMaterial(state, payload) {
    state.newFixExpectMaterial = payload;
  },
  setNewFixExpectProduct(state, payload) {
    state.newFixExpectProduct = payload;
  },
  setManagementDataDetailToWorkOrder(state, payload) {
    state.managementData.detail = payload;
  },
  setNewGroupMaterialToWorkOrder(state, payload) {
    state.newGroupMaterials = payload;
  },
  setManagementGroupMaterialToWorkOrder(state, payload) {
    state.managementGroupMaterials = payload;
  },
  setSelectedIndexToWorkOrder(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToWorkOrder(state, payload) {
    state.managementData = payload;
  },
  setNewDataToWorkOrder(state, payload) {
    state.newData = payload;
  },
  setNewSelectedSalesProducts(state, payload) {
    state.newSelectedSalesProducts = payload;
  },
  setManagementSelectedSalesProducts(state, payload) {
    state.managementSelectedSalesProducts = payload;
  },
  setStartDateToWorkOrder(state, payload) {
    state.start_date = payload;
  },
  setEndDateToWorkOrder(state, payload) {
    state.end_date = payload;
  },
};
const actions = {
  FETCH_LOT_ALL({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/lot/info/all')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setLot', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_LOT_ALL_FROM_WORKORDER({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/lot/info/all')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setLotToWorkOrder', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_LOT_PROCESSING({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('mag/lot/info/processing')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setProcessingLot', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_LOT_DAY_FROM_WORKORDER({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/day/start_date=${payload.start}&end_date=${payload.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setLotToWorkOrder', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_LOT_TODAY_FROM_WORKORDER({ commit }) {
    let today = yyyymmdd(new Date());
    let endDate = new Date(today);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: today,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/day/start_date=${newDate.start}&end_date=${newDate.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setLotToTodayWorkOrder', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_LOT_DAY({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/day/start_date=${payload.start}&end_date=${payload.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setLot', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_LOT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('mag/lot/info', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UPDATE_LOT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('mag/lot/info', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DELETE_LOT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`mag/lot/info/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_LOT_RESOURCE_EXPECT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('mag/lot/resource/expect', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
