import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';
import clonedeep from 'lodash/cloneDeep';

const state = {
  plc_sensor_logs: [],
  plc_sensor_logs_today_hour: [],
  plc_sensor_logs_day_stack: [],
  plc_sensor_logs_day_stack_all: [],
  plc_sensor_logs_package_day: [],
  plc_sensor_logs_time: [],
  plc_sensor_logs_temp: [],
  //설비별 생산수량
  // plc_sensor_log_day: [],
  plc_sensor_log_time: [],

  select_date: null,
  start_time: null,
  end_time: null,
  start_date: null,
  end_date: null,
  search_location: null,
  search_sensor_type: null,
  search_sensor_detail_type: null,
  page_index: 0,

  scroll_date: null,
  scroll_start_time: null,
  scroll_end_time: null,

  scroll_location: null,
  scroll_sensor_type: null,
  scroll_sensor_detail_type: null,
};

const getters = {
  getPlcSensorLog(state) {
    return state.plc_sensor_logs;
  },
  getPlcSensorLogTodayHour(state) {
    return state.plc_sensor_logs_today_hour;
  },
  getPlcSensorLogDayStack(state) {
    return state.plc_sensor_logs_day_stack;
  },
  getPlcSensorLogDayStackAll(state) {
    return state.plc_sensor_logs_day_stack_all;
  },
  getPlcSensorLogPackageDay(state) {
    return state.plc_sensor_logs_package_day;
  },
  getPlcSensorLogTemp(state) {
    return state.plc_sensor_logs_temp;
  },
  getTemperatureSensorLog(state) {
    const hit = state.plc_sensor_logs.filter(
      x => x.plc_sensor_type_detail == 'T',
    );
    return hit != undefined ? hit : [];
  },
  getTemperatureHumiditySensorLog(state) {
    const hit = state.plc_sensor_logs.filter(
      x => x.plc_sensor_type_detail == 'TH',
    );
    return hit != undefined ? hit : [];
  },
  getPlcSensorLogTime(state) {
    return state.plc_sensor_logs_time;
  },
  // getPlcSensorLogDay(state) {
  //   return state.plc_sensor_log_day;
  // },
  getPlcSensorLogTimes(state) {
    return state.plc_sensor_log_time;
  },

  getSelectDateFromPlcSensorLog(state) {
    return state.select_date;
  },
  getStartTimeFromPlcSensorLog(state) {
    return state.start_time;
  },
  getEndTimeFromPlcSensorLog(state) {
    return state.end_time;
  },
  getStartDateFromPlcSensorLog(state) {
    return state.start_date;
  },
  getEndDateFromPlcSensorLog(state) {
    return state.end_date;
  },

  getSearchLocation(state) {
    return state.search_location;
  },
  getSearchSensorType(state) {
    return state.search_sensor_type;
  },
  getSearchSensorDetailType(state) {
    return state.search_sensor_detail_type;
  },
  getPageIndexFromPlcSensorLog(state) {
    return state.page_index;
  },

  getScrollDateFromPlcSensorLog(state) {
    return state.scroll_date;
  },
  getScrollStartTimeFromPlcSensorLog(state) {
    return state.scroll_start_time;
  },
  getScrollEndTimeFromPlcSensorLog(state) {
    return state.scroll_end_time;
  },
  getScrollLocation(state) {
    return state.scroll_location;
  },
  getScrollSensorType(state) {
    return state.scroll_sensor_type;
  },
  getScrollSensorDetailType(state) {
    return state.scroll_sensor_detail_type;
  },
};
const mutations = {
  setPlcSensorLog(state, payload) {
    state.plc_sensor_logs = payload;
  },
  setPlcSensorLogTodayHour(state, payload) {
    state.plc_sensor_logs_today_hour = payload;
  },
  setPlcSensorLogDayStack(state, payload) {
    state.plc_sensor_logs_day_stack = payload;
  },
  setPlcSensorLogDayStackAll(state, payload) {
    state.plc_sensor_logs_day_stack_all = payload;
  },
  setPlcSensorLogPackageDay(state, payload) {
    state.plc_sensor_logs_package_day = payload;
  },
  setPlcSensorLogTemp(state, payload) {
    state.plc_sensor_logs_temp = payload;
  },
  setPlcSensorLogTime(state, payload) {
    state.plc_sensor_logs_time = clonedeep(state.plc_sensor_logs_time).concat(
      payload,
    );
  },
  // setPlcSensorLogDay(state, payload) {
  //   state.plc_sensor_log_day = payload;
  // },
  setPlcSensorLogTimes(state, payload) {
    state.plc_sensor_log_time = payload;
  },

  resetPlcSensorLogTime(state) {
    state.plc_sensor_logs_time = [];
  },

  setSelectDateToPlcSensorLog(state, payload) {
    state.select_date = payload;
  },
  setStartTimeToPlcSensorLog(state, payload) {
    state.start_time = payload;
  },
  setEndTimeToPlcSensorLog(state, payload) {
    state.end_time = payload;
  },
  setStartDateToPlcSensorLog(state, payload) {
    state.start_date = payload;
  },
  setEndDateToPlcSensorLog(state, payload) {
    state.end_date = payload;
  },

  setSearchLocation(state, payload) {
    state.search_location = payload;
  },
  setSearchSensorType(state, payload) {
    state.search_sensor_type = payload;
  },
  setSearchSensorDetailType(state, payload) {
    state.search_sensor_detail_type = payload;
  },
  setPageIndexToPlcSensorLog(state, payload) {
    state.page_index = payload;
  },

  setScrollDateToPlcSensorLog(state, payload) {
    state.scroll_date = payload;
  },
  setScrollStartTimeToPlcSensorLog(state, payload) {
    state.scroll_start_time = payload;
  },
  setScrollEndTimeToPlcSensorLog(state, payload) {
    state.scroll_end_time = payload;
  },
  setScrollLocation(state, payload) {
    state.scroll_location = payload;
  },
  setScrollSensorType(state, payload) {
    state.scroll_sensor_type = payload;
  },
  setScrollSensorDetailType(state, payload) {
    state.scroll_sensor_detail_type = payload;
  },
};

const actions = {
  FETCH_PLC_SENSOR_LOG_NOW({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plc/sensor-log/now`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensorLog', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PLC_SENSOR_LOG_TODAY_HOUR({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plc/sensor-log/today-hour`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensorLogTodayHour', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PLC_SENSOR_LOG_DAY_STACK({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plc/sensor-log/day-stack`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensorLogDayStack', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PLC_SENSOR_LOG_DAY_STACK_ALL({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `plc/sensor-log/day-stack-all/start_day=${payload.start}&end_day=${payload.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensorLogDayStackAll', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PLC_SENSOR_PACKAGE_LOG_DAY({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `custom/plc/custom-package-log/start_day=${newDate.start}&end_day=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensorLogPackageDay', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_PLC_SENSOR_PACKAGE_LOG(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('custom/plc/custom-package-log', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_PLC_SENSOR_LOG_DAY({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `plc/sensor-log/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensorLog', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  FETCH_PLC_SENSOR_LOG_HACCP_TIME({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `plc/sensor-log-time/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensorLogTimes', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_PLC_SENSOR_LOG_HACCP_TIME(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put(`plc/sensor-log-time`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  FETCH_PLC_SENSOR_LOG_TIMES({ commit }) {
    return new Promise((resolve, reject) => {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 1);
      let endDate = new Date();
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: formatDateNoHours(startDate),
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `plc/sensor-log-time/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensorLogTimes', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PLC_SENSOR_LOG_TIME({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `plc/sensor-log/all/date=${payload.date}&stime=${payload.start}&etime=${payload.end}&location=${payload.loction}&type=${payload.type}&detail_type=${payload.detailType}&index=${payload.pageIndex}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensorLogTime', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PLC_SENSOR_LOG_TIME_NOT_PAGE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `plc/sensor-log/all/date=${payload.date}&stime=${payload.start}&etime=${payload.end}&location=${payload.loction}&type=${payload.type}&detail_type=${payload.detailType}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensorLogTime', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PLC_SENSOR_LOG_TIME_EXCEL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `plc/sensor-log/all/date=${payload.date}&stime=${payload.start}&etime=${payload.end}&location=${payload.loction}&type=${payload.type}&detail_type=${payload.detailType}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
