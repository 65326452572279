import { companyInstance } from '@/api/index.js';

const state = {
  machines: [],
  selectedIndex: -1,
  search_machine: null,
  manangementData: {
    id: -1,
    name: '',
    detail: '',
    machine_location: '',
    process_verification_id: -1,
    counter_value: [],
  },
};
const getters = {
  getMachines(state) {
    return state.machines;
  },
  getMachineSort(state) {
    return state.machines.sort((a, b) => a.id - b.id);
  },
  getManagementDataFromMachine(state) {
    return state.manangementData;
  },
  getSelectedIndexFromMachine(state) {
    return state.selectedIndex;
  },
  getSearchMachineFromProductionStatus(state) {
    return state.search_machine;
  },
  getMachinesForSelectricAll(state) {
    let options = [];
    options.push({
      label: '설비기기 전체',
      value: null,
      detail: '',
      operation_time: null,
    });
    const types = state.machines.sort((a, b) => a.id - b.id);
    types.forEach(el => {
      options.push({
        label: el.name,
        value: el.id,
        detail: el.detail,
        operation_time: el.operation_time,
      });
    });
    return options;
  },
  getMachinesForSelectric(state) {
    let options = [];
    options.push({
      label: '선택',
      value: null,
      detail: '',
      operation_time: '',
    });
    const types = state.machines.sort((a, b) => a.id - b.id);
    types.forEach(el => {
      options.push({
        label: el.name,
        value: el.id,
        detail: el.detail,
        operation_time: el.operation_time,
      });
    });
    return options;
  },
};
const mutations = {
  setMachines(state, payload) {
    state.machines = payload;
  },
  setSelectedIndexToMachine(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToMachine(state, payload) {
    state.manangementData = payload;
  },
  setSearchMachineToProductionStatus(state, payload) {
    state.search_machine = payload;
  },
};
const actions = {
  async FETCH_MACHINE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/machine')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response.data.data_list);
            commit('setMachines', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_MACHINE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/machine', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_MACHINE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/machine', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async DELETE_MACHINE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/machine/${payload}`)
        .then(response => {
          console.log('payload', payload);
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
