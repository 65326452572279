const state = {
  openTabIndex: 0,
  managementMode: false,
};

const getters = {
  getOpenTabIndexFromCollectionPage(state) {
    //
    return state.openTabIndex;
  },
  getManagementModeFromCollectionPage(state) {
    //
    return state.managementMode;
  },
};
const mutations = {
  setOpenTabIndexToCollectionPage(state, idx) {
    //
    state.openTabIndex = idx;
  },
  toggleManagementModeToCollectionPage(state) {
    //
    state.managementMode = !state.managementMode;
  },
};

export default { state, getters, mutations };
