import Vue from 'vue';
import App from './App.vue';
import router from '@/routes/index';
import store from '@/store/index';
import jQuery from 'jquery';
import VueLodash from 'vue-lodash';
import clonedeep from 'lodash/cloneDeep';
import VueScreen from 'vue-screen';
import packageJson from '../package.json';
import VueRx from 'vue-rx';
import VuejsClipper from 'vuejs-clipper';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPenToSquare,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faPenToSquare, faCircleCheck);
Vue.component('font-awesome-icon', FontAwesomeIcon);

import {
  formatDate,
  formatDateHHMMSS,
  formatDateNoHours,
  format년월일요일시분초,
  yyyy년MM월dd일,
  hhmm,
  makeComma,
  makeCommaNotZero,
  makeNumber,
  makeNumberFixed2,
  inputNumber,
  inputNumberInt,
  inputNumberIntMinNotZero,
  inputNumberIntNoTarget,
  decimalAdd,
  decimalSub,
  decimalMul,
  decimalDiv,
  decimalMod,
  loopTest,
} from '@/utils/filters';

import { getPageTitle, makeKoreanNumber, groupArrayObject } from '@/utils/func';

Vue.filter('formatDate', formatDate);
Vue.filter('formatDateHHMMSS', formatDateHHMMSS);
Vue.filter('format년월일요일시분초', format년월일요일시분초);
Vue.filter('formatDateNoHours', formatDateNoHours);
Vue.filter('makeComma', makeComma);
Vue.filter('makeCommaNotZero', makeCommaNotZero);
Vue.filter('yyyy년MM월dd일', yyyy년MM월dd일);
Vue.filter('hhmm', hhmm);

// For Tablet Screen
Vue.use(VueScreen);
Vue.use(VueRx);
Vue.use(VuejsClipper);

Vue.use(VueLodash, { name: 'custom', lodash: { clonedeep } });
Vue.prototype.$makeNumber = makeNumber;
Vue.prototype.$makeComma = makeComma;
Vue.prototype.$makeNumberFixed2 = makeNumberFixed2;
Vue.prototype.$inputNumber = inputNumber;
Vue.prototype.$inputNumberInt = inputNumberInt;
Vue.prototype.$inputNumberIntMinNotZero = inputNumberIntMinNotZero;
Vue.prototype.$inputNumberIntNoTarget = inputNumberIntNoTarget;
Vue.prototype.$decimalAdd = decimalAdd;
Vue.prototype.$decimalSub = decimalSub;
Vue.prototype.$decimalMul = decimalMul;
Vue.prototype.$decimalDiv = decimalDiv;
Vue.prototype.$decimalMod = decimalMod;
Vue.prototype.$getPageTitle = getPageTitle;
Vue.prototype.$makeKoreanNumber = makeKoreanNumber;
Vue.prototype.$groupArrayObject = groupArrayObject;
Vue.prototype.$loopTest = loopTest;

Vue.config.productionTip = false;

window.jQuery = jQuery;
window.$ = jQuery;

console.log(packageJson.version);

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app');
