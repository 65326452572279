import { companyInstance } from '@/api';
import clonedeep from 'lodash/cloneDeep';

const state = {
  base_verification_list: [],
  managementData: {
    verification_type_id: 0,
    inspection_item: '',
    pass_standard: '',
    inspection_equipment: '',
    inspection_timing: '',
  },
  selectedIndex: -1,
};
const getters = {
  getManagementDataFromQualityManagement(state) {
    return state.managementData;
  },
  getSelectedIndexFromQualityManagement(state) {
    return state.selectedIndex;
  },
  getBaseVerificationFromQualityManagement(state) {
    return state.base_verification_list.sort(
      (a, b) => a.verification_type_id - b.verification_type_id,
    );
  },
  getBaseVerificationFromQualityManagementNotSort(state) {
    return state.base_verification_list;
  },
  getMaterialVerificationFromQualityManagement(state) {
    return state.base_verification_list.filter(x =>
      [1, 3, 5, 7].includes(x.verification_type_id),
    );
  },
  getMaterialVerificationForSelectricFromQualityManagement(state) {
    if (state.base_verification_list.length < 1) {
      const returnArray = [];
      returnArray.push({
        label: '선택',
        value: null,
      });
      returnArray.push({
        label: '신규항목등록',
        value: 'new',
      });
      return returnArray;
    } else {
      const returnArray = [];
      const base_veri = clonedeep(
        state.base_verification_list
          .sort((a, b) => a.verification_type_id - b.verification_type_id)
          .filter(x => [1, 3, 5, 7].includes(x.verification_type_id)),
      );
      returnArray.push({
        label: '선택',
        value: null,
      });
      base_veri.forEach(el => {
        returnArray.push({
          label: el.inspection_item,
          value: el.id,
        });
      });
      returnArray.push({
        label: '신규항목등록',
        value: 'new',
      });
      return returnArray;
    }
  },
  getProductVerificationFromQualityManagement(state) {
    return state.base_verification_list.filter(
      x => x.verification_type_id >= 4,
    );
  },
  getProductVerificationForSelectricFromQualityManagement(state) {
    if (state.base_verification_list.length < 1) {
      const returnArray = [];
      returnArray.push({
        label: '선택',
        value: null,
      });
      returnArray.push({
        label: '신규항목등록',
        value: 'new',
      });
      return returnArray;
    } else {
      const returnArray = [];
      const base_veri = clonedeep(
        state.base_verification_list
          .sort((a, b) => a.verification_type_id - b.verification_type_id)
          .filter(x => x.verification_type_id >= 4),
      );
      returnArray.push({
        label: '선택',
        value: null,
      });
      base_veri.forEach(el => {
        returnArray.push({
          label: el.inspection_item,
          value: el.id,
        });
      });
      returnArray.push({
        label: '신규항목등록',
        value: 'new',
      });
      return returnArray;
    }
  },
  getSeqVerificationFromQualityManagement(state) {
    return state.base_verification_list.filter(x =>
      [2, 3, 6, 7].includes(x.verification_type_id),
    );
  },
  getSeqVerificationForSelectricFromQualityManagement(state) {
    if (state.base_verification_list.length < 1) {
      const returnArray = [];
      returnArray.push({
        label: '선택',
        value: null,
      });
      returnArray.push({
        label: '신규항목등록',
        value: 'new',
      });
      return returnArray;
    } else {
      const returnArray = [];
      const base_veri = clonedeep(
        state.base_verification_list
          .sort((a, b) => a.verification_type_id - b.verification_type_id)
          .filter(x => [2, 3, 6, 7].includes(x.verification_type_id)),
      );
      returnArray.push({
        label: '선택',
        value: null,
      });
      base_veri.forEach(el => {
        returnArray.push({
          label: el.inspection_item,
          value: el.id,
        });
      });
      returnArray.push({
        label: '신규항목등록',
        value: 'new',
      });
      return returnArray;
    }
  },
};
const mutations = {
  setManagementDataToQualityManagement(state, payload) {
    state.managementData = payload;
  },
  setSelectedIndexToQualityManagement(state, payload) {
    state.selectedIndex = payload;
  },
  addVerificationTypeId(int) {
    state.managementData.verification_type_id += Number(int);
  },
  subVerificationTypeId(int) {
    state.managementData.verification_type_id -= Number(int);
  },
  setBaseVerificationToQualityManagement(state, payload) {
    state.base_verification_list = payload;
  },
};
const actions = {
  FETCH_BASE_VERIFICATION({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/verification')
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setBaseVerificationToQualityManagement',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  INSERT_VERIFICATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/verification', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UPDATE_VERIFICATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/verification', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DELETE_VERIFICATION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/verification/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
