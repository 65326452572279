import { companyInstance } from '@/api/index';
// import clonedeep from 'lodash/cloneDeep';

const state = {
  kpi_target: [],
  selectedIndex: -1,
  kpi_kind_options: [],
  Q_target_year: null,
  Q_target_start_date: null,
  Q_target_end_date: null,
  D_target_year: null,
  P_target_year: null,
  select_year: null,
  kpi_order_shipment_lead_time: [],
  kpi_production_per_hour: [],
  managementData: {
    //조회 & 수정
    id: -1,
    kpi_year: String(new Date().getFullYear()),
    kpi_no: -1,
    kpi_kind_select_option: -1,
    kpi_kind: '',
    kpi_name: '',
    unit_cd: null,
    product_id: null,
    crt_val: 0,
    tgt_val: 0,
    wgt_val: 0,
    remark: '',
  },
};
const getters = {
  getKpiOrderShipmentLeadTime(state) {
    return state.kpi_order_shipment_lead_time;
  },
  getKpiProductionPerHour(state) {
    return state.kpi_production_per_hour;
  },
  getKpiTarget(state) {
    return state.kpi_target;
  },
  getSelectedIndexFromKpiTarget(state) {
    return state.selectedIndex;
  },
  getQTargerYearFromKpiTarget(state) {
    return state.Q_target_year;
  },
  getQTargerStartDateFromKpiTarget(state) {
    return state.Q_target_start_date;
  },
  getQTargerEndDateFromKpiTarget(state) {
    return state.Q_target_end_date;
  },
  getDTargerYearFromKpiTarget(state) {
    return state.D_target_year;
  },
  getPTargerYearFromKpiTarget(state) {
    return state.P_target_year;
  },
  getManagementDataFromKpiTarget(state) {
    return state.managementData;
  },
  getSelectYearFromKpiTarget(state) {
    return state.select_year;
  },
  getKPIKindOptions(state) {
    const options = [];
    options.push({
      label: '선택',
      value: -1,
      detail: { kpi_name: '', unit_cd: '' },
    });
    options.push({
      label: 'Q',
      value: 1,
      detail: { kpi_name: '완제품 불량률', unit_cd: '%' },
    });
    options.push({
      label: 'D',
      value: 2,
      detail: { kpi_name: '수추 출하 리드타임', unit_cd: 'hr' },
    });
    options.push({
      label: 'P',
      value: 3,
      detail: { kpi_name: '시간당 생산량', unit_cd: 'EA/Hour' },
    });
    state.kpi_kind_options = options;
    return options;
  },
  getKPICNSOptions(state) {
    const options = [];
    options.push({
      label: '선택',
      value: -1,
      detail: { kpi_name: '', unit_cd: '' },
    });
    options.push({
      label: 'P',
      value: 1,
      detail: { kpi_name: '시간당 생산량', unit_cd: 'ea/h' },
    });
    options.push({
      label: 'D',
      value: 2,
      detail: { kpi_name: '납기단축', unit_cd: 'day' },
    });
    state.kpi_kind_options = options;
    return options;
  },
  getKPIOsungOptions(state) {
    const options = [];
    options.push({
      label: '선택',
      value: -1,
      detail: { kpi_name: '', unit_cd: '' },
    });
    options.push({
      label: 'Q',
      value: 1,
      detail: { kpi_name: '완제품 불량률', unit_cd: '%' },
    });
    options.push({
      label: 'D',
      value: 2,
      detail: { kpi_name: '수추 출하 리드타임', unit_cd: 'hr' },
    });
    state.kpi_kind_options = options;
    return options;
  },
  getKPIDaejinOptions(state) {
    const options = [];
    options.push({
      label: '선택',
      value: -1,
      detail: { kpi_name: '', unit_cd: '' },
    });
    options.push({
      label: 'D',
      value: 1,
      detail: { kpi_name: '수추 출하 리드타임', unit_cd: 'hr' },
    });
    options.push({
      label: 'Q',
      value: 2,
      detail: { kpi_name: '완제품 불량률', unit_cd: '%' },
    });
    state.kpi_kind_options = options;
    return options;
  },
  getKPITteokOptions(state) {
    const options = [];
    options.push({
      label: '선택',
      value: -1,
      detail: { kpi_name: '', unit_cd: '' },
    });
    options.push({
      label: 'P',
      value: 1,
      detail: { kpi_name: '시간당 생산량 ', unit_cd: '봉 / 시간' },
    });
    options.push({
      label: 'Q',
      value: 2,
      detail: { kpi_name: '완제품 불량률', unit_cd: '%' },
    });
    state.kpi_kind_options = options;
    return options;
  },
  getKPISanchaeTownOptions(state) {
    const options = [];
    options.push({
      label: '선택',
      value: -1,
      detail: { kpi_name: '', unit_cd: '' },
    });
    options.push({
      label: 'P',
      value: 1,
      detail: { kpi_name: '시간당 생산량', unit_cd: 'kg/Hr' },
    });
    options.push({
      label: 'Q',
      value: 2,
      detail: { kpi_name: '공정 불량률', unit_cd: '%' },
    });
    state.kpi_kind_options = options;
    return options;
  },
};
const mutations = {
  setKpiOrderShipmentLeadTime(state, payload) {
    state.kpi_order_shipment_lead_time = payload;
  },
  setKpiProductionPerHour(state, payload) {
    state.kpi_production_per_hour = payload;
  },
  setKpiTarget(state, payload) {
    state.kpi_target = payload;
  },
  setSelectedIndexToKpiTarget(state, index) {
    state.selectedIndex = index;
  },
  setQTargerYearToKpiTarget(state, year) {
    state.Q_target_year = year;
  },
  setQTargerStartDateToKpiTarget(state, date) {
    state.Q_target_start_date = date;
  },
  setQTargerEndDateToKpiTarget(state, date) {
    state.Q_target_end_date = date;
  },
  setDTargerYearToKpiTarget(state, year) {
    state.D_target_year = year;
  },
  setPTargerYearToKpiTarget(state, year) {
    state.P_target_year = year;
  },
  setSelectYearToKpiTarget(state, payload) {
    state.select_year = payload;
  },
  setManagementDataToKpiTarget(state, payload) {
    state.managementData = payload;
  },
  setManagementDataToKpiTargetKind(state, type_id) {
    state.managementData.kpi_kind_select_option = type_id;

    const selectOption = state.kpi_kind_options.find(x => x.value == type_id);
    state.managementData.kpi_kind = selectOption.label;
    state.managementData.kpi_name = selectOption.detail.kpi_name;
    state.managementData.unit_cd = selectOption.detail.unit_cd;
  },
};
const actions = {
  FETCH_KPI_ORDER_SHIPMENT_LEAD_TIME({ commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload);
      companyInstance
        .get(`plan/sales/account/year/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('RES', response);
            commit('setKpiOrderShipmentLeadTime', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_KPI_PRODUCTION_PER_HOUR({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/production/year/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('RES', response);
            commit('setKpiProductionPerHour', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_KPI_TARGET({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`kpi/year/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('RES', response);
            commit('setKpiTarget', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_FETCH_KPI_TARGET(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('kpi', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_FETCH_KPI_TARGET(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('kpi', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_FETCH_KPI_TARGET(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`kpi/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
