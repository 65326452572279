import { companyInstance } from '@/api/index';
const state = {
  product_groups: [],
};

const getters = {
  getProductGroup(state) {
    return state.product_groups;
  },
};

const mutations = {
  setProductGroup(state, payload) {
    state.product_groups = payload;
  },
};

const actions = {
  FETCH_PRODUCT_GROUP({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .get('product-group')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setProductGroup', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_PRODUCT_GROUP(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .post('product-group', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_PRODUCT_GROUP_LIST(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .post('product-group/list', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_PRODUCT_GROUP(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .delete(`product-group/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_PRODUCT_GROUP_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/start=${newDate.start}&end=${newDate.end}`)
        .delete(`product-group/all/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
