import { instance, instanceWithAuth } from '@/api/index.js';
import { getSaupFromCookie } from '@/utils/cookies';
import { cloneDeep } from 'lodash';

const state = {
  systemCompany: {
    account_number: undefined,
    address: undefined,
    business_item: undefined,
    business_type: undefined,
    detail: undefined,
    detail_address: undefined,
    email: undefined,
    fax_number: undefined,
    id: undefined,
    name: undefined,
    phone_number: undefined,
    port: undefined,
    post_number: undefined,
    representative: undefined,
    representative_item: undefined,
    saup_no: undefined,
    server_url: undefined,
    imagePath: undefined,
    logoImage: undefined,
  },
  editSystemCompany: {},
};

const getters = {
  getSystemCompany(state) {
    return state.systemCompany;
  },
  getEditSystemCompany(state) {
    return state.editSystemCompany;
  },
  getSelectedEmailFromEditSystemCompany(state) {
    try {
      if (state.editSystemCompany.email != undefined) {
        const strArr = state.editSystemCompany.email.split('@');
        return strArr[1];
      } else {
        return '';
      }
    } catch (error) {
      console.log(error);
      return '';
    }
  },
  getSelectedBankFromEditSystemCompany(state) {
    try {
      if (state.editSystemCompany.account_number != undefined) {
        const strArr = state.editSystemCompany.account_number.split('/');
        return strArr[1];
      } else {
        return '';
      }
    } catch (error) {
      console.log(error);
      return '';
    }
  },
};

const mutations = {
  setSystemCompany(state, payload) {
    state.systemCompany = payload;
    state.editSystemCompany = cloneDeep(payload);
  },
  resetEditSystemCompany(state) {
    state.editSystemCompany = cloneDeep(state.systemCompany);
  },
  setEditSystemCompanyAddress(state, address) {
    state.editSystemCompany.address = address;
  },
  setEditSystemCompanyPostNumber(state, post_number) {
    state.editSystemCompany.post_number = post_number;
  },
};

const actions = {
  async FETCH_SYSTEM_COMPANY({ commit }) {
    return new Promise((resolve, reject) => {
      instance
        .get('system/company')
        .then(response => {
          if (response.data.result == 'success') {
            let isMatched = false;
            const hitSaup = response.data.data_list.find(
              element => element.saup_no == getSaupFromCookie(),
            );
            if (hitSaup != null) {
              isMatched = true;
              commit('setSystemCompany', hitSaup);
            }
            if (isMatched) {
              resolve(response);
            } else {
              reject(response);
            }
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_SYSTEM_COMPANY(context, updateData) {
    return new Promise((resolve, reject) => {
      instanceWithAuth
        .put('system/company', updateData)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
