import { companyInstance } from '@/api';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  startDate: null,
  endDate: null,
  searchText: '',
  inputPrintList: [],
};
const getters = {
  getStartDateFromInputPrint(state) {
    return state.startDate;
  },
  getEndDateFromInputPrint(state) {
    return state.endDate;
  },
  getDateFromInputPrint(state) {
    return {
      start: formatDateNoHours(state.startDate),
      end: formatDateNoHours(state.endDate),
    };
  },
  getSearchTextFromInputPrint(state) {
    return state.searchText;
  },
  getInputPrintListFromInputPrint(state) {
    return state.inputPrintList.filter(x => x.material_in_out_type_id == 1);
  },
};
const mutations = {
  setStartDateToInputPrint(state, payload) {
    state.startDate = payload;
  },
  setEndDateToInputPrint(state, payload) {
    state.endDate = payload;
  },
  setSearchTextToInputPrint(state, payload) {
    state.searchText = payload;
  },
  setInputPrintListToInputPrint(state, payload) {
    state.inputPrintList = payload;
  },
};
const actions = {
  FETCH_INPUT_PRINT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/material/input/barcode/start_date=${payload.start}&end_date=${payload.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            response.data.data_list.forEach(el => {
              el.check_print = false;
            });
            commit('setInputPrintListToInputPrint', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
