import { companyInstance } from '@/api/index.js';

const state = {
  plc_sensor_list: [],
  plc_sensor_current_logs: [],
};

const getters = {
  getPlcSensor(state) {
    return state.plc_sensor_list;
  },
  getPlcSensorCurrentLog(state) {
    return state.plc_sensor_current_logs;
  },
};
const mutations = {
  setPlcSensor(state, payload) {
    state.plc_sensor_list = payload;
  },
  setPlcSensorCurrentLog(state, payload) {
    state.plc_sensor_current_logs = payload;
  },
};

const actions = {
  RESET_PLC_COUNTER_VALUE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('plc/counter/reset', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PLC_SENSOR({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plc/sensor`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensor', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PLC_SENSOR_CURRENT_LOG_TO_PLC_SENSR({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plc/sensor-log/now`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPlcSensorCurrentLog', response.data.data_list);
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_FETCH_PLC_SENSOR_CURRENT_LOG_TO_PLC_SENSR(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('plc/sensor/default', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('잘갔니?', response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
