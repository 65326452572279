import { companyInstance } from '@/api/index';
// import clonedeep from 'lodash/cloneDeep';

const state = {
  boms: [],
  selectedIndex: -1,
  showNonBomProducts: false,
  managementData: {
    id: -1,
    name: '',
    product_id: null,
    bom_process: [],
  },
  cloneData: {
    // id: -1,
    // name: '',
    // product_id: null,
    bom_process: [],
  },
  newData: {
    name: '',
    product_id: null,
    bom_process: [],
  },
  manageSearchText: '',
  newSearchText: '',
};
const getters = {
  getBom(state) {
    return state.boms;
  },
  getShowNonBomProduct(state) {
    return state.showNonBomProducts;
  },
  getBomNoHistory(state) {
    return state.boms.filter(x => x.product_id != null);
  },
  getManagementDataFromBom(state) {
    return state.managementData;
  },
  getCloneDataFromBom(state) {
    return state.cloneData;
  },
  getNewDataFromBom(state) {
    return state.newData;
  },
  getSelectedIndexFromBom(state) {
    return state.selectedIndex;
  },
  getNewSearchTextFromBom(state) {
    return state.newSearchText;
  },
  getManageSearchTextFromBom(state) {
    return state.manageSearchText;
  },
  getManagementBomProcessGroup(state) {
    const obj = state.managementData.bom_process.reduce(function(rv, x) {
      (rv[x['index']] = rv[x['index']] || []).push(x);
      return rv;
    }, {});
    let arr = [];
    for (let k in obj) {
      arr.push({
        level: k,
        count: obj[k],
      });
    }
    arr.push({
      level: arr.length + 1,
      count: [],
    });
    return arr;
  },
  getNewBomProcessGroup(state) {
    const obj = state.newData.bom_process.reduce(function(rv, x) {
      (rv[x['index']] = rv[x['index']] || []).push(x);
      return rv;
    }, {});
    let arr = [];
    for (let k in obj) {
      arr.push({
        level: k,
        count: obj[k],
      });
    }
    arr.push({
      level: arr.length + 1,
      count: [],
    });
    return arr;
  },
};
const mutations = {
  setBoms(state, payload) {
    state.boms = payload;
  },
  setShowNonBomProduct(state, payload) {
    console.log(payload);
    state.showNonBomProducts = payload;
  },
  setManagementDataToBom(state, payload) {
    if (payload.bom_process != undefined && payload.bom_process.length > 0) {
      payload.bom_process.forEach(y => {
        y.id = y.process_id;
        y.bom_resource.forEach(z => {
          if (z.material_id != null) {
            z.id = z.material_id;
          } else {
            z.id = z.product_id;
          }
        });
      });
    }

    state.managementData = payload;
  },
  setCloneDataToBom(state, payload) {
    if (payload.bom_process != undefined && payload.bom_process.length > 0) {
      payload.bom_process.forEach(y => {
        y.id = y.process_id;
        y.bom_resource.forEach(z => {
          if (z.material_id != null) {
            z.id = z.material_id;
          } else {
            z.id = z.product_id;
          }
        });
      });
    }

    state.cloneData = payload;
  },
  setNewDataToBom(state, payload) {
    state.newData = payload;
  },
  setSelectedIndexToBom(state, index) {
    state.selectedIndex = index;
  },
  setNewSearchTextToBom(state, payload) {
    state.newSearchText = payload;
  },
  setManageSearchTextToBom(state, payload) {
    state.manageSearchText = payload;
  },
};
const actions = {
  FETCH_BOM({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/bom/info')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setBoms', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_BOM_ALL(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/bom/info/all', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_BOM(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/bom/info', payload)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
