import { companyInstance } from '@/api/index.js';

const state = {
  collectoin_types: [],
};
const getters = {
  getCollectionType(state) {
    return state.collectoin_types;
  },
  getCollectionTypeForSelectricAll(state) {
    let options = [];
    options.push({ label: '전체', value: null, detail: null });
    const types = state.collectoin_types;
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getCollectionTypeForSelectric(state) {
    let options = [];
    options.push({ label: '선택', value: null, detail: null });
    const types = state.collectoin_types;
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
  getCollectionTypeForSelectricFromSalesBase(state) {
    let options = [];
    options.push({ label: '선택', value: null, detail: null });
    const types = state.collectoin_types.filter(
      x => x.id == 1 || x.id == 2 || x.id == 3,
    );
    types.forEach(el => {
      options.push({ label: el.name, value: el.id, detail: el.detail });
    });
    return options;
  },
};

const mutations = {
  setCollectionType(state, payload) {
    state.collectoin_types = payload;
  },
};

const actions = {
  FETCH_COLLECTION_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('collection-type')
        .then(response => {
          if (response.data.result == 'success') {
            commit('setCollectionType', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
