import { companyInstance } from '@/api/index.js';
const state = {
  selectedIndex: -1,
  operationList: [],
};

const getters = {
  getSelectedIndexFromOperationIfoForm(state) {
    return state.selectedIndex;
  },
  getOperationListFromOperationInfoForm(state) {
    return state.operationList;
  },
};
const mutations = {
  setSelectedIndexToOperationIfoForm(state, idx) {
    state.selectedIndex = idx;
  },
  setOperationListToOperationInfoForm(state, payload) {
    state.operationList = payload;
  },
};

const actions = {
  DELETE_OPERATION_TIME(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`/base/machine/operation/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
