import { companyInstance } from '@/api/index';
const state = {
  products: [],
  check_only_group_product: false,
  selectedIndex: -1,
  managementData: {
    id: -1,
    product_group_yn: false,
    product_group_list: [],
    product_group_type_id: null,
    name: '',
    standard: '',
    code: '',
    stock_size: 0,
    stock_unit_id: null,
    delivery_unit_id: -1,
    resource_type_id: null,
    box_unit_id: null,
    default_store_id: null,
    detail: '',
    company_list: [],
    tax: null,
    inspection: null,
    expire_year: 0,
    expire_month: 0,
    expire_day: 0,
    oem_product_yn: false,
    crushed_product_yn: false,
  },
  newData: {
    name: '',
    product_group_yn: false,
    product_group_list: [],
    product_group_type_id: null,
    standard: '',
    code: '',
    stock_size: 0,
    stock_unit_id: null,
    delivery_unit_id: -1,
    resource_type_id: null,
    box_unit_id: null,
    default_store_id: null,
    detail: '',
    company_list: [],
    tax: null,
    inspection: null,
    expire_year: 0,
    expire_month: 0,
    expire_day: 0,
    oem_product_yn: false,
    crushed_product_yn: false,
  },
};

const getters = {
  getProduct(state) {
    return state.products;
  },
  getVisibleProduct(state) {
    return state.products.filter(x => x.visible);
  },
  getDeletedProduct(state) {
    return state.products.filter(x => !x.visible);
  },
  getProductNotOEM(state) {
    return state.products
      .filter(x => !x.oem_product_yn)
      .sort((x, y) => x.id - y.id);
  },
  getCheckOnlyGroupProductFromProduct(state) {
    return state.check_only_group_product;
  },
  getSelectedIndexFromProduct(state) {
    return state.selectedIndex;
  },
  getManagementDataFromProduct(state) {
    return state.managementData;
  },
  getNewDataFromProduct(state) {
    return state.newData;
  },
  getGroupTypeForSelectric() {
    let options = [];
    options.push(
      { label: '선택', value: null, detail: null },
      { label: '생산제품', value: 1, detail: 'productionGroup' },
      { label: '세트제품', value: 2, detail: 'setGroup' },
    );

    return options;
  },
};

const mutations = {
  setProduct(state, payload) {
    payload.forEach(el => {
      if (el.detail == null) {
        el.detail = '';
      }
      if (el.standard == null) {
        el.standard = '';
      }
    });

    state.products = payload.sort((a, b) => a.id - b.id);
  },
  setCheckOnlyGroupProductToProduct(state, payload) {
    state.check_only_group_product = payload;
  },
  setSelectedIndexToProduct(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToProduct(state, payload) {
    state.managementData = payload;
  },
  setNewDataToProduct(state, payload) {
    state.newData = payload;
  },
  setManagementProductTypeId(state, type_id) {
    state.managementData.resource_type_id = type_id;
  },
  setNewProductTypeId(state, type_id) {
    state.newData.resource_type_id = type_id;
  },
  setManagementProductGroupTypeId(state, payload) {
    state.managementData.product_group_type_id = payload;
  },
  setNewProductGroupTypeId(state, payload) {
    state.newData.product_group_type_id = payload;
  },
  setManagementProductTax(state, tax) {
    state.managementData.tax = tax;
  },
  setNewProductTax(state, tax) {
    state.newData.tax = tax;
  },
  setManagementProductInspection(state, id) {
    state.managementData.inspection = id;
  },
  setNewProductInspection(state, id) {
    state.newData.inspection = id;
  },
  setManagementStockUnitId(state, id) {
    state.managementData.stock_unit_id = id;
  },
  setNewStockUnitId(state, id) {
    state.newData.stock_unit_id = id;
  },
  setManagementBoxUnitIdToProduct(state, id) {
    state.managementData.box_unit_id = id;
  },
  setNewBoxUnitIdToProduct(state, id) {
    state.newData.box_unit_id = id;
  },
  setManagementProductDefaultStore(state, id) {
    state.managementData.default_store_id = id;
  },
  setNewProductDefaultStore(state, id) {
    state.newData.default_store_id = id;
  },
  setNewProductCompany(state, payload) {
    state.newData.company_list = payload;
  },
};

const actions = {
  FETCH_PRODUCT({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/product/with/company')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setProduct', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PRODUCT_WITH_COMPANY({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('base/product/with/company')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setProduct', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_PRODUCT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('base/product/info', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_PRODUCT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('base/product/info', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_PRODUCT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/product/info/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  RESTORE_PRODUCT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`base/product/info/restore/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
