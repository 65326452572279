import { companyInstance } from '@/api/index';

const state = {
  lot_tank: [],
  management_lot_tank: {
    tank1: false,
    tank2: false,
    tank3: false,
    tank4: false,
  },
  new_lot_tank: {
    tank1: false,
    tank2: false,
    tank3: false,
    tank4: false,
  },
};
const getters = {
  getLotTank(state) {
    return state.lot_tank;
  },
  getManagementLotTank(state) {
    return state.management_lot_tank;
  },
  getNewLotTank(state) {
    return state.new_lot_tank;
  },
};
const mutations = {
  setLotTank(state, payload) {
    state.lot_tank = payload;
  },
  setManagementLotTank(state, payload) {
    state.management_lot_tank = payload;
  },
  setNewLotTank(state, payload) {
    state.new_lot_tank = payload;
  },
};
const actions = {
  FETCH_LOT_TANK_BY_LOT_ID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`custom/lot-tank/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setManagementLotTank', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_LOT_TANK({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`custom/lot-tank`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setLotTank', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  CREATE_LOT_TANK(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post(`custom/lot-tank`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_LOT_TANK_BY_LOT_ID(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`custom/lot-tank/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_LOT_TANK(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put(`custom/lot-tank`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};
export default { state, getters, mutations, actions };
