import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  target_lot: null,
  keyCountForReDraw: 0,
  lot_year_list: null,
  lot_list: null,
};

const getters = {
  getTargetLotFromProduction(state) {
    return state.target_lot;
  },
  getKeyCountForReDrawFromProduction(state) {
    return state.keyCountForReDraw;
  },
  getLotYearListFromProduction(state) {
    return state.lot_year_list;
  },
  getLotListFromProduction(state) {
    return state.lot_list;
  },
};

const mutations = {
  setTargetLotToProduction(state, payload) {
    if (payload == null) {
      state.target_lot = null;
    } else {
      if (payload.bom_info.bom_process[0].index == 0) {
        payload.bom_info.bom_process.forEach(element => {
          element.index += 1;
        });
      }
      payload.bom_info.bom_process.sort((a, b) => a.index - b.index);
      state.target_lot = payload;
    }
  },
  setLotProcessToProduction(state, payload) {
    payload.forEach(el => {
      console.log('el.bom_id', el.bom_id);
      const bomProcessTemp = state.target_lot.bom_process.find(
        x => x.id == el.bom_process_id,
      );
      bomProcessTemp.lot_process = el;
    });
  },
  setKeyCountForReDrawToProduction(state, payload) {
    state.keyCountForReDraw = payload;
  },
  setLotYearListToProduction(state, payload) {
    state.lot_year_list = payload;
  },
  setLotListToProduction(state, payload) {
    state.lot_list = payload;
  },
};
const actions = {
  async FETCH_LOT_PRODUCTION({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/production/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log('bom_lot', response.data.data);
            commit('setTargetLotToProduction', response.data.data);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_LOT_PRODUCTION_YEAR({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/production/year/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setLotYearListToProduction', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_LOT_PRODUCTION_DAY({ commit }, payload) {
    const endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    payload.end = formatDateNoHours(endDate);
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/lot/production/day/start_date=${payload.start}&end_date=${payload.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setLotListToProduction', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_LOT_PROCESS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/process/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response.data.data_list);
            commit('setLotProcessToProduction', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_SEQ_DEFECT_PRODUCTION(contect, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/seq/defect/production/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_LOT_PROCESS_SEQ(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('mag/lot/process/seq', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_LOT_PROCESS_SEQ(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('mag/lot/process/seq', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_LOT_RESOURCE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('mag/lot/resource', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async INSERT_LOT_RESOURCE_LIST(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('mag/lot/resource/list', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async DELETE_LOT_PROCESS_LOT(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`mag/lot/process/lot/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_LOT_INFO_DONE(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put(`mag/lot/info/done`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  FETCH_PLC_SENSOR_CURRENT_LOG_TO_PRODUCTION() {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`plc/sensor-log/now`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response.data.data_list);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_LOT_TRACKING_RESOURCE_TO_PRODUCTION(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`mag/lot/tracking-resource/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response.data.data);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_LOT_RESOURCE_RETURN(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put(`mag/lot/info/return`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
