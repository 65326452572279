import {
  getAuthFromCookie,
  getUserFromCookie,
  saveAuthToCookie,
  saveUserToCookie,
  getUrlToCookie,
  saveUrlToCookie,
  deleteCookie,
  saveSaupToCookie,
} from '@/utils/cookies.js';
import { refreshToken } from '@/api/auth';
import { instance, companyInstance } from '@/api/index.js';

const state = {
  companyURL: getUrlToCookie() || '',
  userId: getUserFromCookie() || '',
  userInfo: {},
  token: getAuthFromCookie() || '',
  users: [],
  user_search_type: null,
};
const getters = {
  getCompanyURL(state) {
    return state.companyURL;
  },
  getUserId(state) {
    return state.userId;
  },
  getUserInfo(state) {
    return state.userInfo;
  },
  getToken(state) {
    return state.token;
  },
  isLogin(state) {
    return state.token == '' ? false : true;
  },
  getUsersForSelectricAll(state) {
    let options = [];
    options.push({ label: '전체', value: null, detail: '' });
    const types = state.users.sort((a, b) => a.id - b.id);
    types.forEach(el => {
      options.push({ label: el.account, value: el.id, detail: el.name });
    });
    return options;
  },
  getUsers(state) {
    return state.users;
  },
  getUserSearchType(state) {
    return state.user_search_type;
  },
};
const mutations = {
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
  setUserId(state, userid) {
    state.userId = userid;
  },
  setToken(state, token) {
    state.token = token;
  },
  setUsers(state, payload) {
    state.users = payload;
  },
  clearUser(state) {
    deleteCookie('user');
    deleteCookie('auth');
    deleteCookie('saup');
    deleteCookie('CompanyUrl');
    localStorage.removeItem('userSaup');
    localStorage.removeItem('userId');
    localStorage.removeItem('companyUrl');
    localStorage.removeItem('token');
    state.userInfo = {};
    state.userId = '';
    state.token = '';
    state.companyURL = '';
  },
  setCompanyUrl(state, payload) {
    state.companyURL = `${payload.server_url}:${payload.port}/api/v1/`;
  },
  setUserSearchType(state, payload) {
    state.user_search_type = payload;
  },
};
const actions = {
  async LOGIN_USER({ commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log('geturlTocookie in loginuser api', getUrlToCookie());
      companyInstance.defaults.baseURL = getUrlToCookie();
      companyInstance
        .put('auth/login', payload)
        .then(response => {
          if (response.data.result == 'success') {
            commit('setUserInfo', response.data.data);
            commit('setUserId', response.data.data.account);
            commit('setToken', response.data.token);
            saveUserToCookie(response.data.data.account);
            saveAuthToCookie(response.data.token);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async REFRESH_TOKEN({ commit }, payload) {
    try {
      // commit('setToken', payload.token);
      commit('setCompanyUrl', payload.companyUrl);
      const response = await refreshToken({
        access_token: payload.token,
      });

      // console.log('refreshResponse',response);
      if (response.data.result == 'success') {
        commit('setUserInfo', response.data.data);
        commit('setUserId', response.data.data.account);
        commit('setToken', response.data.token);
        saveUserToCookie(response.data.data.account);
        saveAuthToCookie(response.data.token);
        saveUrlToCookie(localStorage.getItem('companyUrl'));
        saveSaupToCookie(localStorage.getItem('userSaup'));
        if (
          localStorage.getItem('userSaup') == undefined ||
          localStorage.getItem('userSaup') == null
        ) {
          commit('clearUser');
          return 'token_mismatch';
        }
        localStorage.removeItem('token');
        localStorage.setItem('token', response.data.token);
        //localStorage.
        return 'success';
      } else {
        commit('clearUser');
        if (response.data.detail != undefined) {
          return 'token_mismatch';
        } else {
          return 'system_error';
        }
      }
    } catch (error) {
      commit('clearUser');
      return 'server_is_down';
      // mutations.clearUser();
    }
  },
  async REGISTER_USER(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance.defaults.baseURL = getUrlToCookie();
      companyInstance
        .post('user', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async RESET_PASSWORD(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance.defaults.baseURL = getUrlToCookie();
      companyInstance
        .put('user/password', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async FETCH_AND_SELECT_MY_COMPANY({ commit }, saup_no) {
    return new Promise((resolve, reject) => {
      instance
        .get('system/company')
        .then(response => {
          console.log('FETCH_SYSTEM_COMPANY', response);
          if (response.data.result == 'success') {
            const data = response.data;
            // console.log('getCompanyList', data);
            const hitSaup = data.data_list.find(
              element => element.saup_no == saup_no,
            );
            if (hitSaup != null) {
              console.log('hitSaup', hitSaup);
              commit('setSystemCompany', hitSaup);
              commit(
                'setCompanyUrl',
                `${hitSaup.server_url}:${hitSaup.port}/api/v1/`,
              );
              console.log('hitSaup.server_url', hitSaup.server_url);
              console.log('hitSaup.port', hitSaup.port);
              saveUrlToCookie(`${hitSaup.server_url}:${hitSaup.port}/api/v1/`);

              saveSaupToCookie(hitSaup.saup_no);
              resolve(response);
            } else {
              resolve(resolve);
            }
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async UPDATE_FCM_TOKEN(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('user/fcm-token', payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
