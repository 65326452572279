import { formatDateNoHours } from '@/utils/filters';
const state = {
  openTabIndex: 0,
  managementMode: false,
  start_date: undefined,
  end_date: undefined,
  search_type: null,
};

const getters = {
  getOpenTabIndexFromCompanyPage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromCompanyPage(state) {
    return state.managementMode;
  },
  getDateFromCompanyPage(state) {
    return {
      start: formatDateNoHours(state.start_date),
      end: formatDateNoHours(state.end_date),
    };
  },
  getStartDateFromCompanyPage(state) {
    return state.start_date;
  },
  getEndDateFromCompanyPage(state) {
    return state.end_date;
  },
  getSearchTypeFromCompanyPage(state) {
    return state.search_type;
  },
};
const mutations = {
  setOpenTabIndexToCompanyPage(state, idx) {
    state.openTabIndex = idx;
  },
  toggleManagementModeToCompanyPage(state) {
    state.managementMode = !state.managementMode;
  },
  setStartDateToCompanyPage(state, payload) {
    state.start_date = payload;
  },
  setEndDateToCompanyPage(state, payload) {
    state.end_date = payload;
  },
  // resetDateToCompanyPage(state) {
  //   const today = new Date();
  //   state.end_date = today;
  //   const lastMonth = new Date();
  //   lastMonth.setMonth(lastMonth.getMonth() - 1);
  //   state.start_date = lastMonth;
  // },
  setSearchTypeToCompanyPage(state, payload) {
    state.search_type = payload;
  },
};

export default { state, getters, mutations };
