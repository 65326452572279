/**
 * Update log
 * 2021-09-07 jaeoen. 담당자 속성 추가.
 */

import store from '@/store/index.js';
import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  companys: [],
  purchase_company_balance_list: [],
  sales_company_balance_list: [],
  selectedIndex: -1,
  managementData: {
    company_type_id: null, // 회사 유형
    name: '', //회사명
    representative: '', //대표자
    business_type: '', //업태
    business_item: '', //업종
    representative_item: '', //대표 품목
    address: '', //주소
    detail_address: '', //상세 주소
    account_number: '', //계좌번호,
    post_number: '', //우편번호
    email: '', //이메일
    phone_number: '', //대표 번호
    fax_number: '', //팩스번호
    business_date: '', //거래 개시일
    saup_no: '', //사업자번호
    vat: null, //부가세 구분
    detail: '', //비고
    manager: '', //담당자
  },
  newData: {
    company_type_id: null, // 회사 유형
    name: '', //회사명
    representative: '', //대표자
    business_type: '', //업태
    business_item: '', //업종
    representative_item: '', //대표 품목
    address: '', //주소
    detail_address: '', //상세 주소
    account_number: '', //계좌번호,
    post_number: '', //우편번호
    email: '', //이메일
    phone_number: '', //대표 번호
    fax_number: '', //팩스번호
    business_date: formatDateNoHours(new Date()),
    saup_no: '', //사업자번호
    vat: null, //부가세 구분
    detail: '', //비고
    manager: '', //담당자
  },
};

const getters = {
  getCompany(state) {
    return state.companys;
  },
  getVisibleCompany(state) {
    return state.companys.filter(x => x.visible);
  },
  getDeletedCompany(state) {
    return state.companys.filter(x => !x.visible);
  },
  getSelesTypeCompany(state) {
    return state.companys.filter(n => {
      return n.company_type_id == store.getters.getSalesTypeId;
    });
  },
  getBuyTypeCompany(state) {
    return state.companys.filter(n => {
      return n.company_type_id == store.getters.getBuyTypeId;
    });
  },
  getSelectedIndexFromCompany(state) {
    return state.selectedIndex;
  },
  getManagementDataFromCompany(state) {
    return state.managementData;
  },
  getNewDataFromCompany(state) {
    return state.newData;
  },
  getNewBusinessDateFromCompany(state) {
    state.newData.business_date = formatDateNoHours(
      state.newData.business_date,
    );
    return formatDateNoHours(state.newData.business_date);
  },
  getPurchaseCompanyBalanceList(state) {
    return state.purchase_company_balance_list;
  },
  getSalesCompanyBalanceList(state) {
    return state.sales_company_balance_list;
  },
};

const mutations = {
  setCompany(state, payload) {
    state.companys = payload;
  },
  setSelectedIndexToCompany(state, index) {
    state.selectedIndex = index;
  },
  setManagementDataToCompany(state, payload) {
    console.log('payload : ', payload);
    if (payload.saup_no == null) {
      payload.saup_no = '';
    }
    if (payload.phone_number == null) {
      payload.phone_number = '';
    }
    state.managementData = payload;
  },
  setNewDataToCompany(state, payload) {
    state.newData = payload;
  },
  setNewCompanyAddress(state, address) {
    state.newData.address = address;
  },
  setNewCompanyPostNumber(state, post_number) {
    state.newData.post_number = post_number;
  },
  setNewCompanyTypeId(state, type_id) {
    state.newData.company_type_id = type_id;
  },
  setNewCompanyVatId(state, vat_id) {
    state.newData.vat = vat_id;
  },
  setManagementCompanyTypeId(state, type_id) {
    state.managementData.company_type_id = type_id;
  },
  setManagementCompanyVatId(state, vat_id) {
    state.managementData.vat = vat_id;
  },
  setManagementCompanyAddress(state, address) {
    state.managementData.address = address;
  },
  setManagementCompanyPostNumber(state, post_number) {
    state.managementData.post_number = post_number;
  },
  setPurchaseCompanyBalanceList(state, payload) {
    state.purchase_company_balance_list = payload;
  },
  setSalesCompanyBalanceList(state, payload) {
    state.sales_company_balance_list = payload;
  },
};

const actions = {
  FETCH_COMPANY({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        // .get(`company/all/start=${payload.start}&end=${payload.end}`)
        .get('company/all')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setCompany', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_PURCHASE_COMPANY_BALANCE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('plan/purchase-company/balance')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setPurchaseCompanyBalanceList', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_SALES_COMPANY_BALANCE({ commit }) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get('plan/sales-company/balance')
        .then(response => {
          if (response.data.result == 'success') {
            console.log(response);
            commit('setSalesCompanyBalanceList', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  INSERT_COMPANY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post('company', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  UPDATE_COMPANY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .put('company', payload)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  DELETE_COMPANY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`company/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  RESTORE_COMPANY(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .delete(`company/restore/${payload}`)
        .then(response => {
          console.log(response);
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
