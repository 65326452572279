const state = {
  openTabIndex: 0,
  managementMode: false,
};

const getters = {
  getOpenTabIndexFromMaterialReturnPage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromMaterialReturnPage(state) {
    return state.managementMode;
  },
};
const mutations = {
  setOpenTabIndexToMaterialReturnPage(state, idx) {
    state.openTabIndex = idx;
  },
  toggleManagementModeToMaterialReturnPage(state) {
    state.managementMode = !state.managementMode;
  },
};

export default { state, getters, mutations };
