const state = {
  //data
  title: '',
  content: '',
  //flag
  isModalOpen: false,
};
const getters = {
  getModalTitle(state) {
    return state.title;
  },
  getModalContent(state) {
    return state.content;
  },
  getIsModalOpen(state) {
    return state.isModalOpen;
  },
};
const mutations = {
  OpenModal(state, payload) {
    state.title = payload.title;
    state.content = payload.content;
    state.isModalOpen = true;
  },
  CloseModal(state) {
    state.title = '';
    state.content = '';
    state.isModalOpen = false;
  },
};

export default {
  state,
  getters,
  mutations,
};
