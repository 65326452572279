const state = {
  openTabIndex: 0,
  managementMode: false,
};

const getters = {
  getOpenTabIndexFromExchangeManagementPage(state) {
    return state.openTabIndex;
  },
  getManagementModeFromExchangeManagementPage(state) {
    return state.managementMode;
  },
};
const mutations = {
  setOpenTabIndexToExchangeManagementPage(state, idx) {
    state.openTabIndex = idx;
  },
  toggleManagementModeToExchangeManagementPage(state) {
    state.managementMode = !state.managementMode;
  },
};

export default { state, getters, mutations };
