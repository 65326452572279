import { companyInstance } from '@/api/index.js';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  poor_lists: [],
};

const getters = {
  getPoorFromPoorQuality(state) {
    return state.poor_lists;
  },
};

const mutations = {
  setPoorToPoorQuality(state, payload) {
    state.poor_lists = payload;
  },
};

const actions = {
  FETCH_PROCESS_QUALITY_REPORT_TO_POOR_QUALITY({ commit }, payload) {
    let endDate = new Date(payload.end);
    endDate.setDate(endDate.getDate() + 1);
    const newDate = {
      start: payload.start,
      end: formatDateNoHours(endDate),
    };
    return new Promise((resolve, reject) => {
      companyInstance
        .get(
          `mag/seq/quality-report/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit('setPoorToPoorQuality', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
export default { state, getters, mutations, actions };
