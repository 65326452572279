import { companyInstance } from '@/api';

const state = {
  favoriteList: [],
};

const getters = {
  getFavoriteFromFavoriteMenuPage(state) {
    return state.favoriteList.sort((a, b) => a.id - b.id);
  },
};
const mutations = {
  setFavoriteToFavoriteMenuPage(state, payload) {
    state.favoriteList = payload;
  },
};

const actions = {
  FETCH_FAVORITE_MENU({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`/menu/favorites/${payload}`)
        .then(response => {
          if (response.data.result == 'success') {
            response.data.data_list.forEach(el => {
              el.detail == '/system'
                ? el.sub_menu.push({
                    detail: null,
                    id: 100,
                    main_menu_id: el.sub_menu[0].main_menu_id,
                    name: '기기 설정',
                    permission: 2,
                    state: 0,
                    sub_detail: '/system/setting',
                    sub_menu_id: 999,
                  })
                : '';
            });
            response.data.data_list = response.data.data_list.sort(
              (a, b) => a.id - b.id,
            );
            response.data.data_list = response.data.data_list.map(x => {
              x.sub_menu = x.sub_menu.sort((a, b) => a.id - b.id);
              return x;
            });
            commit('setFavoriteToFavoriteMenuPage', response.data.data_list);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log('FAVORITE MENE ERROR : ', error);
          reject(error);
        });
    });
  },
  INSERT_FAVORITE_MENU(context, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .post(`/menu/favorites`, payload)
        .then(response => {
          if (response.data.result == 'success') {
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log('FAVORITE MENE ERROR : ', error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
