import { companyInstance } from '@/api/index';
import { formatDateNoHours } from '@/utils/filters';

const state = {
  collections: [],
  salesAccounts: [],
  start_date: null,
  end_date: null,
  end_date_temp: '',
};

const getters = {
  getCollectionFromSalesCollectionStatus(state) {
    return state.collections;
  },
  getSalesAccountFromSalesCollectionStatus(state) {
    return state.salesAccounts;
  },
  getStartDateFromSalesCollectionStatus(state) {
    return state.start_date;
  },
  getEndDateFromSalesCollectionStatus(state) {
    return state.end_date;
  },
  getEndDateTempFromSalesCollectionStatus(state) {
    return state.end_date_temp;
  },
};

const mutations = {
  setCollectionToSalesCollectionStatus(state, payload) {
    state.collections = payload;
  },
  setSalesAccountToSalesCollectionStatus(state, payload) {
    state.salesAccounts = payload;
  },
  setStartDateToSalesCollectionStatus(state, payload) {
    state.start_date = payload;
  },
  setEndDateToSalesCollectionStatus(state, payload) {
    state.end_date = payload;
  },
  setEndDateTempToSalesCollectionStatus(state, payload) {
    state.end_date_temp = payload;
  },
};

const actions = {
  FETCH_COLLECTION_FROM_SALE_COLLECTION_STATUS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      companyInstance
        .get(`collection/start=${payload.start}&end=${payload.end}`)
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setCollectionToSalesCollectionStatus',
              response.data.data_list,
            );
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  FETCH_SALES_ACCOUNT_DAY_ALL_TO_SALES_COLLECTION_STATUS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endDate = new Date(payload.end);
      endDate.setDate(endDate.getDate() + 1);
      const newDate = {
        start: payload.start,
        end: formatDateNoHours(endDate),
      };
      companyInstance
        .get(
          `plan/sales/account/all/start_date=${newDate.start}&end_date=${newDate.end}`,
        )
        .then(response => {
          if (response.data.result == 'success') {
            commit(
              'setSalesAccountToSalesCollectionStatus',
              response.data.data_list,
            );
            console.log(response);
            resolve(response);
          }
          reject(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default { state, getters, mutations, actions };
